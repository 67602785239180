@import "../../../../assets/styles/main";

.phone-verify {
    width: 500px;
    padding: 25px;
    background-color: $white;
    border-radius: 10px;
    box-sizing: border-box;

    @include for-size(phone-475) {
        width: 90%;
        padding: 15px;
        margin: 0 auto;
    }

    &__title {
        color: $neutral-black-a32;
        text-align: center;

        @include text-size(normal, 600, 30px, 36px);

        @include for-size(phone-475) {
            @include text-size(normal, 600, 20px, 30px);
        }
    }

    &__description {
        margin: 16px 0;
        color: $light_gray;
        text-align: center;

        @include text-size(normal, 400, 16px, 22px);

        @include for-size(phone-475) {
            @include text-size(normal, 400, 13px, 18px);
        }
    }

    &__form {
        margin: 0 auto;
        width: 343px;

        @include for-size(phone-475) {
            width: 100%;
        }
    }

    &__question-tip {
        margin: 8px 0 16px;
        font-size: 13px;
        text-align: right;

        span {
            font-family: "SF Pro Display Medium";
            color: $main_green;
            cursor: pointer;
        }
    }

    &__btn {
        width: 100%;
        padding: 13px 0;
        background: $main_green;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: $white;
        text-align: center;
        transition: all 0.2s linear;
        height: 50px;

        &:hover {
            opacity: 0.7;
        }

        &[disabled] {
            cursor: not-allowed;
            transition: all 0.2s linear;

            &:hover {
                background: $neutral-light-grey-fdf;
                color: $white;
            }
        }
    }
}
