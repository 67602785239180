@import "../../../../../../../assets/styles/mixin";
@import "../../../../../../../assets/styles/main";

.attention {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 502px;
    background-color: #fff;
    padding: 24px;
    border-radius: 10px;

    @include for-size(phone) {
        width: auto;
        padding: 15px;
        margin: 0 15px;
    }

    &-title {
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: #272a32;
        padding: 0;
        margin: 0 0 24px 0;

        @include for-size(phone) {
            font-size: 20px;
            line-height: 24px;
            margin: 0 0 15px 0;
        }
    }

    &-subtitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #272a32;
        margin: 0 0 24px 0;

        @include for-size(phone) {
            font-size: 13px;
            line-height: 16px;
            margin: 0 0 15px 0;
        }
    }

    &-btn {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        background: #33b378;
        border-radius: 4px;
        border: none;
        outline: none;
        transition: all 0.3s linear;
        cursor: pointer;
        height: 50px;
        width: 194px;

        @include for-size(phone) {
            font-size: 13px;
            line-height: 16px;
        }

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            outline: none;
        }
    }
}
