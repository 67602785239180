@import "../../../../../assets/styles/main";

.restore-account__pending {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: $white;
    border-radius: 10px;
    padding: 65px 59px 45px 59px;
    width: 450px;
    height: auto;
    text-align: center;
    position: relative;

    @include for-size(phone) {
        margin: 0 auto;
        text-align: center;
        padding: 25px 20px 25px 20px;
        width: 80%;

        .btn.btn--green.btn--large {
            max-width: 90%;
        }
    }

    &-img {
        width: 72px;
        height: 50px;
        margin: 0 auto 15px;
    }

    &-title {
        font-size: 25px;
        line-height: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        margin: 0 auto 24px;
        color: $neutral-black-a32;
    }

    &-subtitle {
        font-size: 17px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.38px;
        margin: 0 0 24px 0;
        color: $neutral-black-a32;
        width: 450px;

        &-email {
            font-weight: 700;
            padding: 0 5px 0 0;
        }

        @include for-size(phone) {
            text-align: center;
            width: 100%;
        }
    }

    &-btn {
        background: $main_green;
        border-radius: 4px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        height: 48px;
        border: none;
        outline: none;
        text-align: center;
        color: $white;
        transition: all 0.2s linear;
        cursor: pointer;
        margin: 8px auto 8px auto;
        width: 255px;

        &:hover {
            opacity: 0.7;
        }

        @include for-size(phone) {
            max-width: 90%;
        }
    }
}
