@import "../../../../../assets/styles/main";
.carousel_item {
    width: 250px;
    height: 339px;
    border-radius: 6px;
    margin-right: 60px;
    position: relative;
    transition: all 0.8s ease-in-out;

    &:last-of-type {
        margin-right: 0px;
    }

    &_image {
        background-size: cover;
        object-fit: cover;
        max-width: 247px;
        height: 334px;
    }

    &_title {
        top: 30px;
        left: 30px;
        width: 176px;
        color: $white;
        position: absolute;
        @include text-size(normal, normal, 20px, 22px);
    }
}
