@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixin";

.profile-main-information {
    padding: 40px 35px;
    border-radius: 10px;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__avatar {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 25px;
    }

    &__name {
        display: flex;
        align-items: center;
        margin-bottom: 57px;
        text-align: center;
        @include text-size(normal, 600, 20px, 24px);

        &-popular {
            margin-left: 8px;
        }
    }

    &__reviews-block {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;

        .profile-main-information__divider {
            position: absolute;
            top: 50%;
        }

        &__container {
            border: 1px solid $neutral-light-grey-fdf;
            border-radius: 6px;
            display: flex;
            background: $white;
            z-index: 1;
        }

        &__stars {
            @extend .profile-main-information__reviews-block__reviews;
            border-right: 1px solid $neutral-light-grey-fdf;
        }

        &__reviews {
            padding: 15px 15px;
            color: $neutral-black-a32;
            display: flex;
            align-items: center;

            font-family: "SF Pro Display";
            @include text-size(normal, 400, 15px, 18px);

            svg {
                margin-right: 5px;
            }
        }
    }

    &__general-metrics {
        display: flex;
        width: 100%;
        margin: 16px 0;

        &__left-column {
            max-width: 50%;
        }

        &__right-column {
            max-width: 50%;
            margin-left: 20px;
        }

        &__right-column-contacts {
            margin-left: 60px;
        }

        &__option {
            color: $neutral-black-a32;
            margin: 0 0 9px 0;

            font-family: "SF Pro Display Medium";
            @include text-size(normal, 600, 15px, 18px);
        }

        &__option-main {
            font-size: 17px;
        }

        &__option:last-child {
            margin: 0;
        }

        &__value {
            color: $neutral-grey-282;
            margin: 0 0 9px 0;
        }

        &__value:last-child {
            margin: 0;
        }

        &__show-button {
            cursor: pointer;
            color: $main_green;

            font-family: "SF Pro Display Medium";
            @include text-size(normal, 600, 13px, 22px);
        }
    }

    &__general-metrics-title {
        margin-top: 25px;
        justify-content: space-between;
        align-items: center;
    }

    &__divider {
        width: 100%;
        height: 1px;
        background: $neutral-light-grey-fdf;
        z-index: 0;
    }

    .btn {
        width: 100%;
        margin-top: 38px;
    }
}
