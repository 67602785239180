@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.promotion__search {
    padding: 0;
    display: flex;
    border-radius: 6px;
    margin-bottom: 24px;
    align-items: center;
    padding: 7px 12px 7px 24px;
    background-color: $white;
    justify-content: space-between;
    transition: 0.2s linear;

    @include for-size(phone) {
        margin-bottom: 15px;
    }

    &_input {
        width: 80%;
        padding: 0;
        border: none;
        outline: none;
        box-shadow: none;
        background-image: none;
        background-color: transparent;
        @include text-size(normal, normal, 16px, 22px);
    }

    &_btn {
        height: 48px;
        width: 126px;
        padding: 0;
        border: none;
        color: $white;
        border-radius: 4px;
        text-align: center;
        background-color: $main_green;
        @include text-size(normal, 500, 16px, 22px);
        transition: all 0.2s linear;

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }

        &[disabled] {
            cursor: not-allowed;
            transition: all 0.2s linear;

            &:hover {
                background: #33b378;
                color: #fff;
            }
        }
    }

    &_remove {
        background-color: $main_green;
        padding: 11.5px;
        border-radius: 4px;
        margin-left: 7px;
        cursor: pointer;
        transition: all 0.3s linear;

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            outline: none;
        }

        path {
            fill: $white;
        }

        &.disabled {
            cursor: not-allowed;
            transition: all 0.2s linear;

            &:hover {
                background: #33b378;
                color: #fff;
            }
        }
    }
}
