.custom-textarea,
.custom-textarea__erros {
    padding: 14px 16px;
    height: 150px;
    outline: none;
    resize: none;
    border: 1px solid #e3e4ec;
    box-sizing: border-box;
    border-radius: 4px;
    background: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #272a32;
    width: 100%;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: #33b378;
    }

    &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #828282;
    }

    &__erros {
        outline: none;
        border: 1px solid #ff3b30;
        box-sizing: border-box;
        border-radius: 4px;
    }
}

.custom-textarea {
    &:focus {
        outline: none;
        border: 1px solid #33b378;
        box-sizing: border-box;
        border-radius: 4px;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__label {
        margin-bottom: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #272a32;
    }
    &__error {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #ff3b30;
    }
}
