@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixin";

.reviews {
    padding: 40px 45px;
    border-radius: 10px;
    background: $white;
    height: 285px;
    overflow: hidden;

    @include for-size(phone-630) {
        padding: 40px 15px;
    }

    @include for-size(phone-475) {
      height: 370px;
     }

    &--full-size {
        @extend .reviews;

        height: unset;
        overflow: visible;
    }

    &--empty {
        @extend .reviews;

        height: 398px;

        .reviews__title {
            margin-bottom: 70px;
        }
    }

    &__title {
        margin: 0 0 25px 0;

        font-family: "SF Pro Display";
        @include text-size(normal, 600, 24px, 30px);
    }

    &__counter {
        color: $neutral-grey-282;
        margin-left: 5px;
        font-family: "SF Pro Display";
        @include text-size(normal, 600, 24px, 30px);
    }

    &__no-reviews {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &__title {
            font-family: "SF Pro Display";
            margin-top: 35px;
            margin-bottom: 16px;
            @include text-size(normal, 600, 25px, 30px);
        }

        &__subtitle {
            font-family: "SF Pro Display";
            @include text-size(normal, 400, 16px, 30px);
            color: $light_gray;
        }
    }

    &__see-more-button {
        display: block;
        position: relative;
        margin: 17px 64px 17px 50px;
        color: $neutral-grey-a6a;
        font-family: "SF Pro Display";
        @include text-size(normal, 500, 15px, 30px);

        &::after {
            content: "";
            display: block;
            width: 4px;
            height: 4px;
            border-bottom: 1px solid $neutral-grey-a6a;
            border-left: 1px solid $neutral-grey-a6a;
            position: absolute;
            right: -14px;
            top: calc(50% - 4px);
            transform: rotate(-45deg);
        }

        &-container {
            display: flex;
            justify-content: center;
            margin: 20px 0;

            button {
                width: unset;
                height: unset;
            }
        }
    }
}

.review-item {
    background: $white;
    border: 1px solid $neutral-white-1f1;
    border-radius: 6px;
    padding: 25px 30px;
    display: flex;
    margin-bottom: 20px;

    @include for-size(phone-475) {
        flex-direction: column;
    }

    &__avatar {
        min-width: 65px;
        height: 65px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 22px;

        @include for-size(phone-475) {
           width: 65px;
           height: 65px;
           margin: 0 auto;
           margin-bottom: 20px;
        }
    }

    &__content {
        width: 100%;

        &__header {
            width: 100%;
            margin-bottom: 9px;
            display: flex;
            justify-content: space-between;

            &__name {
                color: $neutral-black-a32;

                font-family: "SF Pro Display Medium";
                @include text-size(normal, 400, 15px, 18px);
            }

            &__date {
                color: $neutral-grey-282;

                font-family: "SF Pro Display";
                @include text-size(normal, 400, 15px, 18px);
            }
        }

        &__feedback {
            margin-top: 9px;
            margin-bottom: 21px;
            color: $neutral-black-a32;
            font-family: "SF Pro Display";
            @include text-size(normal, 400, 15px, 22px);
        }

        &__order {
            color: $neutral-grey-282;

            font-family: "SF Pro Display";
            @include text-size(normal, 400, 15px, 18px);

            &__category {
                @extend.review-item__content__order;
                color: $main_green;
                margin-left: 5px;
            }
        }
    }
}
