@import "../../../../assets/styles/mixin";

.chats__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #ffffff;
    border-radius: 10px;
    width: calc(100% - 72px);
    padding: 36px;
    margin: 0 0 24px 0;

    @include for-size(phone-630) {
        padding: 15px;
        width: calc(100% - 30px);
    }
}
