@import "../../assets/styles/main";

.about-us {
    max-width: 1170px;
    margin: 70px auto;
    padding: 0 15px;

    @include for-size(phone) {
        margin: 35px auto;
    }

    &__btn {
        position: absolute;
    }

    &__title {
        margin: 0;
        @include text-size(normal, 900, 30px, 30px);
        padding: 0 0 10px 0;
        text-align: center;

        @include for-size(phone) {
            @include text-size(normal, 900, 24px, 28px);
            padding: 0 0 5px 0;
        }
    }

    &__subtitle {
        @include text-size(normal, 400, 20px, 24px);
        text-align: center;
        margin: 0 0 15px 0;
        color: #828282;
        padding: 0 100px;

        @include for-size(phone) {
            @include text-size(normal, 900, 16px, 19px);
            margin-bottom: 15px;
            padding: 0;
        }
    }

    &__content-item {
        margin: 0 0 10px 0;
        @include text-size(normal, 400, 16px, 19px);
        display: block;

        &::last-child {
            margin: 0;
        }

        @include for-size(phone) {
            @include text-size(normal, 400, 13px, 16px);
            margin: 0 0 5px 0;
        }
    }

    &__img {
        border-radius: 10px;
        margin-bottom: 15px;
        width: 100%;

        @include for-size(phone) {
            margin-bottom: 15px;
        }
    }
}
