@import "../../../../../../assets/styles/main";

.filter-item {
    border-bottom: 1px solid #dfdfdf;
    padding: 12px 0;

    max-height: 205px;
    overflow-y: auto;

    

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding-right: 6px;
    }

    &__footer {
        display: flex;
        align-items: center;
        cursor: pointer;

        &_title {
            @include text-size(normal, 700, 17px, 34px);
            color: $main_green;
        }

        &_img {
            transition: all 0.2s linear;
            margin-right: 20px;

            &_close {
                transform: rotate(180deg);
                transition: all 0.2s linear;

                path {
                    stroke: $main_green;
                }
            }

            path {
                stroke: $main_green;
            }
        }
    }

    &__title {
        color: $gray_43;
        @include text-size(normal, 700, 17px, 34px);
        margin-bottom: 5px;
    }

    &__img {
        transition: all 0.2s linear;

        &_close {
            transform: rotate(180deg);
            transition: all 0.2s linear;

            path {
                stroke: $main_green;
            }
        }

        path {
            stroke: $main_green;
        }
    }

    &__subtitle {
        list-style: none;

        a {
            margin-bottom: 4px;
            color: $gray_43;
            @include text-size(normal, 400, 17px, 34px);
            cursor: pointer;
            transition: all 0.2s linear;
            list-style: none;

            &.active {
                color: $main_green;
            }

            &:hover {
                color: $main_green;
            }
        }
    }

    &__empty {
        color: $gray_43;
        @include text-size(normal, 400, 17px, 34px);
    }
}
