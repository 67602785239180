@import "../../../../../../assets/styles/_mixin";
@import "../../../../../../assets/styles/main";

.header_container {
    &__nav_block_signed {
        display: flex;
        align-items: center;

        &_notifications,
        &_favorites {
            margin: 0 38px 0 0;
            transition: all 0.2s linear;
            position: relative;

            &:hover {
                opacity: 0.7;
            }

            &_img {
                cursor: pointer;
                width: 22px;
                height: 24px;
            }

            &_unread {
                position: absolute;
                top: 1px;
                right: 0;
                width: 10px;
                height: 10px;
            }
        }

        &_favorites {
            &_unread {
                width: 10px;
                height: 10px;
                right: -3px;
            }
        }

        &_link {
            text-decoration: none;
            margin: 0 38px 0 0;
            transition: all 0.2s linear;

            &.active,
            &:hover {
                font-weight: 600;
            }
        }

        &_text {
            font-size: 15px;
            line-height: 18px;
            color: $neutral-black-a32;
        }
    }

    &__nav_block_unsigned {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    &__nav_link {
        text-decoration: none;
        margin-right: 50px;
    }

    &__nav_text {
        color: $black;
        font-size: 15px;
        line-height: 18px;
        font-family: "SF Pro Display", sans-serif;
        font-weight: 400;
    }

    &__nav_link.active > &__nav_text {
        color: $main_green;
    }

    &__registration_btn {
        width: 138px;
        height: 44px;
        color: $white;
        background-color: $main_green;
        border-radius: 6px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
}

.header_container__nav_block_signed,
.header_container__nav_block_unsigned {
    @include for-size(phone) {
        display: none;
    }
}

.mobile-header {
    &__navigation {
        display: none;

        @include for-size(phone) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px;

            &_link {
                text-decoration: none;
                box-sizing: border-box;
                margin: 15px;
                position: relative;

                &.active {
                    font-weight: 600;
                }
            }

            &_link-text {
                font-size: 20px;
                line-height: 32px;
                color: $white;
            }

            &_unread-notification,
            &_unread-favorites {
                position: absolute;
                top: 10px;
                right: -20px;
                width: 15px !important;
                height: 15px !important;
            }

            &_button {
                border: none;
                background: transparent;
                display: flex;
                cursor: pointer;

                &.isLoading {
                    display: flex;
                    justify-content: center;
                    cursor: not-allowed !important;
                }
            }
        }
    }

    &__profile {
        display: none;

        @include for-size(phone) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
