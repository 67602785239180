.accordion {
    display: flex;
    justify-content: space-between;
    &__content {
        width: 100%;
    }
    &__btn {
        display: flex;
        width: 11px;
        height: 18px;
        padding-left: 4px;
        margin-right: -11px;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        &-active {
            & svg {
                transform: rotate(180deg);
            }
        }
    }
}
