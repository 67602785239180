@import "../../../../../../assets/styles/main";
.specialist-orders__list_item {
    background: $white;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 40px 45px 36px 45px;

    &:last-of-type {
        margin-bottom: 0px;
    }

    @include for-size(phone) {
        margin: 0 auto;
        width: 90%;
        margin-bottom: 20px;
        padding: 20px 15px 20px 15px;

        .btn.btn--green.btn--large {
            width: 100%;
            height: 40px;
        }
    }

    @media (min-width: 767px) and (max-width: 1200px) {
        margin: 0 auto;
        margin-bottom: 20px;
        padding: 40px 45px 55px 45px;
    }

    &-cancelled {
        opacity: 0.7;
        margin-bottom: 20px;

        pointer-events: none;

        padding: 40px 45px 36px 45px;
        background: $white;
        border-radius: 10px;

        &:last-of-type {
            margin-bottom: 0px;
        }

        @include for-size(phone) {
            margin: 0 auto;
            width: 85%;
            margin-bottom: 20px;
            padding: 10px 15px 10px 15px;

            .btn.btn--green.btn--large {
                height: 30px;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 27px;
        margin-bottom: 27px;
        border-bottom: 1px solid $neutral-light-grey-fdf;

        @include for-size(phone) {
            padding-bottom: 10px;
            margin-bottom: 10px;
        }

        &_btn {
            cursor: pointer;
            @include for-size(phone) {
                margin-left: 8px;
                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        &_title {
            color: $neutral-black-a32;
            @include text-size(normal, 700, 24px, 30px);

            @include for-size(phone) {
                text-align: center;
                @include text-size(normal, 700, 18px, 18px);
            }
        }
    }
    &__info {
        &_title {
            margin-top: 12px;
            padding: 9px 15px;
            border-radius: 4px;
            color: $neutral-black-a32;
            background: rgba(237, 238, 240, 0.37);
            @include text-size(normal, 700, 17px, 30px);

            @include for-size(phone) {
                @include text-size(normal, 700, 14px, 18px);
            }
        }

        &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 15px;
            border-bottom: 1px dashed $neutral-light-grey-fdf;

            &_description {
                @include text-size(normal, 400, 15px, 18px);
                color: $neutral-black-a32;
            }

            &_value {
                @include text-size(normal, 400, 15px, 18px);
                color: $neutral-black-a32;
            }

            @include for-size(phone) {
                &_description {
                    @include text-size(normal, 400, 13px, 18px);
                }

                &_value {
                    width: 100px;
                    @include text-size(normal, 400, 13px, 18px);
                }
            }
        }
    }
    &_btn {
        width: 241px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        padding: 14px 10px;

        border-radius: 4px;
        transition: 0.1s linear;
        background-color: $main_green;
        border: none;
        color: $white;
        margin-top: 16px;

        &:hover {
            opacity: 0.7;
            cursor: pointer;
        }
    }
}
