@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.balance__container-right {
    width: 375px;

    @include for-size(phone) {
        width: 100%;
    }

    &-wallets,
    &-balance {
        background-color: #fff;
        border-radius: 10px;
        padding: 36px 36px 24px 36px;
        box-sizing: border-box;

        @include for-size(phone) {
            padding: 16px;
        }

        &-title {
            margin-bottom: 26px;
            font-family: "SF Pro Display Medium";
            font-size: 24px;
            line-height: 30px;
        }

        &-info {
            padding-top: 16px;
            margin-bottom: 16px;
            border-top: 1px dashed $neutral-light-grey-fdf;

            &-systems {
                margin-bottom: 8px;
                display: flex;
                justify-content: space-between;

                &-item {
                    width: 49%;
                    height: 82px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: $light_green;

                    &-icon {
                        width: 50px;
                        height: 20px;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &-amount {
                        margin-top: 8px;
                        display: flex;
                        align-items: center;
                        font-family: "SF Pro Display Medium";
                        font-size: 15px;
                        line-height: 30px;
                        color: $neutral-black-a32;
                    }
                }
            }

            &-funds {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: "SF Pro Display Medium";
                font-size: 15px;
                line-height: 30px;
                color: $neutral-black-a32;

                @include for-size(small-phone) {
                    font-size: 13px;
                }

                &-amount {
                    display: flex;
                    align-items: center;
                }
            }

            &-processing-funds {
                margin-top: 8px;
                color: $neutral-grey-282;

                svg {
                    path {
                        fill: $neutral-grey-282;
                    }
                }
            }
        }
    }

    &-wallets {
        margin-top: 15px;

        &-info {
            text-align: center;
            border-bottom: 1px solid $neutral-light-grey-fdf;

            &-empty-results {
                margin-bottom: 16px;
                font-size: 15px;
                line-height: 22px;
                color: $light_gray;
            }

            &-icon {
                margin-bottom: 16px;
            }
        }

        &-btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            border: none;
            background: none;

            &-text {
                margin-left: 9px;
                font-family: "SF Pro Display Medium";
                font-size: 13px;
                line-height: 22px;
                color: $main_green;
            }
        }
    }
}
