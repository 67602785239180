@import "../../../../assets/styles/main";
@import "../../../../assets/styles/_mixin";

.notification_item {
    display: grid;
    grid-template-areas: "info more remove";
    grid-template-columns: auto 1fr auto;
    align-items: center;
    justify-content: space-between;
    padding: 30px 37px 30px 37px;
    border-bottom: 1px solid $neutral-light-grey-fdf;
    position: relative;
    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: 0px 0px 10px 0px #33b378;
    }

    &--not-read {
        @media screen and (max-width: 630px) {
            background-color: #effbf5;
        }
    }
    @media screen and (max-width: 630px) {
        padding: 16px;
        grid-template-areas: "info remove" "more more";
        grid-template-columns: auto auto;
    }

    &:last-child {
        border-bottom: none;
    }

    &__indicator {
        height: 63px;
        display: flex;
        align-items: center;
        position: absolute;
        left: 12px;
        @media screen and (max-width: 630px) {
            display: none;
        }

        &_item {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $main-green;
        }
    }

    &__info {
        grid-area: info;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 16px;
        width: auto;

        @media screen and (max-width: 630px) {
            margin: 0;
        }

        &_img {
            width: 63px;
            height: 63px;
            margin-right: 16px;
            align-self: flex-start;
            border-radius: 50%;
        }

        &_description {
            width: auto;

            &_text {
                color: $black;
                margin-bottom: 8px;
                word-break: break-word;
                @include text-size(normal, normal, 15px, 22px);
            }

            &_date {
                color: $light_gray;
                @include text-size(normal, normal, 15px, 22px);
            }
        }
    }

    &__nav {
        &_btn {
            grid-area: more;
            justify-self: flex-end;
            height: 50px;
            color: $black;
            padding: 14px 50px;
            margin: 0 10px;
            border-radius: 4px;
            transition: all 0.2s linear;
            background-color: transparent;
            border: 2px solid $main-green;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

            @media screen and (max-width: 630px) {
                justify-self: center;
                margin-top: 16px;
                padding: 14px 70px;
            }
        }

        &_loader {
            transition: all 0.2s linear;

            .spinner-container {
                justify-content: flex-end;
            }

            &:hover {
                opacity: 0.7;
            }

            @media (max-width: 630px) {
                align-self: flex-start;
                margin-left: 16px;
            }
        }

        &_remove {
            grid-area: remove;
            cursor: pointer;
            transition: all 0.2s linear;

            @media screen and (max-width: 630px) {
                align-self: flex-start;
                margin-left: 16px;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
