@import "../../assets/styles/mixin";
@import "../../assets/styles/main";

.balance {
    margin: 120px auto;
    min-height: calc(100vh - 626px);
    max-width: 1170px;
    padding: 0 15px;

    @media (max-width: 1600px) {
        min-height: calc(100vh - 456px);
        margin: 35px auto;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include for-size(phone) {
            flex-direction: column-reverse;
            align-items: center;
        }
    }
}

.balance-iyzico-wallet-customer {
    width: 300px;
    font-size: 13px;

    @media screen and (max-width: 1200px) {
        margin-top: 10px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }

    &__text {
        margin-top: 10px;
    }
}
