@import "../../../../../../assets/styles/mixin";
@import "../../../../../../assets/styles/main";

.info_about_order_form {
    padding: 40px 32px 36px 32px;
    background: $white;
    border-radius: 10px;

    @include for-size(phone) {
        padding: 15px;
    }

    &__details {
        &_title {
            margin-top: 12px;
            padding: 9px 15px;
            border-radius: 4px;
            color: $neutral-black-a32;
            background: rgba(237, 238, 240, 0.37);
            @include text-size(normal, 700, 17px, 30px);

            @include for-size(phone) {
                @include text-size(normal, 700, 16px, 19px);
            }
        }

        &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 15px;
            border-bottom: 1px dashed $neutral-light-grey-fdf;

            &:nth-child(16) {
                padding-bottom: 0;
            }

            &_description {
                @include text-size(normal, 400, 15px, 18px);
                color: $neutral-black-a32;
                max-width: 100%;

                @include for-size(phone) {
                    @include text-size(normal, 700, 13px, 16px);
                    // max-width: 100%;
                }

                &_text {
                    padding-bottom: 10px;
                }

                &_image {
                    display: flex;
                    overflow: auto;
                    padding-bottom: 5px;
                    margin-bottom: 5px;

                    &::-webkit-scrollbar {
                        height: 12px;
                        border-radius: 10px;
                        background-color: #fff;
                        border: 1px solid #33b378;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #33b378;
                        border-radius: 10px;
                    }

                    &_item {
                        width: 173px;
                        height: 173px;
                        object-fit: cover;
                        margin: 0 20px 0 0;
                        border-radius: 10px;
                    }
                }
            }

            &_value {
                @include text-size(normal, 400, 15px, 18px);
                color: $neutral-black-a32;
                display: flex;
                align-items: center;

                @include for-size(phone) {
                    @include text-size(normal, 700, 13px, 16px);
                }

                svg {
                    height: 14px;
                }
            }
        }
    }

    &__btns {
        display: flex;
        justify-content: center;
        margin: 42px 0 0 0;

        @include for-size(phone) {
            margin: 15px 0 0 0;
        }

        &-left,
        &-right {
            transition: all 0.2s linear;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            text-align: center;
            outline: none;
            width: 270px;
            height: 48px;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }

        &-left {
            color: #ffffff;
            background: #33b378;
            border-radius: 4px;
            border: none;
            margin: 0 15px 0 0;

            @include for-size(phone) {
                margin: 0 7.5px 0 0;
            }
        }

        &-right {
            color: #272a32;
            background: #ffffff;
            border: 1px solid #33b378;
            border-radius: 4px;
            margin: 0 0 0 15px;

            @include for-size(phone) {
                margin: 0 0 0 7.5px;
            }
        }
    }
}
