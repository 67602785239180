@import "../../../../assets//styles/main";
@import "../../../../assets//styles/mixin";

.stages_work {
    background: url("../../../../assets/images/background-images/stages_work.png");
    overflow-x: hidden;
    background-size: cover;
    padding: 74px 115px 98px;
    font-family: "SF Pro Display Medium";

    @include for-size(phone-475) {
        padding: 74px 15px 98px;
    }

    &__content {
        max-width: 1170px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__items {
        display: flex;

        @include for-size(tablet-phone) {
            flex-direction: column;
        }
        @media (min-width: 900px) and (max-width: 1400px) {
            flex-wrap: wrap;
            max-width: 600px;
        }
    }

    &__header {
        width: 100%;
        display: flex;
        margin-bottom: 108px;

        @include for-size(tablet-phone) {
            display: block;
            margin-bottom: 50px;
        }
    }

    &__title {
        margin: 0;
        padding-right: 126px;
        color: $white;
        @include text-size(normal, normal, 40px, 42px);

        @include for-size(phone-630) {
            padding-right: 0;
            margin-bottom: 20px;
        }
    }

    &__subtitle {
        width: 410px;
        margin: 0;
        text-align: left;
        color: $white;
        font-family: "SF Pro Display";
        @include text-size(normal, normal, 16px, 22px);

        @include for-size(phone-630) {
            width: 100%;
        }
    }
}
