@import "../../assets/styles/main";
.schedule_work {
    position: absolute;
    width: 405px;
    z-index: 10;
    border-radius: 10px;
    background-color: $white;

    @media screen and(max-width: 500px) {
        width: 300px;
    }

    @media screen and(max-width: 375px) {
        width: 250px;
    }

    &__content {
        padding: 24px 0px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 24px 24px;
        border-bottom: 1px solid $neutral-light-grey-fdf;
    }

    &__title {
        margin: 0;
        @include text-size(normal, normal, 24px, 29px);
    }

    &__table {
        padding: 24px;
        border-bottom: 1px solid $neutral-light-grey-fdf;

        @media screen and(max-width: 375px) {
            padding: 24px 10px;
        }
    }

    &__service {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
    }

    &__btn {
        &--close {
            font-size: 40px;
            color: $neutral-grey-282;
            transition: 0.2s linear;

            &:hover {
                cursor: pointer;
                transform: scale(1.2);
            }
        }

        &--save {
            border: none;
            color: $white;
            font-size: 16px;
            padding: 12px 22px;
            margin-left: 12px;
            width: 168px;
            border-radius: 6px;
            background: $main_green;
            transition: 0.2s linear;

            &:hover {
                cursor: pointer;
                background: $green_90;
            }

            @media screen and(max-width: 500px) {
                width: 120px;
            }

            @media screen and(max-width: 375px) {
                width: 100px;
                padding: 12px 10px;
            }
        }

        &--cancel {
            font-size: 16px;
            width: 168px;
            color: $black;
            padding: 12px 33px;
            border-radius: 6px;
            background: transparent;
            border: 1px solid #33b378;

            &:hover {
                cursor: pointer;
            }

            @media screen and(max-width: 500px) {
                width: 120px;
            }

            @media screen and(max-width: 375px) {
                width: 100px;
                padding: 12px 10px;
            }
        }
    }
}
