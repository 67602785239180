@import "../../assets/styles/main";
@import "../../assets/styles/mixin";

.all_services {
    width: 100%;

    &__content {
        width: 1170px;
        margin: 0 auto;
        padding: 59px 0px 162px 0px;

        @include for-size(tablet-laptop) {
            width: 100%;
            padding: 35px 15px 35px 15px;
            box-sizing: border-box;
        }

        @include for-size(phone) {
            width: 100%;
            padding: 35px 15px 35px 15px;
            box-sizing: border-box;
        }
    }
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 47px;

        @include for-size(phone-630) {
            flex-direction: column;
            margin-bottom: 30px;
            align-items: flex-start;
        }
    }
    &__title {
        margin: 0 auto;
        text-align: center;
        @include text-size(normal, 600, 45px, 30px);
        color: #272a32;

        @include for-size(phone-630) {
            margin-top: 20px;
        }
    }
    &__search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        border-radius: 6px;
        margin-bottom: 62px;
        padding: 7px 7px 7px 20px;
        background-color: $white;

        @include for-size(phone-630) {
            margin-bottom: 25px;
        }

        &_input {
            width: 90%;
            padding: 0;
            border: none;
            outline: none;
            box-shadow: none;
            background-image: none;
            background-color: transparent;
            @include text-size(normal, normal, 16px, 22px);
        }

        &_btn {
            height: 48px;
            width: 126px;
            text-align: center;
            padding: 0;
            border: none;
            border-radius: 4px;
            color: $white;
            background-color: $main_green;
            @include text-size(normal, 500, 16px, 22px);

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }

    &--back {
        border: none;
        display: flex;
        align-items: center;
        padding: 16px 20px;
        border-radius: 6px;
        background: #ffffff;
        cursor: pointer;
        transition: all 0.2s linear;
        color: #272a32;

        &:hover {
            opacity: 0.7;
        }

        &_text {
            margin: 0 0 0 10px;
        }
    }
    &__inner {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 56px 70px 84px 70px;
        background: #ffffff;
        border-radius: 10px;

        @include for-size(phone) {
            flex-wrap: nowrap;
            flex-direction: column;
        }

        @include for-size(phone-630) {
            flex-wrap: nowrap;
            flex-direction: column;
            padding: 56px 20px 84px 20px;
        }
    }
}
