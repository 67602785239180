@import "../../assets/styles/mixin";
@import "../../assets/styles/main";

.autocomplete-search {
    display: flex;
    flex-direction: column;
    position: relative;

    &__block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0;
        border-radius: 6px;
        padding: 7px 12px;
        background-color: $white;
        position: relative;
        width: calc(100% - 24px);

        @include for-size(phone) {
            margin-bottom: 0px;
        }

        &.active {
            border-radius: 6px 6px 0 0;

            @include for-size(tablet-phone) {
                width: calc(100% - 24px);
            }

            &:after {
                position: absolute;
                content: "";
                width: calc(100% - 24px);
                left: 0;
                bottom: 0;
                height: 1px;
                background-color: $neutral-light-grey-fdf;
            }
        }

        @include for-size(small-phone) {
            flex-direction: column;
        }

        &-input {
            flex-grow: 1;
            padding: 0 20px 0 0;
            border: none;
            outline: none;
            box-shadow: none;
            background-image: none;
            background-color: transparent;
            width: 50%;
            @include text-size(normal, normal, 16px, 22px);

            @include for-size(phone-475) {
                font-size: 14px;
            }

            @include for-size(small-phone) {
                padding: 0;
                margin-bottom: 10px;
                width: 60%;
                text-align: center;
            }
        }

        &-btn {
            height: 48px;
            width: max-content;
            text-align: center;
            padding: 13px 41px;
            border: none;
            border-radius: 4px;
            color: $white;
            background-color: $main_green;
            @include text-size(normal, 500, 16px, 22px);

            @media screen and (max-width: 515px) {
                padding: 0 12px;
                font-size: 14px;
            }

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }

    &__containter {
        z-index: 1;
        position: absolute;
        background-color: white;
        top: 61px;
        width: calc(100% - 2px);
        max-height: 200px;
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 0 0 6px 6px;
        outline: 1px double #dfdfdf;
        outline-offset: 0px;
        margin-left: 1px;

        &.loading {
            background-color: #dfdfdf;
            height: 200px;
        }

        .error-component {
            margin: 0;
            padding: 0;
            min-height: auto;
            height: auto;

            &__button {
                display: none;
            }

            &__image {
                margin: 10px 0;
                width: 135px;
                height: 100px;
            }

            &__title {
                @include text-size(normal, 700, 15px, 18px);
            }

            &__description {
                margin: 10px 0;
                @include text-size(normal, normal, 15px, 18px);
            }
        }

        @include for-size(tablet-phone) {
            width: calc(100% - 2px);
            margin-left: 1px;
        }

        @include for-size(small-phone) {
            top: 94px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #fff;
            border-radius: 6px 0 6px 0;
        }

        &::-webkit-scrollbar-thumb {
            background: #33b378;
            border-radius: 6px 0 6px 0;
        }

        &-option {
            display: flex;
            align-items: center;
            padding: 7px 12px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            text-align: left;
            transition: all 0.3s linear;

            &:hover {
                .autocomplete-search__containter-option-title {
                    font-weight: 700;
                }
            }

            @include for-size(small-phone) {
                width: 100%;
                min-width: 100%;
            }

            &:hover {
                box-shadow: 0px 0px 10px 0px #33b378;
            }

            &:hover {
                & > &-title {
                    color: $main_green;
                }
            }

            &-title {
                @include text-size(normal, normal, 15px, 18px);
                transition: all 0.3s linear;

                @include for-size(phone-475) {
                    font-size: 14px;
                }

                @include for-size(small-phone) {
                    padding: 0;
                    margin-bottom: 10px;
                    width: 60%;
                    text-align: center;
                }
            }

            &-subtitle {
                padding: 0 5px;
                color: #828282;
                @include text-size(normal, normal, 13px, 16px);
            }

            &-tag {
                color: $main_green;
                @include text-size(normal, normal, 13px, 16px);
            }
        }
    }
}
