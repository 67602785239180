@import "../../assets/styles/main";

.all-services-results {
    max-width: 1170px;
    width: 100%;
    box-sizing: border-box;
    padding: 59px 0px 162px 0px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
        padding: 59px 16px 162px 16px;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 47px;
    }

    &__title {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 0 10px;
        text-align: center;
        @include text-size(normal, 600, 45px, 30px);
        width: 79%;
    }

    @include for-size(tablet) {
        padding: 35px 16px;

        &__header {
            margin-bottom: 39px;
            flex-direction: column;
            align-items: flex-start;
        }

        &__title {
            width: 100%;
            margin-top: 20px;
        }
    }

    @include for-size(phone) {
        padding: 35px 16px;

        &__header {
            margin-bottom: 20px;
            flex-direction: column;
            align-items: flex-start;
        }

        &__title {
            font-size: 32px;
            width: 100%;
            margin-top: 20px;
        }
    }
}
