@import "../../../../../../../assets/styles/mixin";
@import "../../../../../../../assets/styles/main";

.propose_order_amount {
    width: 502px;
    height: 368px;
    background: $white;
    padding: 80px 73px;
    border-radius: 10px;
    box-sizing: border-box;

    @include for-size(tablet-phone) {
        padding: 35px 28px;
        height: auto;
        width: auto;
        margin: 15px;
    }

    &_title {
        margin: 0 0 33px 0;
        text-align: center;
        @include text-size(normal, normal, 25px, 30px);

        @include for-size(phone) {
            margin: 0 0 15px 0;
            @include text-size(normal, normal, 18px, 24px);
        }
    }

    &_content {
        display: flex;
        position: relative;

        &_error {
            margin: 0;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: $light_red;
            position: absolute;
            top: -20px;
            right: 0;

            @include for-size(phone) {
                top: -15px;
                font-size: 10px;
            }
        }

        &_input {
            width: 100%;
            margin: 0 0 15px 0;
            padding: 16px 10px 16px 16px;
            box-sizing: border-box;
            outline: none;
            background: $white;
            border-radius: 4px;
            border: 1px solid $neutral-light-grey-fdf;
            margin-right: 8px;

            @include for-size(phone) {
                font-size: 13px;
                line-height: 16px;
            }
        }

        &_img {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            box-sizing: border-box;
            border-radius: 4px;
            height: 52px;
            width: 86px;

            @include for-size(phone) {
                height: 50px;
            }

            svg {
                path {
                    fill: #33b378;
                }
            }
        }
    }

    &_btn {
        width: 100%;
        padding: 13px 5px;
        text-align: center;
        color: $white;
        background: $main_green;
        border: none;
        border-radius: 4px;
        height: 50px;
        cursor: pointer;

        @include for-size(phone) {
            font-size: 13px;
            line-height: 16px;
            height: 45px;
        }

        &:hover {
            opacity: 0.7;
        }

        &[disabled] {
            transition: all 0.2s linear;

            &:hover {
                cursor: not-allowed;
                background: $light_gray;
            }
        }
    }
}
