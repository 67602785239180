@import "../../../../assets/styles/main";

.promotion-methods {
    padding-top: 30px;
    min-height: 586px;
    background-color: $main_green;
    box-sizing: border-box;

    @include for-size(phone) {
        padding-bottom: 50px;
    }

    &__header {
        max-width: 1170px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        @include for-size(tablet-laptop) {
            margin-left: 0;

            .back-button {
                margin-left: 16px;
            }
        }

        @include for-size(phone) {
            display: block;
            text-align: center;
            margin-bottom: 20px;

            .back-button {
                margin-left: 16px;
            }
        }
    }

    &__body {
        max-width: 1000px;
        margin: 0 auto;
        padding-bottom: 90px;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;

        @include for-size(tablet-phone) {
            flex-direction: column;
        }

        @include for-size(tablet) {
            padding: 0 16px;
            padding-bottom: 30px;
            align-items: center;
        }

        @include for-size(phone) {
            padding: 0 16px;
            flex-direction: column;
        }

        &-title {
            width: 470px;
            margin: 0;
            margin-bottom: 24px;
            color: $white;
            font-weight: 600;
            font-size: 60px;
            line-height: 110%;

            @include for-size(phone) {
                width: 100%;
                font-size: 30px;
                margin-top: 10px;
            }

            @include for-size(tablet-laptop) {
                font-size: 35px;
            }
        }

        &-subtitle {
            width: 470px;
            color: $white;
            font-size: 17px;
            line-height: 34px;

            @include for-size(phone) {
                width: 100%;
                @include text-size(normal, 500, 15px, 24px);
            }

            @include for-size(tablet-laptop) {
                width: 100%;
            }
        }

        &-buy-button {
            margin-top: 24px;
            display: flex;
            align-items: center;
        }

        &-price {
            margin-left: 16px;
            display: flex;
            align-items: center;
            color: $white;
            font-weight: 600;
            font-size: 22px;
            line-height: 32px;

            &-icon {
                width: 21px;
                height: 21px;

                path {
                    fill: $white;
                }

                @include for-size(tablet-phone) {
                    width: 13px;
                    height: 13px;
                }
            }
        }

        &-icon {
            @include for-size(phone) {
                width: 300px;
                height: 300px;
                margin: 0 auto;
                margin-top: 20px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.promotion-methods__bottom-image {
    height: 316px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .promotion-methods__rate {
        max-width: 1170px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);

        @include for-size(tablet) {
            top: -40px;
        }

        @include for-size(phone) {
            flex-direction: column;
        }

        &-item {
            width: 370px;
            height: 146px;
            margin-right: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            border-radius: 10px;
            text-align: center;

            &:last-child {
                margin-right: 0;
            }

            @include for-size(tablet-laptop) {
                max-width: 310px;
            }

            @include for-size(tablet-phone) {
                width: 220px;
                height: 105px;
            }

            @include for-size(phone) {
                margin-right: 0;
                margin-bottom: 15px;
            }

            &-value {
                font-weight: 600;
                font-size: 46px;
                line-height: 66px;
                color: $main_green;

                @include for-size(tablet-phone) {
                    font-size: 36px;
                    line-height: 50px;
                }
            }

            &-title {
                font-weight: 500;
                font-size: 22px;
                line-height: 28px;
                color: $neutral-black-a32;

                @include for-size(tablet-phone) {
                    font-size: 18px;
                    line-height: 25px;
                }
            }
        }
    }
}
