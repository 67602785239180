@import "../../assets/styles/main";

.btn {
    border-radius: 6px;
    cursor: pointer;
    font-family: "SF Pro Display", sans-serif;
    font-weight: 400;
    transition: all 0.2s linear;

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: 0.7;
    }

    &[disabled] {
        cursor: not-allowed;
        transition: all 0.2s linear;

        &:hover {
            background: $neutral-light-grey-fdf;
            color: $white;
        }
    }

    &--green {
        background: $main_green;
        color: $white;
        border: none;
    }

    &--light {
        background: $white;
        color: $main_green;
        border: 1px solid $main_green;
    }

    &--gray {
        background: $light_gray_background_icons;
        color: $black;
        border: none;
    }

    &--white {
        background: $white;
        color: $black;
        border-radius: 10px;
        border: none;
    }

    &--dark {
        background: $neutral-black-a32;
        color: $white;
        border: none;
    }

    &--medium {
        width: 138px;
        height: 44px;
    }

    &--large {
        width: 356px;
        height: 50px;
        margin-bottom: 18px;
    }

    &--stretch {
        width: 100%;
        height: 50px;
    }
}
