@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.balance-replenish-modal {
    width: 502px;
    padding: 36px 73px;
    background-color: $white;
    border-radius: 10px;
    box-sizing: border-box;

    @include for-size(phone) {
        margin: 0 auto;
        padding: 20px 30px;
        width: 90%;
    }

    &__title {
        margin-bottom: 36px;
        text-align: center;
        font-size: 25px;
        line-height: 30px;
        color: $neutral-black-a32;
    }

    &__ipara-icon {
        margin-bottom: 16px;
    }

    &__form {
        margin-bottom: 24px;

        &-label {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-text {
                margin-bottom: 4px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: $neutral-black-a32;
            }

            &-error {
                margin-bottom: 4px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: $light_red;
            }
        }

        &-input {
            width: 100%;
            height: 50px;
            padding-left: 16px;
            margin-bottom: 8px;
            border: 1px solid $neutral-light-grey-fdf;
            border-radius: 4px;
            box-sizing: border-box;
            outline: 0;

            &:focus {
                border: 1px solid $main_green;
            }
        }

        &-input--error {
            border: 1px solid $light_red;
        }
    }

    &__btn-wrapper {
        margin-top: 8px;
    }
}
