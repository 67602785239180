@import "../../../../../../assets/styles/mixin";
@import "../../../../../../assets/styles/main";

.discussion_work_project_suggestions_item {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 0 37px 43px 37px;
    max-width: 100%;
    width: 100%;

    @include for-size(tablet-phone) {
        padding: 0 15px 15px 15px;
    }

    &:last-child {
        padding: 0 37px;

        @include for-size(tablet-phone) {
            padding: 0 15px;
        }
    }

    &__img {
        margin-right: 14px;

        @include for-size(tablet) {
            margin-right: 10px;
        }

        &_avatar {
            width: 58px;
            height: 58px;
            object-fit: cover;
            border-radius: 50%;
            position: relative;

            @include for-size(tablet) {
                width: 45px;
                height: 45px;
            }

            &_online {
                content: "";
                right: 0;
                bottom: 5px;
                display: block;
                position: absolute;
                width: 15px;
                height: 15px;
                z-index: 100;
                border-radius: 50%;
                background-color: $main_green;

                @include for-size(tablet) {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    &__info {
        width: calc(100% - 72px);
        @include for-size(tablet) {
            width: calc(100% - 58px);
        }
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_info {
            &_day {
                @include text-size(normal, 400, 15px, 22px);
                color: #828282;
                margin-bottom: 4px;

                @include for-size(tablet) {
                    @include text-size(normal, 400, 13px, 16px);
                    margin-bottom: 2px;
                }
            }

            &_title {
                color: $gray_43;
                margin-bottom: 4px;
                @include text-size(normal, 700, 16px, 22px);
                word-break: break-word;

                @include for-size(tablet) {
                    @include text-size(normal, 700, 13px, 16px);
                    margin-bottom: 2px;
                }
            }

            &_price {
                color: $dark_gray;
                margin-bottom: 4px;
                @include text-size(normal, 700, 15px, 22px);
                display: flex;
                align-items: center;
                word-break: break-word;

                @include for-size(tablet) {
                    @include text-size(normal, 700, 13px, 16px);
                    margin-bottom: 2px;
                }

                svg {
                    @include for-size(tablet) {
                        width: 12px;
                        height: 12px;
                    }

                    path {
                        fill: #556267;
                    }
                }
            }
        }

        &_btn {
            color: $white;
            padding: 13px 0;
            border: none;
            border-radius: 4px;
            background: $main_green;
            @include text-size(normal, 500, 15px, 22px);
            align-self: flex-start;
            transition: all 0.2s linear;
            outline: none;
            width: 195px;
            min-width: 195px;

            @include for-size(tablet) {
                @include text-size(normal, 500, 13px, 16px);
                padding: 13px 0;
                min-width: 120px;
                width: 120px;
            }

            @include for-size(phone-475) {
                @include text-size(normal, 500, 13px, 16px);
                padding: 13px 0;
                min-width: 100px;
                width: 100px;
            }

            &:focus {
                outline: none;
            }

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }
    &__body {
        width: 100%;
        &_description {
            max-width: 100%;
            @include text-size(normal, 500, 15px, 22px);
            color: #6a6a6a;
            margin-bottom: 4px;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include for-size(tablet) {
                @include text-size(normal, 500, 13px, 16px);
                margin-bottom: 2px;
            }
        }

        &_tags {
            margin-bottom: 10px;

            &_title {
                color: $neutral-black-a32;
                @include text-size(normal, 600, 15px, 22px);

                @include for-size(tablet) {
                    @include text-size(normal, 600, 13px, 16px);
                }
            }

            &_list {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;

                &_item {
                    margin: 0 4px 4px 0;
                    padding: 11px 16px;
                    color: $main_green;
                    border-radius: 6px;
                    background: $white_background_def;
                }
            }
        }
    }
}
