@import "../../assets/styles/mixin";

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    &-hidden {
        opacity: 0;
        visibility: hidden;
        transition: 0.55s opacity, 0.55s visibility;
    }
    &-visual {
        opacity: 1;
        visibility: visible;
        transition: 0.55s opacity, 0.55s visibility;
    }
}

.modal {
    max-width: auto;
    max-height: calc(95vh);
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    &-not-show {
        display: none;
    }
    &-close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in;

        @include for-size(phone) {
            top: 25px;
            right: 25px;
        }

        &:hover {
            transform: rotate(90deg);
        }
    }

    &__error {
        text-align: center;
        margin-top: 10px;
        line-height: 20px;
        font-size: 18px;
    }
}

.modal::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.modal::-webkit-scrollbar-thumb {
    background: #33b378;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
