@import "../../../../../../../assets/styles/mixin";

.reverse-funds-modal {
    padding: 75px;
    background: #fff;
    border-radius: 10px;
    width: 352px;

    @include for-size(phone) {
        width: 260px;
        text-align: center;
        padding: 45px 15px 15px;
    }

    .select__dropdown-open {
        border-bottom: none;
        border-radius: 4px;
    }

    .select__dropdown_body.open {
        position: relative;
    }

    &__title {
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: #272a32;
        margin-bottom: 36px;

        @include for-size(phone) {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 15px;
        }
    }

    .btn.btn--green.btn--medium {
        width: 100%;
        height: 48px;
        margin-top: 7px;

        @include for-size(phone) {
            margin-top: 5px;
        }
    }
}
