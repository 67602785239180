.tooltip {
    display: inline-block;
    position: relative;

    &-content {
        position: absolute;
        left: 35px;
        top: -50%;

        &-active {
            position: fixed;
            border-radius: 4px;
            padding: 15px;
            color: #fff;
            background: #33b378;
            width: auto;
            max-width: 200px;
            text-align: center;
            animation: fadeInTooltip 0.5s ease-in;
            max-height: 99px;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

@keyframes fadeInTooltip {
    0% {
        opacity: 0.01;
    }
    100% {
        opacity: 1;
    }
}
