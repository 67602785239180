@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.my-account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 69px 0 97px 0;

    @include for-size(phone) {
        padding: 30px 0 30px 0;
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 45px;
        line-height: 30px;
        text-align: center;
        color: $neutral-black-a32;

        @include for-size(phone) {
            font-size: 32px;
            line-height: 38px;
        }
    }

    &__subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 22px;
        text-align: center;
        color: $neutral-grey-282;
        padding: 22px 0 52px 0;

        @include for-size(phone) {
            font-size: 18px;
            padding: 15px 0 30px;
        }
    }

    &__form {
        background: $white;
        border-radius: 10px;
        width: 356px;
        height: auto;
        padding: 38px 42px 55px 42px;

        @include for-size(phone) {
            padding: 32px 16px 32px 16px;
            width: 311px;
        }

        @media (max-width: 374px) {
            max-width: 300px;
            padding: 16px 8px 16px 8px;
        }

        &-date {
            display: flex;
            align-items: center;
            padding: 0;
            margin-bottom: 5px;

            &-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &-label {
                margin-bottom: 4px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: $neutral-black-a32;
            }

            &-error {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: $light_red;
            }
        }

        &-facebook-checkbox {
            margin: 5px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: $neutral-black-a32;

            label {
                cursor: pointer;
            }

            input[type="checkbox"] {
                position: relative;
                border: 2px solid $main_green;
                border-radius: 2px;
                background: none;
                cursor: pointer;
                line-height: 0;
                outline: 0;
                padding: 0 !important;
                vertical-align: text-top;
                height: 20px;
                width: 20px;
                -webkit-appearance: none;
                opacity: 0.5;
            }

            input[type="checkbox"]:hover {
                opacity: 1;
            }

            input[type="checkbox"]:checked {
                background-color: $main_green;
                opacity: 1;
            }

            input[type="checkbox"]:before {
                content: "";
                position: absolute;
                right: 50%;
                top: 50%;
                width: 4px;
                height: 10px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                margin: -1px -1px 0 -1px;
                transform: rotate(45deg) translate(-50%, -50%);
                z-index: 2;
            }
        }

        .form-container__label,
        .form-container__error,
        .select__label {
            margin: 0 0 6px 0;
            font-size: 12px;
            line-height: 16px;
            font-style: normal;
            font-weight: normal;
        }

        .select__label {
            margin: 0 0 2px 0;
        }

        .form-container__register_input,
        .form-container__password {
            margin: 0 0 8px 0;
            border: 1px solid $neutral-light-grey-fdf;

            &:focus {
                border: 1px solid $main_green;
            }
        }

        .form-container__phone-form {
            margin-bottom: 8px;
        }

        .form-container__register_input--error,
        .form-container__register_input--disable,
        .form-container__password,
        .form-container__password--error,
        .form-container__password--disable,
        .select,
        .phone-input {
            margin: 0 0 8px 0;
        }

        .form-container__phone-confirmed {
            font-family: "SF Pro Display Medium";
            text-align: right;
            font-size: 14px;
            color: $green_70;
        }

        .form-container__phone-confirmed--not {
            color: $light_red;

            span {
                cursor: pointer;
            }
        }

        &--avatar {
            display: flex;
            align-items: center;
            margin: 0 0 8px 0;
            justify-content: center;

            .upload_photo {
                margin-bottom: 0;
            }

            &__btns {
                display: flex;
                flex-direction: column;
                margin: 0 0 0 28px;

                &--change,
                &--delete {
                    width: 119px;
                    height: 43px;
                    font-size: 16px;
                    line-height: 19px;
                    box-sizing: border-box;
                    border-radius: 6px;
                    outline: none;
                    cursor: pointer;
                    transition: all 0.2s linear;
                    color: $white;
                    background: $main_green;
                    border: none;

                    &[disabled] {
                        cursor: not-allowed;
                        transition: all 0.2s linear;

                        &:hover {
                            background: $neutral-light-grey-fdf;
                            color: $white;
                        }
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }

                &--delete {
                    margin: 14px 0 0 0;
                }
            }
        }

        &--btns {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 16px;
        }

        &--save,
        &--cancel {
            font-size: 17px;
            line-height: 20px;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 169px;
            height: 50px;
            cursor: pointer;
            transition: all 0.2s linear;

            &:hover {
                opacity: 0.7;
            }
        }

        &--cancel {
            width: 100%;
            color: $black;
            background: $white;
            border: 1px solid $neutral-light-grey-fdf;
        }

        &--save {
            width: 100%;
            margin: 0 0 16px 0;
            color: $white;
            background: $main_green;
            border: none;

            &[disabled] {
                cursor: not-allowed;
                transition: all 0.2s linear;

                &:hover {
                    background: $neutral-light-grey-fdf;
                    color: $white;
                }
            }
        }

        &--promote {
            font-size: 17px;
            line-height: 20px;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 100%;
            height: 50px;
            cursor: pointer;
            transition: all 0.2s linear;
            margin-top: 16px;
            color: $white;
            background: $main_green;
            border: none;

            &:hover {
                opacity: 0.7;
            }
        }

        &--edit {
            font-size: 17px;
            line-height: 20px;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 100%;
            height: 50px;
            cursor: pointer;
            transition: all 0.2s linear;
            margin-top: 16px;
            color: $black;
            background: $white;
            border: 1px solid $main_green;

            &:hover {
                background: $main_green;
                color: $white;
            }
        }
        &--delete {
            font-size: 17px;
            line-height: 20px;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 100%;
            height: 50px;
            cursor: pointer;
            transition: all 0.2s linear;
            color: $black;
            background: $white;
            border: 1px solid $neutral-light-grey-fdf;

            &:hover {
                background: $red;
                color: $white;
            }
        }
    }
}
