@import "../../../assets/styles/main";

.registration_container {
    max-width: 440px;
    background: $white;
    border-radius: 10px;
    margin: 117px auto 121px;

    @include for-size(phone-475) {
        max-width: 100%;
        border-radius: 0px;
        margin: 0px auto;
        border-top: 1px solid $neutral-light-grey-fdf;
    }

    &__form {
        width: 356px;

        @include for-size(phone-475) {
            width: 100%;
        }
    }

    &__photo {
        margin-bottom: 48px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 44px 41px 52px 43px;

        @include for-size(phone-475) {
            padding: 60px 20px 52px 20px;
        }
    }

    &__label {
        margin-bottom: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $neutral-black-a32;
    }
    &__form_photo {
        border-radius: 50%;
        border: 1px solid $main_background;
        padding: 6px;
        display: flex;
        margin-bottom: 20px;
    }

    &__title {
        margin: 0 0 25px 0;
        color: $black;
        padding: 0;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
    }

    &__services {
        margin-bottom: 64px;
    }

    &__service {
        margin-bottom: 40px;

        &_item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 27px;
        }

        &_select {
            box-sizing: border-box;
            width: 100%;
            height: 50px;

            border-radius: 4px;
            border: 1px solid $neutral-light-grey-fdf;
            padding: 10px 15px;
            font-size: 16px;
        }

        &_description {
            font-size: 14px;
            line-height: 18px;
            font-family: "SF Pro Display", sans-serif;
            text-align: start;
            color: $light_gray;
            padding: 0 40px 0 0;
            margin: 8px 0 0;
        }
    }

    &__date {
        display: flex;
        align-items: center;
        padding: 0;

        &_info {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_label {
            margin-bottom: 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: $neutral-black-a32;
        }

        &_error {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: $light_red;
        }
    }

    &__phone-form {
        margin-bottom: 8px;
    }

    &__btn {
        width: 100%;
        padding: 13px 0;
        margin-top: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: $white;
        background: $main_green;
        border-radius: 4px;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }
}
