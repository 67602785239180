@import "../../../../assets/styles/main";

.select-icon-form {
    width: 356px;
    height: auto;
    border-radius: 10px;
    background-color: $white;

    padding: 38px 42px 28px 42px;
    margin: 0 auto 20px auto;

    @include for-size(phone) {
        margin: 0 auto 20px auto;
        padding: 20px 40px 20px 40px;
        width: 85%;
    }
    @include for-size(phone-630) {
        margin: 0 auto 20px auto;
        padding: 20px 45px 20px 45px;
        width: 70vw;
    }

    &__options {
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        background-color: $neutral-light-grey-fdf;
        border-radius: 8px;
        margin-bottom: 20px;

        &-item {
            width: 50%;
            padding: 10px 15px;
            border-radius: 8px;
            transition: 0.2s linear;

            &--active {
                cursor: pointer;
                width: 50%;
                padding: 10px 15px;
                border-radius: 8px;

                color: $white;
                background-color: $main-green;
            }
            &:hover {
                cursor: pointer;
                color: $white;

                background-color: $main-green;
            }
        }
    }

    &_title {
        @include for-size(phone) {
            margin: 0 auto;
            width: 80%;
        }
        text-align: center;
        padding: 0px 0 35px 0;
        max-width: 1170px;
        font-weight: 700;
        font-size: 25px;
        line-height: 28px;
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        flex: 1 1 auto;
        justify-content: center;
    }

    &__item {
        opacity: 0.27;
        scale: 1;
        width: 100px;
        height: 100px;
        margin-right: 10px;
        margin-bottom: 10px;

        cursor: pointer;
        transition: 0.2s linear;

        &--active {
            opacity: 1;
            scale: 1;
            width: 100px;
            height: 100px;
            margin-right: 10px;
            margin-bottom: 10px;

            cursor: pointer;
            transition: 0.2s linear;
        }

        &-img {
            border-radius: 50%;
            img {
                width: 100px;
                height: 100px;
            }
        }

        &:hover {
            opacity: 1;
            transform: scale(1.1);
        }
    }
    &__upload {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0px;
        padding: 16px 10px;
        border-radius: 5px;
        font-size: 18px;
        color: black;
        background-color: $neutral-white-1f1;
        cursor: pointer;
        transition: 0.2s linear;

        &_img {
            margin: 5px 0 0 8px;
        }

        &_input {
            display: none;
        }
        &:hover {
            opacity: 0.7;
        }
    }
}
