@import "../../../../../../assets/styles/mixin";
@import "../../../../../../assets/styles/main";

.balance__history-item-wrapper {
    border-bottom: 1px solid #dfdfdf;
}

.balance__history-item-wrapper:last-child {
    border: none;
}

.balance__history-item {
    padding: 22px 0 22px 12px;
    display: flex;
    justify-content: space-between;

    @include for-size(phone) {
        padding: 16px 0;
    }

    &-left {
        display: flex;

        &-payment-system {
            width: 32px;
            height: 13px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &-info {
            margin-left: 16px;
            font-size: 15px;
            line-height: 22px;

            @include for-size(small-phone) {
                font-size: 12px;
            }

            &-title {
                font-family: "SF Pro Display Medium";
                color: #000;
            }

            &-date {
                margin-top: 4px;
                color: $light_gray;
            }
        }
    }

    &-right {
        display: flex;

        &-info {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-family: "SF Pro Display Medium";
            font-size: 15px;
            line-height: 22px;
            color: $neutral-black-a32;

            @include for-size(small-phone) {
                font-size: 12px;
            }
        }

        &-download {
            margin-top: 5px;
            display: flex;
            align-items: center;
            font-family: "SF Pro Display Medium";
            font-size: 13px;
            line-height: 22px;
            color: $main_green;
            cursor: pointer;

            @include for-size(small-phone) {
                font-size: 12px;
            }

            svg {
                margin-right: 6px;
            }
        }

        &-arrow {
            margin: 0 20px 0 30px;

            svg {
                cursor: pointer;
            }
        }

        &-arrow-reversed {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}

.balance__history-item-extended {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 14px;
    padding: 16px 55px;
    width: calc(100% - 62px);
    background-color: $light_green;
    border-radius: 8px;
    box-sizing: border-box;

    &-item {
        display: flex;
        align-items: center;
        width: 50%;
        font-size: 15px;
        line-height: 22px;

        &-key {
            width: 130px;
            color: $light_gray;
        }

        &-value {
            color: $neutral-black-a32;
        }

        &-balance {
            display: flex;
            align-items: center;
        }
    }
}
