@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.promote-form {
    background-color: $white;
    padding: 40px;
    border-radius: 10px;
    margin: 0 15px;
    height: auto;
    width: 357px;
    border-radius: 10px;
    margin: 117px auto 117px;

    .select {
        margin-bottom: 19px;

        @include for-size(phone) {
            margin-bottom: 15px;
        }
    }

    @include for-size(phone) {
        margin: 35px auto;
        padding: 15px;
        width: 270px;
    }

    .select__dropdown-open {
        border-bottom: none;
        border-radius: 4px;
    }

    .select__dropdown_body.open {
        position: relative;
    }

    &__title {
        text-align: center;
        font-weight: 400;
    }

    &__title-promotion {
        font-weight: 600;
    }

    &__btn {
        font-size: 17px;
        line-height: 20px;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
        width: 100%;
        height: 50px;
        cursor: pointer;
        transition: all 0.2s linear;
        color: $white;
        background: $main_green;
        border: none;

        @include for-size(phone) {
            font-size: 13px;
            line-height: 16px;
        }

        &:hover {
            opacity: 0.7;
        }

        &[disabled] {
            cursor: not-allowed;
            transition: all 0.2s linear;

            &:hover {
                background: $neutral-light-grey-fdf;
                color: $white;
            }
        }
    }
}
