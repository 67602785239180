@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.discussion_work_project_actions {
    width: 370px;
    border-radius: 10px;
    padding: 19px 0 34px 0;
    background: $white;
    align-self: flex-start;

    @include for-size(tablet) {
        margin: 0 15px 0 0;
        min-width: 310px;
        width: 310px;
    }

    @include for-size(phone) {
        margin-bottom: 15px;
        width: 100%;
        padding: 25px 0;
    }

    &_title {
        color: $gray_43;
        word-break: break-all;
        padding: 0 26px 14px 26px;
        @include text-size(normal, 600, 22px, 32px);
        border-bottom: 1px solid $neutral-light-grey-fdf;

        @include for-size(tablet-phone) {
            @include text-size(normal, 600, 18px, 24px);
            padding: 0 15px 15px 15px;
        }
    }

    &__list {
        padding: 10px 26px 13px 26px;

        @include for-size(tablet-phone) {
            padding: 0 15px;
        }
    }
}
