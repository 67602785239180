@import "../../../../assets/styles/variables";

.promotion-empty-result {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__text {
		margin-top: 10px;
        font-size: 20px;
        color: $neutral-black-a32;
    }
}
