@import "../../../../assets/styles/main";

.update-order-modal {
    width: 1110px;
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 24px 30px 42px 30px;
    border-radius: 10px;
    flex-direction: column;

    @include for-size(phone-475) {
        width: 90%;
    }

    @media (min-width: 475px) and (max-width: 767px) {
        width: 90%;
    }

    @media (min-width: 767px) and (max-width: 1200px) {
        margin: 0 auto;
        width: 95%;
    }

    &__form {
        display: flex;

        @include for-size(phone-475) {
            display: block;
        }

        @media (min-width: 475px) and (max-width: 767px) {
            display: block;
        }

        &-left {
            width: calc(50% - 15px);

            @include for-size(phone-475) {
                width: 95%;
            }

            @media (min-width: 475px) and (max-width: 767px) {
                width: 100%;
            }

            @media (min-width: 767px) and (max-width: 1200px) {
                width: 45%;
            }

            &__item {
                margin: 0 0 40px 0;

                &-title {
                    margin: 0 0 24px 0;
                    font-size: 22px;
                    line-height: 32px;
                    color: #272a32;
                }
            }
        }

        &-right {
            width: calc(50% - 15px);
            margin: 0 0 0 30px;

            @include for-size(phone-475) {
                margin: 0;
                width: 95%;
            }

            @media (min-width: 475px) and (max-width: 767px) {
                margin: 0;
                width: 100%;
            }
            @media (min-width: 767px) and (max-width: 1200px) {
                width: 45%;
            }

            &__item {
                margin: 0 0 40px 0;

                &-title {
                    margin: 0 0 24px 0;
                    font-size: 22px;
                    line-height: 32px;
                    color: #272a32;
                }
            }
            &__option {
                margin-bottom: 20px;
                &-price {
                    cursor: pointer;
                    position: relative;
                    margin-right: 16px;
                    color: $light_gray;
                    @include text-size(normal, 500, 14px, 16px);

                    &:last-of-type {
                        margin-right: 0px;
                    }

                    &--active {
                        position: relative;
                        color: $main-green;
                        @extend .update-order-modal__form-right__option-price;

                        &:after {
                            left: 0;
                            display: block;
                            padding-bottom: 3px;
                            position: absolute;

                            content: "";
                            width: 100%;
                            border-bottom: 1px solid $main-green;
                        }
                    }

                    &:hover {
                        color: $main-green;
                        @extend .update-order-modal__form-right__option-price;
                        transition: 0.2s linear;
                    }
                }
            }
        }
    }

    &__btns {
        display: flex;
        justify-content: center;

        @include for-size(phone-475) {
            width: 90%;
        }

        &-save,
        &-cancel {
            font-size: 17px;
            line-height: 20px;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 270px;
            height: 48px;
            cursor: pointer;
            transition: all 0.2s linear;

            &:hover {
                opacity: 0.7;
            }
        }

        &-cancel {
            color: $black;
            background: $white;
            border: 1px solid $main_green;
            margin: 0 0 0 15px;
        }

        &-save {
            color: $white;
            background: $main_green;
            border: none;
            margin: 0 15px 0 0;

            &[disabled] {
                cursor: not-allowed;
                transition: all 0.2s linear;

                &:hover {
                    background: $neutral-light-grey-fdf;
                    color: $white;
                }
            }
        }
    }
}
