@import "../../../../assets/styles/mixin";

.promotion__right-container-history {
    border-collapse: collapse;
    width: 100%;

    @include for-size(phone) {
        width: 800px;
    }

    &-header {
        background-color: #effbf5;
        height: 40px;

        &-item {
            text-align: left;
            padding-left: 16px;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #434343;
            width: 25%;
            word-break: break-word;
        }
    }

    &-content {
        transition: all 0.3s linear;

        &:hover {
            box-shadow: 0px 0px 10px 0px #33b378;
        }

        &-item {
            border-top: 1px solid #edeef0;
            height: 40px;
            padding-left: 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            word-break: break-word;

            &.disabled {
                color: #bd0a00;
            }

            &.active {
                color: #33b378;
            }
        }
    }
}
