@import "../../../../assets/styles/main";
.delete-form {
    width: 500px;
    padding: 50px 20px;
    box-sizing: border-box;
    text-align: center;
    background: white;
    border-radius: 10px;

    &__title {
        font-size: 20px;
        margin: 0 0 20px;

        &-name {
            font-weight: 600;
            color: #33b378;
        }
    }

    &__btns {
        justify-content: space-around;

        &-cancel {
            font-size: 17px;
            line-height: 20px;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 40%;
            margin-right: 8px;
            height: 50px;
            cursor: pointer;
            transition: all 0.2s linear;
            margin-top: 16px;
            color: $white;
            background: $main_green;
            border: 1px solid $neutral-light-grey-fdf;

            &:hover {
                opacity: 0.7;
            }
        }

        &-delete {
            font-size: 17px;
            line-height: 20px;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 40%;
            height: 50px;
            cursor: pointer;
            transition: all 0.2s linear;
            margin-top: 16px;
            color: $black;
            background: $white;
            border: 1px solid $neutral-light-grey-fdf;

            &:hover {
                background: $red;
                color: $white;
            }
        }
    }
}
