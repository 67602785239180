@import "../../assets/styles/mixin";
@import "../../assets/styles/main";

.terms-of-service {
    margin: 35px auto;
    text-align: center;
    max-width: 1170px;
    height: auto;
    padding: 0 15px;

    @include for-size(phone) {
        margin: 15px auto;
    }

    &__link {
        color: #33b378;
        cursor: pointer;
        font-weight: bold;
        text-decoration: none;
    }

    &__title {
        text-align-last: left;
        padding: 0;
        margin: 0;
        background-color: $main_green;
        color: white;
        font-size: 20px;
        line-height: 24px;
        padding: 15px;
        font-weight: 400;

        @include for-size(phone) {
            font-size: 15px;
            line-height: 18px;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 15px;
        background-color: white;

        &-item {
            padding-bottom: 10px;

            @include for-size(phone) {
                font-size: 13px;
                line-height: 16px;
                padding-bottom: 5px;
            }

            &:last-child {
                padding-bottom: 0;
            }

            &__title {
                font-size: 15px;
                line-height: 18px;
                margin: 0;
                padding: 0;
                text-align: left;

                @include for-size(phone) {
                    font-size: 13px;
                    line-height: 16px;
                }
            }

            &__titles,
            &__subtitles,
            &__titles-item {
                text-align: left;
                margin: 0;
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                padding: 10px 0 0 21px;

                &-bold {
                    font-weight: 600;
                }

                @include for-size(phone) {
                    font-size: 13px;
                    line-height: 16px;
                    padding: 5px 0 0 12.5px;
                }
            }

            &__titles-item {
                padding: 10px 0 0 50px;

                @include for-size(phone) {
                    padding: 5px 0 0 32.5px;
                }
            }

            &__title-space,
            &__titles-space,
            &__titles-item-space {
                padding-right: 10px;

                @include for-size(phone) {
                    padding-right: 5px;
                }
            }
        }
    }
}
