@import "../../../../../assets/styles/mixin";
@import "../../../../../assets/styles/main";

.discussion_work_project_actions__list {
    &_item {
        padding: 10px 0 13px 0;
        border-bottom: 1px solid $neutral-light-grey-fdf;

        &:last-of-type {
            border-bottom: none;
        }

        &_date {
            word-wrap: break-word;
            word-break: break-all;
            color: $neutral-grey-282;
            @include text-size(normal, normal, 17px, 34px);

            @include for-size(tablet-phone) {
                @include text-size(normal, normal, 13px, 16px);
            }
        }

        &_title {
            word-wrap: break-word;
            word-break: break-all;
            color: $neutral-black-a32;
            @include text-size(normal, normal, 17px, 34px);

            @include for-size(tablet-phone) {
                @include text-size(normal, normal, 13px, 16px);
            }
        }
    }
}
