@import "../../../assets/styles/main";

.conditions_container {
    max-width: 606px;
    background: $white;
    border-radius: 10px;
    margin: 117px auto 121px;

    @include for-size(phone-630) {
        margin: 50px auto 121px;
        background: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 54px 42px 65px;
        text-align: center;

        @include for-size(phone-630) {
            padding: 0px 20px 65px 20px;

            .btn.btn--green.btn--large {
                max-width: 100%;
            }
        }
    }

    &__info {
        border-radius: 8px;
        border: 1px solid $gray_background_icons;
        padding: 25px 56px 20px 30px;
        text-align: center;
        margin-bottom: 36px;

        @include for-size(phone-630) {
            padding: 25px 10px 20px 10px;
        }
    }
    &__form {
        width: 100%;
    }

    &__title {
        text-align: center;
        margin: 20px 0 32px;
        @include text-size(normal, 400, 25px, 22px);
    }

    &__description {
        margin: 0;
        color: $light_gray;
        @include text-size(normal, 400, 16px, 22px);

        @include for-size(phone-630) {
            color: $dark_gray;
            @include text-size(normal, 400, 16px, 22px);
        }
    }

    &__text {
        color: $light_gray;
        font-family: "SF Pro Display";
    }

    &__link {
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
        color: $main_green;
    }

    &__check {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 17px;

        input[type="checkbox"] {
            margin-right: 13px;
        }
    }
}
