.image-zoomer {
    max-width: 100%;
    max-height: 85vh;
    line-height: 0;
    padding: 0;
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
    background: #f1f1f1;
    img {
        max-height: 85vh;
        height: 100%;
        max-width: 90vw;
    }
}
