@import "../../../../assets/styles/main";

.notification-settings-form {
    width: 500px;
    padding: 50px 20px;
    box-sizing: border-box;
    text-align: center;
    background: white;
    border-radius: 10px;

    &__title {
        font-size: 20px;
        margin: 0 0 20px;

        &-name {
            font-weight: 600;
            color: #33b378;
        }
    }

    &__types {
        margin: 50px auto 20px auto;
        width: 80%;
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            &-text {
                @include text-size(normal, normal, 18px, 22px);

                margin-left: 12px;
            }
        }
    }

    &__btns {
        justify-content: space-around;

        &-cancel {
            font-size: 17px;
            line-height: 20px;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 40%;
            margin-right: 8px;
            height: 50px;
            cursor: pointer;
            transition: all 0.2s linear;
            margin-top: 16px;
            color: $white;
            background: $main_green;
            border: 1px solid $neutral-light-grey-fdf;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
