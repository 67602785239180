@import "../../assets/styles/mixin";
@import "../../assets/styles/main";

.promotion {
    margin: 0 auto 70px;
    padding: 0 15px;
    max-width: 1170px;
    display: flex;

    @include for-size(phone) {
        flex-direction: column;
        margin: 0 auto 35px;
    }

    &__title {
        margin: 70px auto 46px;
        padding: 0 15px;
        max-width: 1170px;
        font-weight: 700;
        font-size: 36px;
        line-height: 28px;

        @include for-size(phone) {
            margin: 35px auto 15px;
            font-size: 32px;
            text-align: center;
        }
    }

    &__right {
        width: calc(100% - 300px);
        margin-left: 30px;

        @include for-size(phone) {
            margin: 0;
            width: 100%;
        }

        &-container {
            min-height: 430px;
            background-color: $white;
            border-radius: 10px;
            padding: 25px;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            box-sizing: border-box;

            &::-webkit-scrollbar {
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: $main_green;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }

            @include for-size(phone) {
                padding: 15px;
                margin-bottom: 15px;
                overflow: hidden;
                overflow-x: auto;
            }
        }
    }
}
