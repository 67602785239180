@import "../../../../../assets/styles/mixin";
@import "../../../../../assets/styles/main";

.crop-image {
    background-color: $white;
    padding: 35px;
    border-radius: 10px;
    height: auto;
    width: auto;
    max-width: 350px;
    border-radius: 10px;

    @include for-size(phone) {
        padding: 15px;
        max-width: 270px;
    }
    &__promt {
        height: 48px;
        display: flex;
        align-items: center;

        padding: 6px 16px;
        border-radius: 4px;
        background-color: #808080;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        margin-bottom: 10px;

        &-img {
            margin-right: 12px;
            padding: 7px 0px;
        }

        &-text {
            color: $white;
            font-weight: 500;
            font-size: 15px;
            line-height: 1.43;
            letter-spacing: 0.01071em;
        }

        &-active {
            height: 48px;
            display: flex;
            align-items: center;

            padding: 6px 16px;
            border-radius: 4px;
            background-color: rgb(2, 136, 209);
            transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            margin-bottom: 10px;

            &-text {
                color: $gray_68;
                font-weight: 500;
                font-size: 15px;
                line-height: 1.43;
                letter-spacing: 0.01071em;
            }
        }
    }

    &__before {
        width: 100%;

        &-wrapper {
            padding: 0 calc((100% - 215px) / 2);
        }

        &-img {
            width: 100%;
            border-radius: 6px;
        }

        &-btn {
            font-size: 17px;
            line-height: 20px;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 100%;
            height: 50px;
            cursor: pointer;
            transition: all 0.2s linear;
            color: $white;
            background: $main_green;
            border: none;
            margin-top: 15px;

            @include for-size(phone) {
                font-size: 13px;
                line-height: 16px;
            }

            &:hover {
                opacity: 0.7;
            }

            &[disabled] {
                cursor: not-allowed;
                transition: all 0.2s linear;

                &:hover {
                    background: $neutral-light-grey-fdf;
                    color: $white;
                }
            }
        }
    }

    &__after {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        border-top: 1px solid #dfdfdf;
        padding-top: 15px;

        &-img {
            width: 124px;
            height: 124px;
            border-radius: 50%;
            object-fit: cover;
        }

        &-btn {
            font-size: 17px;
            line-height: 20px;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 100%;
            height: 50px;
            cursor: pointer;
            transition: all 0.2s linear;
            color: $white;
            background: $main_green;
            border: none;
            margin-top: 15px;

            @include for-size(phone) {
                font-size: 13px;
                line-height: 16px;
            }

            &:hover {
                opacity: 0.7;
            }

            &[disabled] {
                cursor: not-allowed;
                transition: all 0.2s linear;

                &:hover {
                    background: $neutral-light-grey-fdf;
                    color: $white;
                }
            }
        }
    }

    &__btn {
        font-size: 17px;
        line-height: 20px;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
        width: 100%;
        height: 50px;
        cursor: pointer;
        transition: all 0.2s linear;
        background: $white;
        border: 1px solid #dfdfdf;
        margin-top: 15px;

        @include for-size(phone) {
            font-size: 13px;
            line-height: 16px;
        }

        &:hover {
            opacity: 0.7;
        }

        &[disabled] {
            cursor: not-allowed;
            transition: all 0.2s linear;

            &:hover {
                background: $neutral-light-grey-fdf;
                color: $white;
            }
        }
    }
}
