@import "../../../../assets/styles/_mixin";
@import "../../../../assets/styles/main";

.language-control {
    margin: 15px 0 0 21px;
    position: relative;
    display: flex;
    justify-content: center;

    @include for-size(phone) {
        margin: 0;
    }

    &__selected {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        border: 1px solid $main_green;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        padding: 4px 8px;
        transition: all 0.2s linear;
        background-color: #fff;

        &:hover {
            background: $main_green;

            .language-control__text {
                color: $white;
            }

            .language-control__icon {
                path {
                    fill: $white;
                }
            }
        }

        .active {
            transform: rotate(180deg);
        }
    }

    &__text {
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: $neutral-black-a32;
        margin: 0 0 0 12px;
    }

    &__select {
        &-list {
            width: 100%;
            position: absolute;
            top: 40px;
            padding: 0;
            border-radius: 4px;
            border: 1px solid #33b378;
            width: 123px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: -4px;
                width: 7px;
                height: 7px;
                border-top: 1px solid #33b378;
                background: white;
                z-index: 5;
                left: 50%;
                border-left: 1px solid #33b378;
                transform: rotate(45deg);
            }
        }

        &-item {
            background: $white;
            padding: 4px 9px;
            display: flex;
            z-index: 4;
            padding: 8px;
            transition: all 0.2s linear;
            position: relative;
            box-sizing: border-box;

            &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0px;
                width: 107px;
                height: 1px;
                background: #dfdfdf;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            .language-control__text {
                text-align: center;
                margin-left: 8px;
                display: flex;
                justify-content: center;
            }

            cursor: pointer;
        }
    }
}
