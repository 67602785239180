@import "../../../../assets/styles/main";

.phone-code-modal {
    width: 390px;
    padding: 25px;
    background-color: $white;
    border-radius: 10px;
    box-sizing: border-box;

    @include for-size(phone-475) {
        width: 90%;
        padding: 15px;
        margin: 0 auto;
    }

    &__title {
        font-family: "SF Pro Display Medium";
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: $neutral-black-a32;
    }

    &__description {
        margin: 16px 0;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: $neutral-grey-a6a;
    }

    &__btn {
        width: 100%;
        padding: 13px 0;
        background: $main_green;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: $white;
        text-align: center;
        transition: all 0.2s linear;
        height: 50px;

        &:hover {
            opacity: 0.7;
        }
    }
}
