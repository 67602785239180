@import "../../../../../assets/styles/main";
@import "../../../../../assets/styles/mixin";

.stage {
    max-width: 270px;
    border-radius: 6px;
    margin-right: 30px;
    margin-bottom: 30px;
    background-color: $white;

    &:last-of-type {
        margin-right: 0px;
    }

    @include for-size(tablet-phone) {
        margin-right: 0;
        margin-bottom: 10px;
    }

    &__content {
        padding: 25px 0px 40px 0px;
    }

    &:last-of-type {
        svg {
            display: none;
        }
    }

    &__number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 0 38px 0 30px;

        &_title {
            color: $main_green;
            // margin-right: 74px;
            overflow-wrap: normal;
            word-break: break-all;
            @include text-size(normal, 600, 46px, 66px);

            @include for-size(tablet-phone) {
                word-break: unset;
                font-size: 35px;
            }
        }
    }

    &__info {
        padding: 0 38px 0 30px;
        text-align: left;

        &_title {
            color: $black;
            margin-bottom: 10px;
            word-break: normal;
            word-wrap: break-word;
            @include text-size(normal, 500, 16px, 22px);
        }

        &_description {
            color: $neutral-grey-282;
            word-break: normal;
            word-wrap: break-word;
            @include text-size(normal, normal, 15px, 22px);
        }
    }
}
