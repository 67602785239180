.pagination {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    background: #fff;
    padding: 25px;
    border-radius: 10px;

    li:first-child,
    li:last-child {
        display: flex;
        flex-grow: 1;
        margin: 0;
    }

    li:first-child {
        justify-content: flex-start;
        margin-right: 7px;
    }

    li:last-child {
        justify-content: flex-end;
    }
}

.pagination > li {
    user-select: none;
    outline: none;
    display: inline-block;
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff7e8;
    margin-right: 7px;
    list-style: none;
    background: #f1f1f1;
    border-radius: 4px;
    border: none;
    width: 53px;
    height: 52px;
    transition: all 0.2s linear;

    &:hover {
        opacity: 0.7;
    }
}

.pagination > li > a,
.pagination > li > span {
    position: relative;
    outline: none;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    border: none;
    color: #202438;
    border-radius: 4px;
    transition: all 0.2s linear;

    &:hover {
        opacity: 0.7;
    }
}

.pagination > li.active {
    background: #33b378;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 53px;
    height: 52px;
    transition: all 0.2s linear;

    &:hover {
        opacity: 1;
    }
}

.pagination > li.active > a {
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #fff;
    letter-spacing: -0.01em;
    transition: all 0.2s linear;

    &:hover {
        opacity: 1;
    }
}

.pagination > li > a:hover {
    cursor: pointer;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    outline: none;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #272a32;
    background: #f1f1f1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 90px;
    height: 42px;
    display: flex;
    justify-content: center;
}

.pagination > li:last-child,
.pagination > li:first-child {
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.01em;
    color: #fff7e8;
    border: none;
    transition: all 0.2s linear;
    width: 90px;
    height: 42px;
    background: none;

    &:hover {
        opacity: 0.7;
    }

    &.disabled {
        transition: all 0.2s linear;

        &:hover {
            opacity: 1;
        }

        a {
            cursor: not-allowed;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.pagination-container {
    display: flex;
}
.pagination__count {
    display: none;
}
.pagination__current {
    display: none;
}

@media screen and (max-width: 650px) {
    .pagination-container {
        display: grid;
        grid-template-areas: "a" "b" "c";
        width: 100%;
        background: #fff;
        color: #272a32;
        font-weight: 600;
        border-radius: 10px;
        overflow: hidden;
        box-sizing: border-box;
        padding: 10px;
    }
    .pagination__count {
        display: block;
        grid-area: a;
        justify-self: center;
        margin-top: 10px;
    }
    .pagination__current {
        display: block;
        grid-area: b;
        justify-self: center;
        margin: 5px 0 10px;
    }
    .pagination {
        grid-area: c;
        box-sizing: border-box;
        padding: 0;
        display: flex;
        justify-content: space-between;
        border-radius: 0;
    }
    .pagination > li {
        display: none;
    }
    .pagination > li.active {
        display: none;
    }
    .pagination > li.previous,
    .pagination > li.next {
        flex-grow: 1;
        overflow: hidden;
        & a {
            width: 100%;
            height: 100%;
        }
    }
}
