@import "../../../../assets/styles/main";

.filters {
    display: block;
    height: auto;
    background-color: $white;
    border-radius: 10px;
    padding: 26px;
    word-break: break-word;
    overflow-wrap: normal;
    width: 230px;
    align-self: flex-start;

    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $main_green;
    }
    ::-webkit-scrollbar-track {
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 10px;
        background-color: $gray_c4;
    }

    ::-webkit-scrollbar {
        opacity: 0;
    }
    
    @media screen and (max-width: 900px) {
        padding-top: 6px;
    }

    &__title {
        color: $gray_43;
        padding-bottom: 14px;
        border-bottom: 1px solid $neutral-light-grey-fdf;
        @include text-size(normal, 600, 22px, 32px);
    }

    &__subtitle {
        color: $gray_43;
        @include text-size(normal, 600, 22px, 32px);
        cursor: pointer;
        display: inline-block;
        padding-top: 14px;
    }
}
