@import "../../../../../assets/styles/mixin";
@import "../../../../../assets/styles/main";

.promotion__right-container-services-item {
    border-bottom: 1px solid #dfdfdf;
    padding: 48px 0 24px 0;
    display: flex;

    @include for-size(tablet-phone) {
        padding: 30px 0 15px 0;
        flex-direction: column-reverse;
    }

    &:first-child {
        padding: 0 0 24px 0;

        @include for-size(tablet-phone) {
            padding: 0 0 15px 0;
        }
    }

    &:last-child {
        border-bottom: none;
        padding: 0;
    }

    &:nth-child(3) {
        border-bottom: none;
        padding: 48px 0 0 0;

        @include for-size(tablet-phone) {
            padding: 30px 0 0 0;
        }
    }

    &-left {
        display: flex;
        flex-direction: column;
        width: calc(100% - 369px);

        @include for-size(tablet-phone) {
            width: 100%;
        }

        &-title {
            margin-bottom: 16px;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 32px;
            color: #272a32;
            word-break: break-word;

            @include for-size(tablet-phone) {
                font-size: 19px;
                line-height: 22px;
            }
        }

        &-subtitle {
            margin-bottom: 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #6a6a6a;
            word-break: break-word;

            @include for-size(tablet-phone) {
                font-size: 16px;
                line-height: 19px;
            }
        }

        &-price {
            font-style: normal;
            color: #272a32;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-icon {
                width: 21px;
                height: 21px;

                @include for-size(tablet-phone) {
                    width: 13px;
                    height: 13px;
                }
            }

            &-inner {
                display: flex;
                align-items: center;

                &-title {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 32px;
                    margin-left: 12px;

                    @include for-size(tablet-phone) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
        }

        &-acting {
            &-title {
                color: #ababab;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                padding-bottom: 16px;
                display: block;

                @include for-size(tablet-phone) {
                    font-size: 13px;
                    line-height: 16px;
                }
            }

            &-btns {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 292px;

                @include for-size(tablet-phone) {
                    width: 100%;
                    justify-content: flex-start;

                    .btn.btn--light.btn--medium {
                        &:nth-child(2) {
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }

    &-right {
        width: 345px;
        height: 202px;
        background-color: #f1f1f1;
        border-radius: 10px;
        margin-left: 24px;

        @include for-size(tablet-phone) {
            margin: 0 0 15px 0;
            width: 100%;
        }

        &-img {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
