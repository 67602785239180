$main_green: #33b378;
$green_70: #52e19f;
$green_90: #40c989;
$green_120: #23885a;
$light_green: #effbf5;

$main_background: #e5e5e5;
$gray_background_icons: #edeef0;
$light_gray_background_icons: #f3f4f6;
$white_background_def: #def7eb;
$gray_40: #b6babb;
$gray_6a: #6a6a6a;
$dark_gray: #556267;
$light_gray: #ababab;
$gray_43: #434343;
$gray_c4: #c4c4c4;
$gray_68: #666668;

$dark_blue: #454953;
$black: #000000;
$red: red;
$pink: #ff2a5e;
$light_red: #ff3b30;
$light_orange: #ff9500;
$light_blue: #007aff;
$white: white;

$neutral-black-c2e: #2c2c2e;
$neutral-black-a32: #272a32;
$neutral-light-grey-fdf: #dfdfdf;
$neutral-grey-282: #828282;
$neutral-grey-267: #556267;
$neutral-white-1f1: #f1f1f1;
$neutral-grey-343: #434343;
$neutral-grey-a6a: #6a6a6a;
$neutral-grey-e93: #8e8e93;
