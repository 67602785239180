@import "../../../../assets/styles/mixin";

.feedback-modal {
    &__container {
        box-sizing: border-box;
        width: 500px;
        height: 500px;
        border-radius: 10px;
        background: #fff;
        padding: 24px;

        @include for-size(tablet-phone) {
            padding: 15px;
            height: auto;
            width: 340px;
            margin: 15px 15px;
        }

        @media (max-width: 374px) {
            width: 290px;
        }
    }
    &__header {
        text-align: center;
        font-size: 25px;
        line-height: 30px;
        color: #272a32;

        @include for-size(phone) {
            font-size: 18px;
            line-height: 24px;
        }

        &_text {
            font-weight: 400;
        }

        &_username {
            font-weight: 700;
        }
    }
}
