@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.balance__history {
    width: calc(100% - 390px);
    background-color: #fff;
    border-radius: 10px;
    padding: 36px 0 24px;
    box-sizing: border-box;

    @include for-size(phone) {
        margin-top: 15px;
        width: 100%;
    }

    &-header {
        margin: 0 36px;
        padding-bottom: 26px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px dashed $neutral-light-grey-fdf;

        @include for-size(phone) {
            margin: 0 16px;
        }

        &-left {
            font-family: "SF Pro Display Medium";
            font-size: 24px;
            line-height: 30px;
            color: $neutral-black-a32;
        }
    }

    &-items-container {
        height: 580px;
        padding: 0 36px;
        overflow-y: scroll;

        @include for-size(phone) {
            padding: 0 16px;
        }
    }

    &-footer {
        margin: 0 36px;
        border-top: 1px dashed $neutral-light-grey-fdf;

        @include for-size(phone) {
            margin: 0 16px;
        }

        &-btn {
            margin: 0 auto;
            margin-top: 24px;
            width: 195px;
        }
    }
}

.balance__history-custom-date-range-button {
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-text {
        margin-right: 10px;
        margin-top: 2px;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: $light_gray;
    }
}

.react-datepicker__day--in-range {
    background-color: $main_green;
}
