@import "../../../../assets/styles/main";
.create-order-form {
    max-width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 10px;
    padding: 26px 29px 42px 31px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include for-size(phone) {
        padding: 15px;
    }

    &__content {
        display: flex;

        @include for-size(phone) {
            display: block;
        }

        &-left {
            width: calc(50% - 15px);

            @include for-size(phone) {
                width: 100%;
            }

            &__item {
                margin: 0 0 30px 0;

                @include for-size(phone) {
                    margin: 0 0 15px 0;
                }

                &-title {
                    margin: 0 0 24px 0;
                    font-size: 22px;
                    line-height: 32px;
                    color: #272a32;

                    @include for-size(phone) {
                        font-size: 20px;
                        line-height: 24px;
                        margin: 0 0 15px 0;
                    }
                }
            }
        }

        &-right {
            width: calc(50% - 15px);
            margin: 0 0 0 30px;

            @include for-size(phone) {
                margin: 0;
                width: 100%;
            }

            &__item {
                margin: 0 0 30px 0;

                @include for-size(phone) {
                    margin: 0 0 15px 0;
                }

                &-title {
                    margin: 0 0 24px 0;
                    font-size: 22px;
                    line-height: 32px;
                    color: #272a32;

                    @include for-size(phone) {
                        font-size: 20px;
                        line-height: 24px;
                        margin: 0 0 15px 0;
                    }
                }
            }

            &__option {
                margin-bottom: 20px;
                &-price {
                    cursor: pointer;
                    position: relative;
                    margin-right: 16px;
                    color: $light_gray;
                    @include text-size(normal, 500, 14px, 16px);

                    &:last-of-type {
                        margin-right: 0px;
                    }

                    &--active {
                        position: relative;
                        color: $main-green;
                        @extend .create-order-form__content-right__option-price;

                        &:after {
                            left: 0;
                            display: block;
                            padding-bottom: 3px;
                            position: absolute;

                            content: "";
                            width: 100%;
                            border-bottom: 1px solid $main-green;
                        }
                    }

                    &:hover {
                        color: $main-green;
                        @extend .create-order-form__content-right__option-price;
                        transition: 0.2s linear;
                    }
                }
            }
        }
    }

    &__btns {
        display: flex;
        justify-content: center;
        margin: 42px 0 0 0;

        @include for-size(phone) {
            margin: 0;
        }

        &-left,
        &-right {
            transition: all 0.2s linear;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            text-align: center;
            outline: none;
            width: 270px;
            height: 48px;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

            @include for-size(phone) {
                font-size: 13px;
                line-height: 16px;
            }
        }

        &-left {
            color: #ffffff;
            background: #33b378;
            border-radius: 4px;
            border: none;
            margin: 0 15px 0 0;

            @include for-size(phone) {
                margin: 0 7.5px 0 0;
            }

            &[disabled] {
                cursor: not-allowed;
                transition: all 0.2s linear;

                &:hover {
                    background: #dfdfdf;
                    color: #fff;
                }
            }
        }

        &-right {
            color: #272a32;
            background: #ffffff;
            border: 1px solid #33b378;
            border-radius: 4px;
            margin: 0 0 0 15px;

            @include for-size(phone) {
                margin: 0 0 0 7.5px;
            }
        }
    }
}
