@import "../../../../assets/styles/main";
.customer_card_info {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 22px;
    margin-top: 24px;
    border-top: 1px solid $neutral-light-grey-fdf;
    max-width: calc(1170px - 300px);

    &:last-of-type {
        margin-bottom: 0px;
    }

    @include for-size(phone) {
        padding-top: 10px;
        margin-top: 10px;

        .btn.btn--green.btn--large {
            width: 90%;
            height: 40px;
        }
    }

    @media (min-width: 767px) and (max-width: 1200px) {
        .btn.btn--green.btn--large {
            width: 90%;
        }
    }

    &__img {
        margin-right: 14px;
        &_avatar {
            width: 58px;
            height: 58px;
            object-fit: cover;
            border-radius: 50%;

            @include for-size(phone) {
                width: 45px;
                height: 45px;
            }

            &-active {
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 15px;
                    height: 15px;
                    background-color: $main_green;
                    bottom: 3px;
                    right: 0;
                    z-index: 10;
                    border-radius: 50%;

                    @include for-size(phone) {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }

    &__info {
        max-width: calc(100% - 72px);
        width: 100%;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include for-size(phone) {
            display: block;
        }

        @media (min-width: 767px) {
            display: block;
        }

        &_info {
            &_title {
                color: $gray_43;
                margin-bottom: 4px;
                @include text-size(normal, 600, 16px, 22px);

                @include for-size(phone) {
                    @include text-size(normal, 600, 15px, 18px);
                }
            }

            &_type {
                color: $light_gray;
                margin-bottom: 4px;
                @include text-size(normal, normal, 16px, 22px);

                @include for-size(phone) {
                    @include text-size(normal, 600, 14px, 18px);
                }
            }

            &_price {
                display: flex;
                align-items: center;
                color: $black;
                margin-bottom: 5px;

                @include text-size(normal, bold, 16px, 22px);

                @include for-size(phone) {
                    @include text-size(normal, 600, 14px, 18px);
                }
            }
        }
        &_offer {
            color: $white;
            padding: 13px 51px;
            border: none;
            border-radius: 4px;
            background: $main_green;
            @include text-size(normal, 500, 15px, 22px);

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }

            @include for-size(phone) {
                @include text-size(normal, 600, 14px, 18px);
            }
        }
    }
    &__body {
        max-width: 100%;
        margin-bottom: 5px;
        &_description {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $gray_6a;
            @include text-size(normal, 500, 15px, 22px);

            @media (min-width: 767px) and (max-width: 1200px) {
                width: 90%;
            }
        }
        &_tags {
            &_title {
                margin: 0 0 10px 0;
                color: $neutral-black-a32;
                @include text-size(normal, 600, 15px, 22px);
            }
            &_list {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                &_item {
                    margin: 0 20px 8px 0;
                    padding: 11px 16px;
                    color: $main_green;
                    border-radius: 6px;
                    background: $white_background_def;
                    border: 1px solid $main_green;
                }
            }
        }
    }
}
