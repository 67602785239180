@import "../../../../assets/styles/variables";

.specialist-contacts-modal {
    width: 568px;
    padding: 24px;
    background-color: $white;
    text-align: center;
    box-sizing: border-box;
	border-radius: 10px;

    &__title {
        margin: 24px 0;
        font-size: 25px;
        line-height: 30px;
    }

    &__description {
        font-size: 17px;
        line-height: 24px;
    }

    &__title,
    &__description {
        color: $neutral-black-a32;
    }

    &__buttons {
        margin-top: 24px;
        display: flex;
        justify-content: center;

        &__button-wrapper {
            width: 196px;
        }

        &__button-wrapper:first-child {
            margin-right: 16px;
        }
    }
}
