@import "../../../assets/styles/mixin";

.user-not-select-fields {
    width: 50vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 12px;
    text-align: center;
    padding: 60px;

    @include text-size(normal, 400, 15px, 18px);
}
