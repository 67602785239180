@import "../../assets/styles/variables";
@import "../../assets/styles/mixin";

.faq {
    padding: 0 15px;
    max-width: 1170px;
    margin: 70px auto 70px auto;

    &__header {
        &-title {
            margin: 0 0 32px 0;
            text-align: center;
            @include text-size(normal, 600, 45px, 30px);
            color: $neutral-black-a32;
        }
    }
    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;

        &-item {
            transition: all 0.2s linear;
            width: 100%;
            min-height: 96px;
            margin-bottom: 32px;
            box-sizing: border-box;

            border-radius: 10px;
            padding: 36px 38px 36px 36px;
            background-color: $white;

            cursor: pointer;

            &:last-of-type {
                margin-bottom: 0;
            }

            &-title {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &-text {
                    @include text-size(normal, 600, 20px, 24px);
                    color: $neutral-black-a32;
                }

                &-img {
                    transition: 0.2s linear;
                    svg {
                        width: 24px;
                        height: 12px;
                        path {
                            stroke: $main-green;
                        }
                    }
                    &--active {
                        @extend .faq__content-item-title-img;
                        transform: rotate(180deg);
                    }
                }
            }

            &-description {
                width: 90%;
                padding-top: 24px;
                color: $neutral-grey-282;
                transition: 0.2s linear;
                @include text-size(normal, 500, 15px, 22px);

                &--active {
                    @extend .faq__content-item-description;
                }
            }
        }
    }
}
