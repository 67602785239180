@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixin";

.services {
    padding: 40px 45px;
    margin-bottom: 30px;
    background: $white;
    border-radius: 10px;

    &__title {
        margin: 0 0 25px 0;
        color: $neutral-black-a32;

        font-family: "SF Pro Display";
        @include text-size(normal, 600, 24px, 30px);
    }

    &__category {
        padding: 9px 15px;
        border-radius: 4px;
        background: rgba(237, 238, 240, 0.37);

        font-family: "SF Pro Display";
        @include text-size(normal, 600, 17px, 30px);
    }

    &__item {
        padding: 12px 15px;
        border-bottom: 1px dashed $neutral-light-grey-fdf;

        &__additional {
            width: 100%;
            display: flex;
            justify-content: space-between;

            &-address {
                width: 50%;
                text-align: right;
                word-break: break-word;
            }
        }

        &__comment {
            word-break: break-word;
            margin-top: 16px;
            color: $neutral-grey-282;
            font-family: "SF Pro Display";
            @include text-size(normal, 400, 15px, 30px);
        }
    }
}
.schedule {
    &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        &:last-child {
            padding-bottom: 0;
        }
    }
}
