@import "../../../../../../assets/styles/main";

.specialist_order_list_empty {
    width: 870px;
    height: 384px;
    border-radius: 10px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include for-size(phone) {
        width: 90%;
        margin: 0 auto;
        height: 200px;
    }
    @media (min-width: 767px) and (max-width: 1200px) {
        width: 95%;
        margin: 0 auto;
        height: 262px;
    }
    &__img {
        width: 88px;
        height: 120px;

        @include for-size(phone) {
            height: 80px;
        }
    }

    &__title {
        color: $neutral-black-a32;
        text-align: center;
        @include text-size(normal, 500, 20px, 24px);

        @include for-size(phone) {
            @include text-size(normal, 500, 16px, 30px);
        }
    }
}
