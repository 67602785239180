@import "../../../assets/styles/main";

.confirm_container {
    max-width: 606px;
    height: 410px;
    background: $white;
    border-radius: 10px;
    margin: 117px auto 117px;

    @include for-size(phone-630) {
        background: none;
        margin: 100px auto 100px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 84px 72px;
        text-align: center;

        @include for-size(phone-630) {
            padding: 40px 20px 72px;
        }
    }

    &__title {
        text-align: center;
        margin: 20px 0 32px;

        @include text-size(normal, 400, 25px, 30px);
    }

    &__description {
        margin: 0;
        color: $light_gray;
        @include text-size(normal, 400, 16px, 22px);
    }
    &__img {
        margin-top: 50px;

        @include for-size(phone-630) {
            width: 100px;
            margin-top: 20px;
        }
    }
}
