input[type="range"] {
    -webkit-appearance: none !important;
    background: transparent;
    height: 2px;
    width: 343px;
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    border-radius: 5px;
    background: linear-gradient(#fff, #fff) 0 / var(--sx) 100% no-repeat,
        #8e8e93;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    cursor: pointer;
    height: 6px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    margin-top: -5px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #33b378;
    }
}

.files-preview {
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        width: 100%;
        max-width: 1170px;
        position: relative;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;

        &-video {
            height: 560px;
            width: 1170px;
            object-fit: cover;

            @media (max-width: 1300px) {
                width: calc(100vw - 80px);
            }

            @media (max-width: 767px) {
                width: calc(100vw - 50px);
            }
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        max-width: 343px;
        flex-wrap: wrap;
        border-radius: 10px;
        transition: all 0.2s ease-in-out;
        flex-direction: column;
        margin-top: 20px;

        &-actions {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;

            &-item {
                background: none;
                border: none;
                outline: none;
                cursor: pointer;

                svg path {
                    transition: all 0.2s ease-in-out;
                }

                &:hover > svg path {
                    stroke: #33b378;
                }

                &.download {
                    cursor: default;
                }
            }

            &-play {
                display: flex;

                &-item {
                    background: none;
                    border: none;
                    outline: none;
                    cursor: pointer;

                    svg path {
                        transition: all 0.2s ease-out;
                    }

                    &:hover > svg path {
                        fill: #33b378;
                    }

                    &:nth-child(2) {
                        margin: 0 25px;
                    }
                }
            }
        }

        &-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            width: 100%;

            &-item {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #8e8e93;
            }
        }
    }
}
