.custom-input {
    &-dual-price,
    &-dual {
        &-container {
            width: 100%;
            display: flex;
            align-items: center;
        }

        &-from {
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #272a32;
            margin: 0 10px 0 0;
        }

        &-to {
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #272a32;
            margin: 0 10px;
        }

        &-input {
            text-align: center;
            border: 1px solid #dfdfdf;
            box-sizing: border-box;
            border-radius: 4px;
            height: 50px;
            width: 50%;
            position: relative;
            background: #ffffff;

            &:focus {
                border: 1px solid #33b378;
                outline: none;
                box-sizing: border-box;
                border-radius: 4px;
            }

            &.errors {
                &:focus {
                    outline: none;
                    border: 1px solid red;
                    box-sizing: border-box;
                    border-radius: 4px;
                }
            }
        }
        &-dual-price {
            &-input {
                background: #ffffff
                    url("../../../assets/images/currency/lira.svg") right 15px
                    center no-repeat;
            }
        }
    }

    &-single-input {
        background: #ffffff;
        border: 1px solid #dfdfdf;
        padding: 16px;
        box-sizing: border-box;
        border-radius: 4px;
        height: 51px;
        width: 100%;
        position: relative;

        &:focus {
            border: 1px solid #33b378;
            outline: none;
            box-sizing: border-box;
            border-radius: 4px;
        }

        &.errors {
            &:focus {
                outline: none;
                border: 1px solid red;
                box-sizing: border-box;
                border-radius: 4px;
            }
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.errors {
    border: 1px solid #ff3b30;
}
