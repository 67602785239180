@import "../../../assets/styles/main";

.users_container {
    max-width: 536px;
    top: 231px;
    background: $white;
    border-radius: 10px;
    margin: 157px auto 157px;

    @include for-size(phone-475) {
        max-width: 320px;
        background: none;
        border-radius: 10px;
        margin: 100px auto 100px;
    }

    &__content {
        padding: 40px;

        @include for-size(phone-475) {
            padding: 10px;
        }
    }

    &__types {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        text-align: center;
        margin: 0 0 32px;
        font-size: 25px;
        line-height: 30px;
        font-weight: 400;
    }

    &__btn {
        width: 219px;
        height: 196px;
        border-radius: 6px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        flex-direction: column;
        text-align: center;

        &_decription {
            color: $white;
            font-size: 18px;
            line-height: 21px;

            @include for-size(phone-475) {
                font-size: 15px;
                line-height: 35px;
            }
        }

        @include for-size(phone-475) {
            width: 145px;
            height: 145px;
        }

        @media (min-width: 475px) and (max-width: 520px) {
            width: 195px;
            height: 195px;
        }
    }

    .specialist {
        background-color: $dark_gray;
        transition: 0.1s linear;

        &:hover {
            background-color: $main-green;
        }

        &-active {
            background-color: $main-green;
        }
    }

    .customer {
        background-color: $dark_gray;
        transition: 0.2s linear;

        &:hover {
            background-color: $main-green;
        }

        &-active {
            background-color: $main-green;
        }
    }
}
