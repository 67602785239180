@import "../../../../../../../assets/styles/mixin";
@import "../../../../../../../assets/styles/main";

.decline_propose {
    width: 822px;
    padding: 50px 20px;
    box-sizing: border-box;
    text-align: center;
    background: $white;
    border-radius: 10px;

    @include for-size(tablet-phone) {
        padding: 35px 20px;
        width: auto;
        margin: 15px;
    }

    &_title {
        color: $neutral-black-a32;
        @include text-size(normal, normal, 20px, 24px);

        @include for-size(phone) {
            @include text-size(normal, normal, 20px, 24px);
        }

        &_marked {
            color: $black;
            @include text-size(normal, bold, 18px, 24px);

            @include for-size(phone) {
                @include text-size(normal, bold, 18px, 24px);
            }
        }
    }

    &_subtitle {
        color: $neutral-black-a32;
        @include text-size(normal, normal, 20px, 24px);

        @include for-size(phone) {
            @include text-size(normal, normal, 14px, 16px);
        }
    }

    &_services {
        display: flex;
        padding: 0 145px;
        justify-content: space-between;
        margin-top: 30px;

        @include for-size(phone) {
            padding: 0;
            justify-content: center;
            margin-top: 15px;
        }

        &_cancel {
            width: 255px;
            margin-right: 24px;
            padding: 13px 5px;
            text-align: center;
            color: $white;
            background: $main_green;
            border: none;
            border-radius: 4px;
            transition: 0.1s linear;

            @include for-size(phone) {
                font-size: 13px;
                left: 16px;
                padding: 11px 5px;
            }

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }

        &_decline {
            width: 255px;
            padding: 13px 5px;
            border-radius: 4px;
            text-align: center;
            background: $white;
            color: $neutral-black-a32;
            border: 1px solid $main_green;

            @include for-size(phone) {
                font-size: 13px;
                left: 16px;
                padding: 11px 5px;
            }

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }
}
