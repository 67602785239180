.assessment-picker {
    &__container {
        display: flex;
        align-items: center;
    }
    &__label {
        line-height: 1;
    }
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s linear;
        &_cheked {
            background: #def7eb;
        }
    }
    &__input {
        display: none;
    }
}
