@import "../../assets/styles/main";

.create-order {
    margin: 69px auto 165px auto;
    max-width: 1170px;

    @media (max-width: 1200px) {
        margin: 35px 15px;
        max-width: 100%;
    }
}
