@import "../../assets/styles/mixin";

.support {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 1170px;
    margin: 69px auto 69px auto;
    padding: 0 15px;
    height: auto;

    @include for-size(phone) {
        margin: 35px auto 35px auto;
    }

    &__header {
        display: flex;
        margin-bottom: 39px;

        @include for-size(phone) {
            flex-direction: column;
            margin-bottom: 25px;
        }

        &-content {
            display: flex;
            flex-direction: column;
            flex-grow: 2;

            @include for-size(phone) {
                margin: 25px 0 0 0;
            }

            &-title {
                font-weight: 600;
                font-size: 45px;
                line-height: 30px;
                text-align: center;
                color: #272a32;

                @include for-size(phone) {
                    font-size: 32px;
                    line-height: 38px;
                }
            }
        }
    }

    &__body {
        display: flex;
        width: 100%;

        @include for-size(phone) {
            flex-direction: column;
        }

        &-left {
            margin-right: 50px;

            @include for-size(phone) {
                width: 100%;
                margin-right: 0;
                display: flex;
                flex-direction: column-reverse;
            }
        }

        &-right {
            width: calc(100% - 300px);

            @include for-size(phone) {
                width: 100%;
            }
        }
    }
}
