@import "../../../../assets/styles/main";

.error-component {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    margin: 85px auto;
    max-width: 1170px;
    min-height: calc(100vh - 240px);
    height: auto;
    border-radius: 10px;

    @include for-size(phone) {
        margin: 30px auto;
        background-color: initial;
    }

    &__image {
        margin: 75px 0 25px 0;
        max-width: 100%;
    }

    &__title {
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: #272a32;

        @include for-size(phone) {
            padding: 0 15px;
            font-size: 22px;
            line-height: 28px;
        }
    }

    &__description {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #ababab;
        margin: 25px 0;
        width: 337px;
        word-break: break-word;

        @include for-size(phone) {
            width: 280px;
            font-size: 13px;
            line-height: 16px;
        }
    }

    &__subdescription {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #ababab;
        width: 337px;

        @include for-size(phone) {
            width: 280px;
            font-size: 13px;
            line-height: 16px;
        }
    }

    &__redirect {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #272a32;
        padding-top: 15px;
        text-decoration: none;

        @include for-size(phone) {
            font-size: 13px;
            line-height: 21px;
        }
    }

    &__button {
        margin: 0 0 98px 0;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        background: #33b378;
        border-radius: 4px;
        width: 201px;
        height: 48px;
        color: #ffffff;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all 0.2s linear;

        &:hover {
            opacity: 0.7;
        }

        @include for-size(phone) {
            font-size: 13px;
            line-height: 22px;
        }
    }

    &__not-found {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 75px;
        width: 100%;

        &-title {
            font-weight: 600;
            font-size: 75px;
            line-height: 90px;
            text-align: center;
            color: #272a32;
        }

        &-subtitle {
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            color: #272a32;
        }

        &-image {
            max-width: 100%;
            margin: 33px 0 8px 0;
        }
    }
}
