@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/mixin";

%order-item-pseudo-el {
    content: "";
    display: block;
    position: absolute;
    width: 70px;
    height: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $main_green;
}

.order-item {
    padding: 40px 45px 55px 45px;
    border-radius: 10px 10px 0 0;
    background: $white;
    margin-bottom: 20px;

    @include for-size(phone) {
        padding: 15px 10px 15px 10px;
        margin-bottom: 20px;
    }

    &__text-area {
        padding: 15px;
        border: 1px dashed $neutral-light-grey-fdf;
        border-radius: 1px;
        color: $light_gray;
        position: relative;
        width: 348px;
        height: 141px;
        resize: none;

        &:focus {
            border: 1px dashed $neutral-light-grey-fdf;
            outline: none;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background: #33b378;
        }

        @include for-size(phone) {
            width: 85%;
        }

        @media (min-width: 767px) and (max-width: 820px) {
            width: 85%;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        @include for-size(phone) {
            margin-bottom: 20px;
        }

        &__title {
            margin: 0;

            font-family: "SF Pro Display";
            @include text-size(normal, 600, 24px, 30px);

            @include for-size(phone-475) {
                // text-align: center;
                @include text-size(normal, 600, 16px, 18px);
            }
            @media (min-width: 475px) and (max-width: 767px) {
                @include text-size(normal, 600, 18px, 20px);
            }
            @media (min-width: 767px) and (max-width: 1200px) {
                @include text-size(normal, 600, 22px, 22px);
            }
        }

        &__controls {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 75px;
            margin-left: 10px;

            &-delete,
            &-edit {
                margin-left: 5px;
                cursor: pointer;
                transition: all 0.2s linear;

                &:hover {
                    opacity: 0.7;
                }

                @include for-size(phone) {
                    img {
                        height: 25px;
                    }
                }
            }

            &__counter {
                display: flex;
                align-items: center;
                svg {
                    margin-left: 5px;
                }

                ~ svg {
                    cursor: pointer;
                }
            }
        }
    }

    &__divider {
        height: 1px;
        width: 100%;
        background: $neutral-light-grey-fdf;
    }

    &__body {
        margin-top: 30px;
        display: flex;

        @include for-size(phone) {
            display: block;
        }
        @media (min-width: 767px) and (max-width: 1200px) {
            display: block;
        }

        &--left-column {
            width: calc(50% - 10px);
            margin-right: 20px;

            @include for-size(phone) {
                margin-right: 0px;
                margin-bottom: 8px;
                width: 100%;
            }

            @media (min-width: 767px) and (max-width: 1200px) {
                margin-right: 0px;
                margin-bottom: 8px;
                width: 100%;
            }
        }

        &--right-column {
            width: calc(50% - 10px);

            @include for-size(phone) {
                width: 100%;
            }

            @media (min-width: 767px) and (max-width: 1200px) {
                width: 100%;
            }
        }

        &__caption {
            padding: 9px 15px;
            color: $neutral-black-a32;
            background: rgba(237, 238, 240, 0.37);
            border-radius: 4px;
            margin-top: 12px;

            font-family: "SF Pro Display Medium";
            @include text-size(normal, 400, 17px, 30px);

            &:first-child {
                margin-top: 0;
            }

            @include for-size(phone-475) {
                padding: 5px 15px;
            }
        }

        &__description {
            text-align: right;
            color: $neutral-black-a32;

            font-family: "SF Pro Display";
            @include text-size(normal, 400, 15px, 18px);

            @include for-size(phone-475) {
                width: 45%;
                word-wrap: break-word;
            }
            @media (min-width: 475px) and (max-width: 1200px) {
                width: 50%;
                word-wrap: break-word;
            }

            &-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px 15px;
                border-bottom: 1px dashed $neutral-light-grey-fdf;

                &-no-dash {
                    border-bottom: none;
                    @extend .order-item__body__description-container;
                }
            }
        }
        &__value {
            color: $neutral-black-a32;

            font-family: "SF Pro Display";
            @include text-size(normal, 400, 15px, 18px);

            @include for-size(phone-475) {
                word-wrap: break-word;
                width: 45%;
            }
            @media (min-width: 475px) and (max-width: 767px) {
                text-align: left;
                width: 50%;
                word-wrap: break-word;
            }
        }

        &__comment-field {
            width: 100%;
            height: 173px;
            margin-top: 12px;
            position: relative;
        }
    }

    &__photos {
        display: flex;
        overflow: auto;
        padding-top: 12px;
        margin-top: -12px;
        padding-bottom: 11px;

        &-empty {
            padding: 12px;
        }

        &::-webkit-scrollbar {
            height: 12px;
            border-radius: 10px;
            background-color: #fff;
            border: 1px solid #33b378;
        }

        &::-webkit-scrollbar-thumb {
            background: #33b378;
            border-radius: 10px;
        }

        &__item {
            margin-right: 20px;
            position: relative;

            &-img {
                width: 173px;
                height: 173px;
                object-fit: cover;
                border-radius: 10px;
            }

            &-close {
                position: absolute;
                cursor: pointer;
                z-index: 1000;

                top: -10px;
                right: -10px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__photo-uploader {
        position: relative;
        min-width: 173px;
        height: 173px;
        border: 1px solid $neutral-light-grey-fdf;
        border-radius: 10px;
        margin-right: 20px;
        cursor: pointer;

        &::before {
            @extend %order-item-pseudo-el;

            width: 70px;
            height: 4px;
        }

        &::after {
            @extend %order-item-pseudo-el;

            width: 4px;
            height: 70px;
        }
    }
}
