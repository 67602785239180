@import "../../../../assets/styles/main";

.favourites-item {
    padding: 16px;
    background-color: $white;
    border-radius: 10px;

    &__icon {
        display: flex;
        justify-content: flex-end;
    }

    &__avatar {
        width: 104px;
        height: 104px;
        margin: 24px auto;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__name {
        font-family: "SF Pro Display Bold";
        font-size: 22px;
        line-height: 28px;
        color: $neutral-grey-343;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        word-break: break-word;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 1;

        &-first {
            margin-left: 11px;
        }
    }

    &__role {
        margin: 4px 0;
        text-align: center;
        color: $neutral-grey-282;
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        line-height: 20px;
    }

    &__rate {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $neutral-black-a32;
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        line-height: 20px;

        &-icon {
            width: 16px;
            height: 16px;
            margin-left: 4px;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__location {
        font-size: 15px;
        line-height: 20px;

        div:last-child {
            margin: 8px 0 24px;
        }

        &-key {
            font-family: "SF Pro Display Bold";
            color: $neutral-black-a32;
        }

        &-value {
            color: $neutral-grey-282;
        }
    }

    &__specialist-buttons {
        display: flex;
        justify-content: space-between;
    }

    &__button {
        width: 48%;
        height: 40px;
        background-color: $main_green;
        border: none;
        border-radius: 4px;
        color: $white;
        font-size: 15px;
        line-height: 22px;
        cursor: pointer;
        transition: all 0.2s linear;

        &:hover {
            opacity: 0.7;
        }

        &-questionnaire {
            background-color: $white;
            color: $neutral-black-a32;
            border: 2px solid $main_green;
        }

        &-customer {
            width: 100%;
        }
    }
}
