@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.promotion__leftbar {
    background-color: $white;
    align-self: flex-start;
    border-radius: 10px;
    padding: 25px;
    width: 220px;
    position: sticky;
    top: 90px;

    @include for-size(phone) {
        width: auto;
        padding: 15px;
        margin-bottom: 15px;
        display: flex;
        align-self: center;
        justify-content: center;
        flex-wrap: wrap;
        position: initial;
        width: calc(100% - 30px);
    }

    &-item {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;

        &:last-child {
            margin-bottom: 0;

            @include for-size(phone) {
                justify-content: center;
                margin: 0 15px 8px 0;
            }
        }

        @include for-size(phone) {
            margin: 0 15px 8px 0;
        }

        &-circle {
            width: 12px;
            height: 12px;
            background-color: $gray_43;
            display: block;
            border-radius: 10px;
            transition: all 0.3s linear;

            @include for-size(phone) {
                width: 8px;
                height: 8px;
                margin-right: 5px;
            }
        }

        &-title {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 34px;
            color: $gray_43;
            margin-left: 25px;
            transition: all 0.3s linear;

            @include for-size(phone) {
                font-size: 15px;
                line-height: 15px;
                margin-left: 0;
            }
        }

        &.condition {
            &.active,
            &:hover {
                .promotion__leftbar-item-title {
                    color: $main_green;
                }
                .promotion__leftbar-item-circle {
                    background-color: $main_green;
                }
            }
        }

        &.acting {
            &.active,
            &:hover {
                .promotion__leftbar-item-title {
                    color: $light_orange;
                }
                .promotion__leftbar-item-circle {
                    background-color: $light_orange;
                }
            }
        }

        &.history {
            .promotion__leftbar-item-circle {
                background-color: $neutral-grey-282;
            }

            &.active,
            &:hover {
                .promotion__leftbar-item-title {
                    color: $light_blue;
                }
                .promotion__leftbar-item-circle {
                    background-color: $light_blue;
                }
            }
        }
    }
}
