@import "../../../../../assets/styles/main";

.uploaded {
    &-image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &-container {
            cursor: pointer;
            overflow: hidden;
            position: relative;
            border-radius: 50%;
            width: 124px;
            height: 124px;

            &_delete {
                position: absolute;
                width: 124px;
                height: 40px;
                bottom: 0px;

                &_slide {
                    display: flex;
                    position: absolute;
                    justify-content: center;
                    height: 45px;
                    width: 130px;
                    bottom: -45px;
                    opacity: 0.9;
                    background-color: $main_green;
                    color: $white;

                    &_text {
                        font-size: 13px;
                        padding-top: 10px;
                        font-weight: 500;
                        line-height: 16px;
                        font-family: "SF Pro Display";
                        font-style: normal;
                    }
                    &:hover {
                        opacity: 1;
                        .uploaded-image-container_delete_slide_text {
                            font-size: 15px;
                        }
                    }
                }
            }
            &:hover {
                .uploaded-image-container_delete_slide {
                    transition: 0.3s linear;
                    bottom: 0px;
                }
            }
        }
    }

    &-container {
        display: flex;
        align-items: center;
        border: 1px solid $neutral-white-1f1;
        padding: 7px;
        border-radius: 50%;
        position: relative;

        &-plus {
            position: absolute;
            bottom: -6px;
            right: -10px;
            cursor: pointer;
        }
    }
}
