@import "../styles/main";

html {
    padding: env(safe-area-inset);
    margin: env(safe-area-inset);
    -webkit-padding: env(safe-area-inset);
    -webkit-margin: env(safe-area-inset);
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url("../images/background-images/background_main_icons.png");
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "SF Pro Display";
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
}
input::placeholder {
    color: #3c3c434d;
}

* {
    margin: 0;
    padding: 0;
}

body::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
    border: 1px solid #33b378;
}

body::-webkit-scrollbar-thumb {
    background: #33b378;
}
