@import "../../assets/styles/mixin";

.chats {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 1170px;
    margin: 69px auto 69px auto;
    min-height: calc(100vh - 524px);
    padding: 0 15px;

    @include for-size(phone-630) {
        margin: 35px auto 35px auto;
    }

    &__header {
        display: flex;
        margin-bottom: 39px;

        @include for-size(phone-630) {
            flex-direction: column;
            margin-bottom: 25px;
        }

        &-content {
            display: flex;
            flex-direction: column;
            flex-grow: 2;
            margin: 0 200px 0 0;

            @include for-size(phone-630) {
                margin: 25px 0 0 0;
            }

            &-title {
                font-weight: 600;
                font-size: 45px;
                line-height: 30px;
                text-align: center;
                color: #272a32;

                @include for-size(phone-630) {
                    font-size: 32px;
                    line-height: 38px;
                }
            }
        }
    }
}
