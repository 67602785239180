@import "../../assets/styles/main";

.form-container {
    position: relative;

    &__input {
        outline: 0;
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: 1px solid $neutral-light-grey-fdf;
        padding: 10px 15px;
        margin: 18px 0;
        font-size: 16px;

        &:focus {
            box-sizing: border-box;
            border: 1px solid $main_green !important;
        }
    }
    &__password {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: 1px solid $neutral-light-grey-fdf;
        padding: 0 15px;
        background-color: white;
        margin: 0 0 8px 0;
        font-size: 16px;
        outline: 0;
        :last-of-type {
            margin: 0;
        }
        &_input {
            height: 100%;
            width: 90%;
            border: none;
            outline: none;
        }
        &_image {
            cursor: pointer;
        }

        &--error {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 50px;

            outline: 0;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid $light_red;
            background-color: white;
            padding: 0 15px;
            margin: 0 0 8px 0;
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__label {
        margin-bottom: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $neutral-black-a32;
    }
    &__error {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $light_red;
    }
    &__register_input {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: 1px solid $neutral-light-grey-fdf;
        padding: 10px 15px;
        margin: 0 0 8px 0;
        font-size: 16px;
        outline: 0;
        :last-of-type {
            margin: 0;
        }
        &--disable {
            outline: 0;
            box-sizing: border-box;
            opacity: 0.7;
            width: 100%;
            height: 50px;
            border-radius: 4px;
            border: 1px solid $neutral-light-grey-fdf;
            padding: 10px 15px;
            margin: 0 0 8px 0;
            font-size: 16px;
            cursor: not-allowed;
        }
        &--error {
            outline: 0;
            box-sizing: border-box;
            width: 100%;
            height: 50px;
            border-radius: 4px;
            border: 1px solid $light_red;
            padding: 10px 15px;
            margin: 0 0 8px 0;
            font-size: 16px;
        }
    }

    &__register_textarea {
        box-sizing: border-box;
        resize: vertical;
        width: 100%;
        height: 114px;
        border-radius: 4px;
        border: 1px solid $neutral-light-grey-fdf;
        padding: 10px 15px;
        margin: 0 0 8px 0;
        font-size: 16px;
    }
    &__fixed_price {
        display: flex;
        width: 100%;

        &_input {
            box-sizing: border-box;
            width: 100%;
            height: 50px;
            border-radius: 4px;
            border: 1px solid $neutral-light-grey-fdf;
            padding: 10px 15px;
            font-size: 16px;
            outline: 0;
            :last-of-type {
                margin: 0;
            }
            &--disable {
                outline: 0;
                box-sizing: border-box;
                opacity: 0.7;
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid $neutral-light-grey-fdf;
                padding: 10px 15px;
                margin: 0 0 8px 0;
                font-size: 16px;
                cursor: not-allowed;
            }
            &--error {
                outline: 0;
                box-sizing: border-box;
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid $light_red;
                padding: 10px 15px;
                margin: 0 0 8px 0;
                font-size: 16px;
            }
        }
        &_currency {
            margin-left: 8px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10%;
            padding: 0 25px;

            border: 1px solid $neutral-light-grey-fdf;
            border-radius: 4px;

            svg {
                path {
                    fill: $main_green;
                }
            }
            &--error {
                @extend .form-container__fixed_price_currency;
                border: 1px solid $light_red;
            }
        }
    }
    &__with-icon {
        position: absolute;
        top: 40px;
        right: 18px;
    }
}
