@import "../../assets/styles/mixin";

.specialist-list {
    display: flex;
    flex-direction: column;
    margin: 69px auto 165px auto;
    text-align: center;
    max-width: 1170px;
    min-height: calc(100vh - 620px);

    @media (max-width: 1200px) {
        padding: 0 15px;
    }

    @include for-size(tablet-phone) {
        padding: 0 15px;
        box-sizing: border-box;
        width: 100%;
        align-items: center;
        margin: 35px auto;
    }
    .error-component {
        max-width: 1170px;
        margin: 0;
    }

    .pagination {
        @include for-size(phone-630) {
            flex-direction: column;

            li {
                margin: 5px 0;
            }
        }
    }

    &__header {
        display: flex;

        @include for-size(tablet-phone) {
            flex-direction: column;
        }

        &-btn {
            @include for-size(tablet-phone) {
                margin: 0 auto;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            flex-grow: 2;
            margin: 0 100px 0 0;
            @include for-size(tablet-phone) {
                margin: 20px 0 0 0;
            }
            &-title {
                font-weight: 600;
                font-size: 45px;
                line-height: 30px;
                text-align: center;
                color: #272a32;
            }

            &-subtitle {
                font-size: 18px;
                line-height: 22px;
                color: #828282;
                margin: 22px 0 10px 0;
            }
        }
    }
}
