.rating {
    background-image: url("../../assets/images/specialist-profile/star-gray.svg");
    width: 100px;
    background-repeat: repeat-x;
    height: 18px;
    display: block;
    background-size: 20px 18px;

    &--yellow {
        @extend .rating;
        background-image: url("../../assets/images/specialist-profile/star.svg");
    }
}
