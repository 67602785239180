@import "../../../../../../assets/styles/mixin";
@import "../../../../../../assets/styles/main";

.info_about_order {
    &__inner {
        padding: 59px 0 160px 0;
        margin: 0 auto;
        max-width: 1170px;

        @media (max-width: 1200px) {
            margin: 0 15px;
        }

        @include for-size(phone) {
            padding: 35px 0;
        }
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 46px;

        @include for-size(phone) {
            margin-bottom: 15px;
            flex-direction: column;
            align-items: flex-start;
        }

        &_title {
            text-align: center;
            color: $neutral-black-a32;
            @include text-size(normal, 700, 30px, 28px);
            word-break: break-word;
            width: 100%;

            @include for-size(phone) {
                margin: 15px 0 15px 0;
                @include text-size(normal, 700, 20px, 24px);
                align-self: center;
                text-align: center;
                width: 100%;
            }
        }
    }
}
