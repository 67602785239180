@import "../../assets/styles/main";

.auth_confirmation {
    max-width: 545px;
    border-radius: 10px;
    margin: 117px auto 117px;

    @include for-size(phone-475) {
        background: none;
        border-radius: 10px;
        margin: 100px auto 100px;
        padding: 0 16px;
    }

    &__info {
        align-items: center;
        text-align: center;
    }

    &__description {
        color: $light_gray;
        @include text-size(normal, 400, 17px, 22px);

        @include for-size(phone-475) {
            @include text-size(normal, 400, 13px, 18px);
        }
    }

    &__title {
        color: $neutral-black-a32;
        margin: 0 0 16px auto;

        @include text-size(normal, 600, 45px, 57px);

        @include for-size(phone-475) {
            @include text-size(normal, 600, 20px, 30px);
        }
    }

    &__form {
        max-width: 343px;
        margin: 0 auto;

        @include for-size(phone-475) {
            max-width: 440px;
        }
    }

    &__active_buttons {
        margin-top: 36px;
        display: flex;
        justify-content: space-between;
        row-gap: 10px;

        button {
            width: 166px;
            height: 46px;
            font-size: 15px;
            color: $neutral-grey-e93;
            background-color: $neutral-light-grey-fdf;
            border: none;
            border-radius: 4px;
            cursor: pointer;

            @media screen and (max-width: 375px) {
                width: 48%;
            }
        }

        .active {
            background-color: $main_green;
            color: $white;
        }
    }

    &__content {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__phone-form {
        margin-bottom: 8px;
    }

    &__label {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;

        color: $neutral-black-a32;
    }

    &__question-tip {
        margin: 8px 0 16px;
        font-size: 13px;
        text-align: right;

        span {
            font-family: "SF Pro Display Medium";
            color: $main_green;
            cursor: pointer;
        }
    }

    &__btn {
        padding: 13px 0;
        background: $main_green;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: $white;
        text-align: center;
        transition: all 0.2s linear;
        height: 50px;

        &:hover {
            opacity: 0.7;
        }

        &[disabled] {
            cursor: not-allowed;
            transition: all 0.2s linear;

            &:hover {
                background: $neutral-light-grey-fdf;
                color: $white;
            }
        }
    }
}
