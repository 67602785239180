@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.discussion_work_project_profile {
    width: 370px;
    background: $white;
    border-radius: 10px;
    padding: 43px 0 70px 0;
    margin-bottom: 30px;

    @include for-size(tablet) {
        margin: 0 15px 15px 0;
        min-width: 310px;
        width: 310px;
    }

    @include for-size(phone) {
        margin-bottom: 15px;
        width: 100%;
        padding: 25px 0;
    }

    &__header {
        padding: 0 36px;
        text-align: center;
        margin-bottom: 33px;

        @include for-size(phone) {
            margin-bottom: 15px;
            padding: 0 15px;
        }

        &_img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            margin-bottom: 25px;
            object-fit: cover;

            @include for-size(phone) {
                margin-bottom: 15px;
                width: 100px;
                height: 100px;
            }
        }

        &_title {
            word-wrap: break-word;
            margin-bottom: 6px;
            color: $neutral-black-a32;
            @include text-size(normal, 600, 20px, 24px);

            @include for-size(phone) {
                @include text-size(normal, 600, 16px, 19px);
            }
        }

        &_type {
            word-wrap: break-word;
            color: $neutral-grey-282;
            @include text-size(normal, normal, 15px, 18px);

            @include for-size(phone) {
                @include text-size(normal, normal, 13px, 16px);
            }
        }
    }

    &__rate {
        width: 164px;
        position: relative;
        margin: 0 auto 25px auto;

        @include for-size(phone) {
            margin: 0 auto 15px auto;
        }

        &:before {
            position: absolute;
            content: "";
            width: 70px;
            left: -42%;
            top: 50%;
            height: 1px;
            background-color: $neutral-light-grey-fdf;
            cursor: default;
        }

        &:after {
            position: absolute;
            content: "";
            width: 70px;
            right: -42%;
            top: 50%;
            height: 1px;
            background-color: $neutral-light-grey-fdf;
            cursor: default;
        }

        &_inner {
            z-index: 10;
            display: flex;
            position: relative;
            background: $white;
            border: 1px solid $neutral-light-grey-fdf;
            border-radius: 6px;
            justify-content: space-around;
            cursor: pointer;
            transition: all 0.3s linear;

            &:hover {
                box-shadow: 0px 0px 10px 0px #33b378;
            }
        }

        &_left {
            z-index: 10;
            display: flex;
            position: relative;
            align-items: center;
            padding: 16px 21px 16px 18px;
            border-right: 1px solid $neutral-light-grey-fdf;

            &_img {
                margin-right: 5px;
            }

            &_number {
                color: $neutral-black-a32;
                @include text-size(normal, normal, 15px, 18px);
            }
        }

        &_right {
            z-index: 2;
            align-items: center;
            display: flex;
            padding: 16px 22px 16px 16px;

            &_img {
                margin-top: 5px;
                margin-right: 5px;
            }
        }
    }

    &__info {
        padding: 0 34px;

        @include for-size(phone) {
            padding: 0 15px;
        }

        &_inner {
            display: flex;
            padding-bottom: 32px;
            border-bottom: 1px solid $neutral-light-grey-fdf;

            @include for-size(phone) {
                padding-bottom: 15px;
            }
        }

        &_descriptions {
            width: 55%;
            word-wrap: break-word;

            &_item {
                margin-bottom: 8px;
                color: $neutral-black-a32;
                @include text-size(normal, 600, 15px, 22px);

                @include for-size(phone) {
                    @include text-size(normal, 600, 13px, 16px);
                }

                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }

        &_values {
            width: 45%;

            &_item {
                margin-bottom: 8px;
                color: $neutral-grey-282;
                @include text-size(normal, 600, 15px, 22px);

                @include for-size(phone) {
                    @include text-size(normal, 600, 13px, 16px);
                }

                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }
    }

    &__services {
        padding: 25px 34px 0 34px;

        @include for-size(phone) {
            padding: 15px 15px 0 15px;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 18px;
            border-radius: 4px;
            transition: 0.1s linear;
            background-color: $white;
            border: 2px solid $main_green;
            height: 46px;

            @include for-size(phone) {
                margin-bottom: 15px;
                @include text-size(normal, 600, 13px, 16px);
                height: 40px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &_img {
                margin-right: 11px;
            }

            &:hover {
                color: $white;
                cursor: pointer;
                background-color: $main_green;

                svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }
    }
}
