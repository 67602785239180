@font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    src: local("SF Pro Display"),
        url(../fonts/SFProDisplay/SF-Pro-Display-Light.otf) format("truetype");
}

@font-face {
    font-family: "SF Pro Display Medium";
    font-style: normal;
    src: local("SF Pro Display Medium"),
        url(../fonts/SFProDisplay/SF-Pro-Display-Medium.OTF) format("truetype");
}

@font-face {
    font-family: "SF Pro Display Bold";
    font-style: normal;
    src: local("SF Pro Display Bold"),
        url(../fonts/SFProDisplay/SF-Pro-Display-Bold.OTF) format("truetype");
}
