@import "../../../../../assets/styles/main";
@import "../../../../../assets/styles/mixin";

.category_item {
    width: 180px;
    height: 180px;
    border-radius: 6px;
    background-color: $white;
    transition: 0.2s linear;
    cursor: pointer;
    margin: 0px 16px 16px 0px;
    transition: all 0.2s linear;

    &:nth-child(6) {
        margin-right: 0;
    }

    @media (max-width: 1390px) {
        &:nth-child(6) {
            margin-right: 16px;
        }
    }

    @include for-size(phone-630) {
        margin-bottom: 20px;
        margin-right: 0;

        &:nth-child(6) {
            margin-right: 0;
        }
    }

    &__container {
        padding: 44px 24px 30px 24px;
        display: flex;
        align-items: center;
        flex-direction: column;

        margin: auto 0;
        text-align: center;
    }

    &__image {
        margin-bottom: 18px;
        width: 34px;
        height: 33px;
    }

    &__description {
        margin: 0;
        transition: all 0.3s linear;
        font-size: 15px;
        line-height: 18px;
        font-style: normal;
        font-weight: normal;
        text-align: center;
        color: $black;
    }

    &:hover {
        background-color: #556267;

        .category_item__description {
            color: $white;
        }
    }
}

.category_item__loading {
    background-color: $neutral-light-grey-fdf;
    width: 180px;
    height: 180px;
    border-radius: 6px;
    transition: 0.2s linear;
    margin: 0px 16px 16px 0px;

    &:nth-child(6) {
        margin-right: 0;
    }

    @media (max-width: 1390px) {
        &:nth-child(6) {
            margin-right: 16px;
        }
    }

    @include for-size(phone-630) {
        margin-bottom: 20px;
        margin-right: 0;

        &:nth-child(6) {
            margin-right: 0;
        }
    }
}
