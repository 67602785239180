@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/_main";

.feedback-form {
    margin: 0;
    padding: 0;
    color: $neutral-black-a32;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;

    @include for-size(phone) {
        font-size: 13px;
        line-height: 16px;
    }

    &__assessment-picker-container {
        display: flex;
        justify-content: center;
        padding: 32px 0;
        margin: 0;

        @include for-size(phone) {
            padding: 15px;
        }
    }

    &__textarea-container {
        margin: 0;
        padding: 0;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;

        &_error {
            color: $light_red;
        }
    }

    &__textarea {
        box-sizing: border-box;
        outline: none;
        margin: 8px 0 24px 0;
        padding: 16px;
        width: 100%;
        height: 180px;
        border: 1px solid $neutral-light-grey-fdf;
        border-radius: 4px;
        resize: none;

        @include for-size(phone) {
            margin: 15px 0;
        }

        &::placeholder {
            color: $neutral-grey-282;
        }
    }

    &__submit-button-container {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    &__sumbit-button {
        box-sizing: border-box;
        width: 194px;
        height: 50px;
        margin: 0;
        font-size: 15px;
        line-height: 22px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        color: $white;
        background-color: $main_green;

        @include for-size(phone) {
            font-size: 13px;
            line-height: 16px;
            height: 45px;
        }

        &:hover {
            opacity: 0.7;
        }

        &[disabled] {
            transition: all 0.2s linear;

            &:hover {
                cursor: not-allowed;
                background: $light_gray;
            }
        }
    }
}
