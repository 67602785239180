@import "../../../../assets/styles/main";
@import "../../../../assets/styles/mixin";

.category {
    max-width: 100%;
    margin: 0 auto;
    background-color: $light_gray_background_icons;
    padding: 58px 115px 66px;

    @include for-size(phone-475) {
        padding: 58px 15px 66px;
    }

    &__container-6 {
        margin: 0 auto;
        max-width: 1170px;

        @media (min-width: 1013px) and (max-width: 1400px) {
            max-width: 750px;
        }
    }

    &__container-8 {
        margin: 0 auto;
        max-width: 1170px;

        @media (min-width: 817px) and (max-width: 1020px) {
            max-width: 550px;
        }
        @media (min-width: 1020px) {
            max-width: 800px;
            .category_item:nth-child(6) {
                margin-right: 16px;
            }
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 15px;
        flex: 1 1 auto;
        justify-content: center;

        @include for-size(phone-630) {
            flex-wrap: nowrap;
            align-items: center;
            flex-direction: column;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        margin: 0 auto;
        border: none;
        border-radius: 4px;
        color: $white;
        background-color: $main-green;
        padding: 12px 28.5px 12px 35px;
        @include text-size(normal, normal, 15px, 24px);
        cursor: pointer;
        transition: all 0.2s linear;

        &:hover {
            opacity: 0.7;
        }

        &_img {
            padding-left: 8px;
        }
    }
}
