@import "../../../../assets/styles/main";

.specialist_orders_sidebar {
    width: 270px;
    box-sizing: border-box;
    margin-right: 20px;
    background: $white;
    padding: 24px 27px;
    border-radius: 10px;

    @include for-size(phone) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        margin: 0 auto 10px;
        width: 95%;
        padding: 15px 10px;
    }

    &__line {
        border: 1px solid #dfdfdf;
        margin-bottom: 14px;

        @include for-size(phone) {
            display: none;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-bottom: 14px;
        color: $gray_43;

        font-family: "SF Pro Display";
        @include text-size(normal, 400, 17px, 34px);

        @include for-size(phone) {
            padding: 2px 0;
            margin-bottom: 0px;
            margin-right: 10px;
            @include text-size(normal, 400, 15px, 15px);
        }

        &__mark {
            width: 12px;
            height: 12px;
            margin-right: 25px;
            border-radius: 50%;
            display: inline-block;
            background: $neutral-grey-282;

            @include for-size(phone) {
                display: none;
            }
        }

        &:hover {
            color: $main_green;

            .specialist_orders_sidebar__item__mark {
                background: $main_green;
            }
        }
        &--price_is_fixed,
        &--negotiation,
        &--in_work {
            @extend .specialist_orders_sidebar__item;

            color: $light_orange;

            .specialist_orders_sidebar__item__mark {
                background: $light_orange;
            }
        }

        &--cancelled {
            @extend .specialist_orders_sidebar__item;

            color: $red;

            .specialist_orders_sidebar__item__mark {
                background: $red;
            }
        }
        &--completed {
            @extend .specialist_orders_sidebar__item;

            color: $main_green;

            .specialist_orders_sidebar__item__mark {
                background: $main_green;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
