@import "../../assets/styles/mixin";

.proposals-list {
    display: flex;
    flex-direction: column;
    margin: 69px auto 165px auto;
    text-align: center;
    max-width: 1170px;
    min-height: calc(100vh - 620px);

    @media (max-width: 1200px) {
        margin: 69px 15px 165px 15px;
    }

    @include for-size(phone) {
        margin: 35px 15px;
    }

    .error-component {
        max-width: 1170px;
        margin: 0;
    }

    &__header {
        display: flex;

        @include for-size(phone) {
            flex-direction: column;
        }

        &-content {
            display: flex;
            flex-direction: column;
            flex-grow: 2;
            margin: 0 100px 0 0;

            @include for-size(phone) {
                margin: 0;
            }

            &-title {
                font-weight: 600;
                font-size: 45px;
                line-height: 30px;
                text-align: center;
                color: #272a32;

                @include for-size(phone) {
                    flex-direction: column;
                    font-size: 30px;
                    line-height: 30px;
                    margin-top: 15px;
                }
            }

            &-subtitle {
                font-size: 18px;
                line-height: 22px;
                color: #828282;
                margin: 22px 0 10px 0;

                @include for-size(phone) {
                    font-size: 13px;
                    line-height: 16px;
                    margin: 10px 0;
                }
            }
        }
    }
}
