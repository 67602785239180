@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/fonts";

.footer {
    width: 100%;
    background-color: $neutral-black-a32;

    &__content {
        max-width: 1170px;
        margin: 0 auto;
        padding: 54px 16px 36px 16px;
    }

    &__main {
        max-width: 1170px;
        margin: 0 auto;
        display: grid;
        grid-template-areas: "logo col-1 col-2 info" "social col-1 col-2 info";
        grid-template-rows: 80px auto;
        column-gap: 20px;
        margin-bottom: 25px;
        justify-content: space-between;
        padding-bottom: 115px;
        border-bottom: 1px solid $dark_blue;

        @include for-size(phone) {
            grid-template-areas: "logo social" "col-1 col-2" " info info";
            grid-template-rows: auto auto auto;
            row-gap: 20px;
        }

        @media screen and (max-width: 500px) {
            display: flex;
            flex-direction: column;
        }

        &-head {
            display: flex;

            @include for-size(phone) {
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    &__logo {
        cursor: pointer;
        width: 145px;
        height: 54px;

        @include for-size(phone) {
            width: 125px;
            height: 43px;
            margin-bottom: 15px;
        }

        @include for-size(phone) {
            display: flex;
            align-self: center;
        }

        svg {
            width: 145px;
            height: 54px;

            @include for-size(phone) {
                width: 125px;
                height: 43px;
            }

            path {
                &:nth-child(1),
                &:nth-child(2) {
                    fill: white;
                }
            }
        }
    }

    &__social {
        grid-area: social;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        list-style: none;
        @media screen and (max-width: 500px) {
            justify-content: center;
        }
    }

    &__link {
        margin-right: 10px;
        line-height: 0;
        transition: all 1s linear;

        a svg path,
        a svg circle {
            transition: all 0.2s ease-in;
        }

        &:hover {
            a svg circle {
                fill: $main-green;
                stroke: white;
            }
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
    &__navs {
        margin: 0;
        list-style: none;
        &:first-of-type {
            grid-area: col-1;
        }
        &:last-of-type {
            grid-area: col-2;
        }
    }

    &__nav {
        text-align: left;

        @include for-size(phone) {
            text-align: center;
        }
        a {
            margin: 0;
            padding: 0;
            color: $white;
            text-decoration: none;
            @include text-size(normal, normal, 17px, 34px);
            transition: all 0.3s linear;

            &:hover,
            &.active {
                color: $main-green;
                @include text-size(normal, normal, 17px, 34px);
            }
        }
    }

    &__info {
        grid-area: info;
        @include for-size(phone) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__support {
        color: $white;
        margin-bottom: 24px;
        @include for-size(phone) {
            text-align: center;
        }
        &_title {
            margin: 0 0 16px 0;
            @include text-size(normal, normal, 17px, 24px);
        }

        &_mail {
            margin: 0;
            color: $white;
            text-decoration: none;
            @include text-size(normal, 600, 17px, 24px);
            transition: all 0.3s linear;

            &:hover {
                color: $main-green;
            }
        }
    }

    &__apps {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    &__app-link {
        text-decoration: none;
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border: none;
        outline: none;
        color: $white;
        padding: 11px 0px;
        border-radius: 6px;
        background: rgba(69, 73, 83, 0.66);
        @include text-size(normal, 500, 15px, 26px);
        transition: all 0.2s ease-in;

        &:hover {
            opacity: 0.7;
        }

        svg {
            margin-right: 12px;
        }
        &:last-of-type {
            margin-left: 10px;
        }

        @media screen and (max-width: 850px) and (min-width: $phone) {
            box-sizing: border-box;
            padding: 11px 30px;
            width: auto;
            flex-direction: column;
            & p {
                display: none;
            }
            svg {
                margin: 0;
            }
        }
    }

    &__bottom {
        max-width: 1170px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;

        @include for-size(phone) {
            flex-direction: column-reverse;
            justify-content: center;
        }
    }

    &__watermark {
        margin: 0;
        color: $light_gray;
        @include text-size(normal, normal, 15px, 22px);
    }

    &__terms,
    &__policy {
        margin: 0;
        color: $light_gray;
        @include text-size(normal, normal, 15px, 22px);
        cursor: pointer;
        transition: all 0.3s linear;
        text-decoration: none;

        &.active,
        &:hover {
            color: $main-green;
        }

        @include for-size(phone) {
            margin-bottom: 15px;
        }
    }

    &__creator {
        margin: 0;
        color: $light_gray;
        @include text-size(normal, normal, 15px, 22px);

        &--light {
            color: $white;
        }
    }
}
