.Toastify__toast-container {
    width: auto;
    max-width: 400px;
    margin: 0 0 15px 15px;

    h4 {
        font-size: 14px;
        line-height: 21px;
        color: #272a32;
    }
}
