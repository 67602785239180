@import "../../assets/styles/main";
@import "../../assets/styles/_mixin";
.notifications {
    max-width: 1170px;
    width: 100%;
    margin: 70px auto;
    box-sizing: border-box;
    padding: 0 15px;
    min-height: calc(100vh - 526px);
    @include for-size(phone) {
        margin: 30px auto;
    }

    &__title {
        color: $black;
        margin: 0 0 66px 0;
        @include text-size(normal, 800, 36px, 28px);
        @include for-size(phone) {
            margin: 0 0 30px 0;
            text-align: center;
            @include text-size(normal, 800, 32px, 28px);
        }
    }

    &__body {
        width: 100%;
        padding: 24px 0 0 0;
        border-radius: 10px;
        background-color: $white;
        margin-bottom: 30px;

        @include for-size(phone) {
            margin-bottom: 15px;
        }
    }

    &__header {
        border-bottom: 1px solid $neutral-light-grey-fdf;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 16px 61px;

        &_img {
            cursor: pointer;

            svg {
                width: 32px;
                height: 32px;
                transition: 0.2s linear;
                &:hover {
                    transform: rotate(90deg);
                }
            }
        }
        &_title {
            color: $neutral-black-a32;
            @include text-size(normal, 600, 18px, 30px);
            @include for-size(phone) {
                text-align: center;
                // margin: 0 0 16px 0;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__empty-result {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
        height: 452px;
        border-radius: 10px;
        width: 100%;

        &--img {
            width: 87px;
            height: 120px;
            padding: 0 0 16px 0;
        }

        &--title {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #272a32;
        }
    }
}
