@import "../../../assets/styles/main";
@import "../../../assets/styles/mixin";

.all_services__item {
    width: 25%;
    margin-right: 50px;
    margin-bottom: 188px;

    @include for-size(phone) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }

    &:nth-child(3n + 3) {
        margin-right: 0px;
    }

    &:hover {
        ::-webkit-scrollbar {
            width: 3px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: $main_green;
        }
        ::-webkit-scrollbar-track {
            margin-top: 10px;
            margin-bottom: 20px;
            border-radius: 10px;
            background-color: $gray_c4;
        }
    }

    ::-webkit-scrollbar {
        opacity: 0;
    }

    &_header {
        display: flex;
        align-items: center;
        cursor: pointer;

        &_title {
            margin: 0;
            color: $neutral-black-a32;
            @include text-size(normal, 600, 18px, 22px);
            transition: all 0.2s linear;
        }

        &_amount {
            color: $neutral-grey-282;
            margin: 3px 0 0 9px;
            @include text-size(normal, normal, 14px, 22px);
            transition: all 0.2s linear;
        }

        &:hover > &_title,
        &:hover > &_amount {
            color: $main_green;
        }
    }
    &_list {
        width: 200px;
        height: auto;
        max-height: 200px;
        overflow-y: auto;
        list-style: none;
        margin-top: 4px;
        padding-inline-start: 42px;

        &_description {
            margin-bottom: 6px;
            color: $neutral-black-a32;
            cursor: pointer;
            @include text-size(normal, normal, 16px, 32px);
            transition: all 0.2s linear;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover {
                color: $main_green;
            }

            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }

    &-image {
        width: 34px;
        height: 33px;
        margin-right: 10px;
    }
}
