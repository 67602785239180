@import "../../../../../assets/styles/main";

.restore-account-success {
    width: 500px;
    padding: 45px;
    background-color: $white;
    border-radius: 10px;
	box-sizing: border-box;

    @include for-size(phone-630) {
        width: 90%;
		margin: 0 5%;
    }

    &__title {
        text-align: center;
        font-size: 18px;
        line-height: 25px;
        color: $light_gray;
    }

    &__btn {
        width: 200px;
        margin: 0 auto;
        margin-top: 40px;
    }
}
