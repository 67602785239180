@import "../../../../assets/styles/mixin";

.specialists-item {
    display: flex;
    align-items: center;
    margin: 0 0 31px 0;
    background: #ffffff;
    border-radius: 10px;
    height: 152px;
    padding: 36px 45px 37px 46px;
    transition: all 0.2s ease-in-out;

    @include for-size(tablet) {
        flex-direction: column;
        height: auto;
        width: auto;
        width: 375px;
        align-self: center;
        padding: 25px 15px;
        margin: 0 0 25px 0;
    }

    @include for-size(phone) {
        flex-direction: column;
        height: auto;
        width: auto;
        width: 300px;
        align-self: center;
        padding: 25px 15px;
        margin: 0 0 25px 0;
    }

    &:hover {
        box-shadow: 0px 0px 10px 0px #33b378;
    }

    &__left {
        display: flex;
        align-items: center;
        width: 57%;
        border-right: 1px solid #dfdfdf;

        @include for-size(tablet-phone) {
            border: none;
            width: 100%;
            flex-direction: column;
        }

        &-content {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            @include for-size(tablet-phone) {
                align-items: center;
            }
        }

        &-name {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #272a32;
            display: flex;
            justify-content: flex-start;
            margin: 0 0 6px 0;
        }

        &-service {
            display: flex;
            justify-content: flex-start;
            font-size: 15px;
            line-height: 18px;
            color: #828282;
            margin: 0 0 20px 0;
            word-break: break-word;
        }

        &-avatar {
            width: 151px;
            height: 151px;
            object-fit: cover;
            border-radius: 50%;
            margin: 0 25px 0 0;

            @include for-size(tablet-phone) {
                margin: 0;
            }
        }

        &-star {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0 0 20px 0;
            font-size: 15px;
            line-height: 18px;
            color: #272a32;

            &-count {
                margin: 0 4px 0 6px;
            }
        }

        &-buttons {
            @include for-size(phone-630) {
                display: flex;
                flex-direction: column;
            }

            &-primary,
            &-secondary {
                transition: all 0.2s linear;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                text-align: center;
                outline: none;
                width: 126px;
                height: 48px;
                cursor: pointer;

                &:hover {
                    opacity: 0.7;
                }
            }

            &-primary {
                color: #ffffff;
                background: #33b378;
                border-radius: 4px;
                border: none;
                margin: 0 15px 0 0;

                @include for-size(phone-630) {
                    margin: 0;
                }
            }

            &-secondary {
                color: #272a32;
                background: #ffffff;
                border: 1px solid #33b378;
                border-radius: 4px;
                margin: 0 0 0 15px;

                @include for-size(phone-630) {
                    margin: 20px 0 0 0;
                }
            }
        }
    }

    &__right {
        width: 43%;
        margin: 0 0 0 51px;

        @include for-size(tablet-phone) {
            margin: 20px 0 0 0;
            width: 100%;
        }

        &-item {
            display: flex;
            margin: 0 0 9px 0;

            &-title {
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                color: #272a32;
                text-align: left;
                width: 45%;
            }

            &-subtitle {
                font-size: 15px;
                line-height: 18px;
                color: #828282;
                text-align: left;
                width: 70%;
                display: flex;
                align-items: center;

                svg {
                    width: 14px;
                    height: 14px;
                    path {
                        fill: #828282;
                    }
                }
            }
        }

        &-clarification {
            display: flex;
            justify-content: flex-start;
            text-align: left;
            margin: 16px 0 0 0;
            font-size: 15px;
            line-height: 22px;
            color: #828282;
            word-break: break-word;
        }
    }
}
