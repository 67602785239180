@import "../../../assets/styles/main";

.oauth-login {
    margin-top: 36px;

    button {
        width: 100%;
        height: 50px;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 4px;
        background-color: $white;
        cursor: pointer;

        span {
            margin-left: 14px;
            font-family: "SF Pro Display Medium";
            font-size: 15px;
            color: $neutral-black-a32;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
