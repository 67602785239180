@import "../../assets/styles/variables";
@import "../../assets/styles/mixin";

.country-codes {
    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__label {
        margin-bottom: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $neutral-black-a32;
    }

    &__error {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $light_red;
    }

    &__form {
        display: flex;
    }

    &__dropdown {
        width: 126px;
        height: 50px;
        margin-right: 5px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        border: 1px solid $neutral-light-grey-fdf;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;

        &-flag {
            width: 24px;
            height: 16px;
        }

        &-default-flag {
            width: 24px;
            height: 16px;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__dropdown--full-width {
        box-sizing: content-box;
    }

    &__dropdown--readonly {
        cursor: default;
        background-color: #efefef4d;
        color: $neutral-grey-343;
    }

    &__dropdown--error {
        border: 1px solid $light_red;
    }

    &__phone-mask-input {
        width: 50px;
        padding-left: 10px;
        border: none;
        outline: 0;
        cursor: pointer;

        &::placeholder {
            font-size: 15px;
            font-family: "SF Pro Display Medium";
            color: $light_gray;
        }

        &:read-only {
            background-color: #fafafa;
            cursor: default;
        }
    }

    &__phone-value-input {
        width: 212px;
        padding: 10px 15px;
        border: none;
        border: 1px solid $neutral-light-grey-fdf;
        border-radius: 4px;
        outline: 0;
        box-sizing: border-box;

        &:focus {
            border: 1px solid $main_green;
        }

        &::placeholder {
            font-size: 15px;
            font-family: "SF Pro Display Medium";
            color: $light_gray;
        }

        @include for-size(phone-475) {
            width: 100%;
        }
    }

    &__phone-value-input--full-width {
        width: 100%;
    }

    &__phone-value-input--error {
        border-color: $light_red;

        &:focus {
            border-color: $light_red;
        }
    }
}
