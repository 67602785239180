@import "../../../assets/styles/mixin";
@import "../../../assets/styles/main";

.location_container {
    max-width: 440px;
    background: $white;
    border-radius: 10px;
    margin: 117px auto 117px;

    @media (max-width: 1600px) {
        margin: 75px auto 75px;
    }

    @include for-size(phone) {
        margin: 35px auto 35px;
    }

    .btn.btn--green.btn--large {
        margin-top: 8px;
        margin-bottom: 0px;
    }

    &__form {
        width: 100%;

        .select__dropdown-open {
            border-bottom: none;
            border-radius: 4px;
        }

        .select__dropdown_body.open {
            position: relative;
        }

        &-loading {
            margin: 8px 0;
        }
    }

    @include for-size(phone-475) {
        background: none;

        .btn.btn--green.btn--large {
            width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 54px 42px 43px;
        text-align: center;

        @include for-size(phone-475) {
            padding: 54px 20px 43px;
        }
    }

    &__title {
        text-align: center;
        margin: 20px 0 32px;
        font-size: 25px;
        line-height: 30px;
        font-weight: 400;
    }

    &__description_link {
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
        color: $main_green;
    }

    &__select {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: 1px solid $neutral-light-grey-fdf;
        padding: 10px 15px;
        margin: 18px 0;
        font-size: 16px;

        &:focus {
            box-sizing: border-box;
            border: 1px solid $main_green !important;
        }
    }
}
