@import "../../../assets/styles/main";

.custom-date-picker,
.custom-date-picker__errors {
    position: relative;
    padding: 16px;
    font-style: normal;
    font-weight: normal;
    background: $white;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    border: 1px solid $neutral-light-grey-fdf;
    border-radius: 4px;
    font-size: 16px;
    line-height: 19px;
    color: $neutral-black-a32;
    background: $white
        url("../../../assets/images/registration-icons/date_of_bithday.svg")
        right 15px center no-repeat;
    height: 51px;

    &::placeholder {
        font-size: 16px;
        line-height: 19px;
        color: #3c3c434d;
    }

    &:focus {
        outline: none;
    }
}
.custom-date-picker__errors {
    outline: none;
    border: 1px solid $light_red;
    &:focus {
        outline: none;
        border: 1px solid $light_red;
    }
}

.react-datepicker {
    border: 1px solid $neutral-light-grey-fdf;

    &-popper {
        z-index: 8;
    }

    &__day {
        color: $neutral-black-a32;

        &:hover {
            background: $main_green;
            color: $white;
        }

        &-name {
            color: $neutral-black-a32;
        }

        &--selected,
        &--keyboard-selected {
            background: $main_green;
            color: $white !important;

            &:hover {
                background: $main_green;
            }
        }
    }

    &__header {
        background: $main_background;
        border-bottom: none;
        border-top-right-radius: 0px;

        &:not(.react-datepicker__header--has-time-select) {
            border-top-right-radius: 0.2em;
            border-top-left-radius: 0.2em;
        }
    }

    &__triangle {
        display: none;
    }

    &__current-month {
        color: $neutral-black-a32;
        display: none;
    }

    &__navigation:hover *::before {
        border-color: $white;
    }

    &__navigation-icon::before,
    &__year-read-view--down-arrow,
    &__month-read-view--down-arrow,
    &__month-year-read-view--down-arrow {
        border-color: $main_green;
    }

    &__day--disabled,
    &__month-text--disabled,
    &__quarter-text--disabled,
    &__year-text--disabled {
        color: $neutral-light-grey-fdf;

        &:hover {
            background: none;
            color: $neutral-light-grey-fdf;
        }
    }

    &__month-dropdown,
    &__year-dropdown {
        background-color: $white;
        box-shadow: 0px 0px 5px rgba(39, 42, 50, 0.5);
        :hover {
            background-color: $white_background_def;
        }
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #fff;
            border: 1px solid #33b378;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #33b378;
            border-radius: 4px;
        }
    }

    &__year-option {
        & > a:last-child,
        & > a:first-child {
            display: none;
        }
    }

    &__month-dropdown-container {
        padding-top: 2px;
        &:first-child {
            padding-right: 25px;
            margin-left: -15px;
        }
    }

    &__month-read-view,
    &__year-read-view {
        visibility: visible !important;
        &:hover {
            & span:first-of-type {
                border-color: #b6babb;
            }
        }
        &--down-arrow {
            top: 2px;
            height: 5px;
            width: 5px;
            border-width: 2px 2px 0 0;
            &:hover {
                border-color: #b6babb;
            }
        }
    }
}

.custom-date-picker {
    &:focus {
        outline: none;
        border: 1px solid #33b378;
        box-sizing: border-box;
        border-radius: 4px;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__label {
        margin-bottom: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #272a32;
    }
    &__error {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #ff3b30;
    }
}
