@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/variables";

.service-form {
    box-sizing: border-box;
    width: 1170px;
    padding: 24px 30px;
    border-radius: 10px;
    background: white;


    @include for-size(tablet-laptop) {
        width: 80vw;
    }
    @include for-size(phone) {
        width: 90vw;
    }

    &-container {
        display: flex;

        @include for-size(tablet-laptop) {
            flex-direction: column;
        }

        @include for-size(phone) {
            flex-direction: column;
        }
    }

    &-error {
        text-align: center;
        padding-bottom: 15px;
        &__text {
            color: #ff3b30;
            font-size: 12px;
            font-weight: 600;
        }
    }

    &__left-column {
        width: 50%;
        margin-right: 31px;

        @include for-size(tablet-laptop) {
            width: 100%;
            margin-right: 0;
        }

        @include for-size(phone) {
            width: 100%;
            margin-right: 0;
        }
    }

    &__right-column {
        width: 50%;

        @include for-size(tablet-laptop) {
            width: 100%;
        }

        @include for-size(phone) {
            width: 100%;
        }
    }

    &__section-title {
        font-family: "SF Pro Display";
        @include text-size(normal, 500, 22px, 32px);
        margin: 24px 0;

        &:first-child {
            margin-top: 0;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;

        button:first-child {
            margin-right: 30px;
        }

        .btn--light {
            color: black;
        }
    }

    .order-item__photos {
        margin-top: -12px;
        padding-bottom: 0;
    }

    .select__dropdown_body.open {
        width: 100%;
        box-sizing: unset;
        max-height: 30vh;
        overflow-y: auto;
    }

    .form-container__input {
        margin: 0;
    }

    .custom-textarea {
        height: 190px;
    }
}
