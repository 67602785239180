@import "../../../../assets/styles/main";
@import "../../../../assets/styles/mixin";

.banner {
    text-align: center;
    overflow-x: hidden;
    background-size: cover;
    padding: 92px 285px 114px;

    @include for-size(tablet-phone) {
        padding: 92px 85px 114px;
    }

    @include for-size(phone-630) {
        padding: 92px 35px 114px;
    }

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
    }

    &__title {
        margin: 0 0 52px 0;
        color: $white;
        @include text-size(normal, 600, 50px, 60px);
        font-family: "SF Pro Display";

        &--green {
            color: $main_green;
        }

        @include for-size(phone) {
            font-size: 30px;
            line-height: 30px;
            margin: 0 0 25px 0;
        }
    }

    &__description {
        text-align: center;

        &_title {
            margin-bottom: 6px;
            color: $white;
            @include text-size(normal, 500, 22px, 30px);

            &--green {
                color: $main_green;
            }
        }

        &_subtitle {
            margin: 0;
            color: $white;
            @include text-size(normal, normal, 18px, 30px);
        }
    }

    &__search {
        display: flex;

        align-items: center;
        justify-content: space-between;
        margin-bottom: 62px;

        width: 950px;

        &_autocomplete {
            width: 60%;
            margin-right: 16px;

            @media screen and (max-width: 1000px) {
                width: 100%;
                margin-right: 0px;
            }
        }
        &__location {
            display: flex;
            align-items: center;
            width: 40%;

            @include for-size(phone) {
                display: flex;
                align-items: center;
                width: 100%;
            }

            @media screen and (max-width: 450px) {
                display: block;
                align-items: center;
                width: 100%;
            }

            @media (min-width: 767px) and (max-width: 1000px) {
                display: flex;
                align-items: center;
                width: 60%;
            }

            .select__dropdown {
                margin-top: 0px;
                &-open {
                    margin-top: 0px;
                }
            }

            &_country {
                width: 100%;
                margin-right: 8px;
            }
            &_district {
                width: 100%;
            }
        }
        @media screen and (max-width: 1000px) {
            width: calc(100% + 2px);
            display: block;
        }
    }
}
