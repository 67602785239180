@import "../../../../../../assets/styles/mixin";

.select-order-auth {
    .auth_required_distributor {
        margin: 0;
        background-color: #fff;

        @include for-size(phone) {
            margin: 0 15px;
        }
    }
}
