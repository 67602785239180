@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.support-create-ticket {
    background-color: $white;
    align-self: flex-start;
    border-radius: 10px;
    padding: 15px 10px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in;
    justify-content: center;
    border: 1px solid $white;

    @include for-size(phone) {
        margin: 0 0 15px 0;
        width: calc(100% - 20px);
        height: 30px;
    }

    &:hover {
        border: 1px solid $main_green;
        box-shadow: 0px 0px 5px rgba(39, 42, 50, 0.1);
    }

    &:hover > &__title {
        color: $main_green;
    }

    &:hover > &__icon svg path {
        stroke: $dark_blue;
    }

    &__icon {
        padding: 10px;
        border-radius: 10px;
        transition: all 0.2s ease-in;
        margin-right: 7.5px;

        svg path {
            stroke: $main_green;
            transition: all 0.2s ease-in;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 34px;
        color: $gray_43;
        transition: all 0.2s ease-in;
        font-weight: 700;
        margin-left: 7.5px;

        @include for-size(phone) {
            font-size: 15px;
            line-height: 30px;
        }
    }
}
