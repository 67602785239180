@import "../../assets/styles/main";

.blog-info {
    text-align: center;
    max-width: 1170px;
    margin: 69px auto 69px auto;

    .error-component {
        max-width: 1170px;
        margin: 0;
    }

    @include for-size(tablet-laptop) {
        margin: 35px auto;
        padding: 0 15px;
    }

    @include for-size(phone) {
        margin: 35px auto;
        padding: 0 15px;
    }

    &__header {
        margin-bottom: 25px;
    }

    &__body {
        max-width: 770px;
        margin: 0 auto;

        &-category {
            text-overflow: ellipsis;
            word-break: break-word;
            -webkit-line-clamp: 1;
            font-size: 12px;
            font-family: "SF Pro Display Bold";
            line-height: 16px;
            color: $green_90;
            text-transform: uppercase;
        }

        &-title {
            margin: 15px auto;
            width: calc(100% - 300px);

            span {
                font-family: "SF Pro Display Bold";
                font-size: 36px;
                line-height: 42px;
                text-align: center !important;
                color: #272a32;
                padding: 0 !important;

                @include for-size(phone) {
                    font-size: 32px;
                    line-height: 38px;
                    margin-top: 15px;
                    width: 100%;
                }
            }
        }

        &-date {
            margin-bottom: 36px;
            font-size: 15px;
            color: $gray_6a;

            &-divider {
                margin: 0 12px;
            }
        }

        &-image {
            width: 100%;
            max-height: 500px;
            object-fit: cover;
            animation: fadeInActiveBlogItemContent 0.4s ease-in;
        }

        &-description {
            font-size: 16px;
            line-height: 19px;
            text-align: left;
            display: block;
            margin: 36px 0;
            animation: fadeInActiveBlogItemContent 0.8s ease-in;
            padding: 0 !important;
        }

        &-social {
            text-align: left;

            &-item {
                cursor: pointer;
                margin-right: 16px;

                &:hover > svg path {
                    fill: $main_green;
                }

                svg {
                    width: 34px;
                    height: 34px;

                    path {
                        fill: $white;
                    }
                }

                svg circle {
                    fill: $dark_gray;
                    stroke: $dark_gray;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@keyframes fadeInActiveBlogItemContent {
    0% {
        opacity: 0.01;
    }
    100% {
        opacity: 1;
    }
}
