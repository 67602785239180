@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/_mixin";
@import "../../../../assets/styles/main";

body {
    &.no-scroll {
        overflow-y: hidden;
    }
}
.header_container {
    background-color: $white;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    height: 59px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 998;
    border-bottom: 1px solid #f1f1f1;

    @include for-size(phone) {
        display: none;
    }

    &__header_block {
        width: 1170px;
        margin: 16px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 1200px) {
            padding: 0 16px;
        }
    }

    &__local_info {
        display: flex;
        align-items: center;

        a {
            &:focus {
                outline: none;
            }

            svg {
                height: 50px;
                width: 145px;
                padding-top: 4px;
            }
        }
    }

    &__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 25px;
        line-height: 1;
        cursor: pointer;
        text-decoration: none;
        color: $black;
        @include for-size(phone) {
            display: none;
        }

        &::before {
            content: "";
            width: 4px;
            height: 4px;
            border-bottom: 1px solid $light_gray;
            border-left: 1px solid $light_gray;
            position: relative;
            right: 10px;
            top: -1px;
            transform: rotate(-45deg);
        }
    }
}
.mobile-header {
    display: none;

    @include for-size(phone) {
        display: flex;
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        background-color: $white;
        top: 0;
        z-index: 998;
        border-bottom: 1px solid #f1f1f1;

        a {
            height: 100%;
            max-height: 100%;
            display: flex;
            align-items: center;

            &:focus {
                outline: none;
            }

            svg {
                height: 43px;
                width: 125px;
                z-index: 60;
            }
        }
    }
}
.burger {
    display: flex;
    align-items: center;
    z-index: 60;

    &__button {
        width: 32px;
        height: 32px;
        padding: 0;
        margin: 0 0 0 16px;
        background: transparent;
        border: none;
        position: relative;
        cursor: pointer;

        & span {
            display: flex;
            width: 100%;
            height: 2px;
            margin: 0;
            padding: 0;
            background: $main_green;
            transform-origin: right;
            transition: 0.3s linear;
        }

        &::before,
        &::after {
            content: "";
            width: 100%;
            height: 2px;
            background: $main_green;
            position: absolute;
            left: 0;
            transition: 0.3s linear;
        }

        &::before {
            top: 6px;
        }

        &::after {
            top: 24px;
        }

        &_opened {
            span {
                transform: scaleX(0);
                opacity: 0;
            }

            &::before {
                transform: rotate(-45deg);
                top: 16px;
            }

            &::after {
                transform: rotate(45deg);
                top: 16px;
            }
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        position: fixed;
        z-index: 50;
        left: 100vw;
        width: 100vw;
        min-height: 100vh;
        height: 100vh;
        background: linear-gradient(to bottom, $white 60px, $main_green 0%);
        overflow-y: auto;
        transition: 0.3s linear;
        padding: 40px 0;
        top: -70px;
        padding-bottom: 70px;
        transform: translateY(70px);

        &_opened {
            left: 0;
        }
    }
}
