@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.discussion_work_project_suggestions {
    margin-left: 30px;
    border-radius: 10px;
    padding: 22px 0 62px 0;
    background-color: $white;
    width: calc(100% - 400px);
    align-self: flex-start;

    @include for-size(tablet) {
        width: 100%;
        margin: 0;
        padding: 15px 0;
    }

    @include for-size(phone) {
        width: 100%;
        padding: 15px 0;
        margin: 0;
    }

    &_title {
        padding: 0 0 16px 45px;
        margin-bottom: 18px;
        color: $neutral-black-a32;
        @include text-size(normal, 600, 18px, 30px);
        border-bottom: 1px solid $neutral-light-grey-fdf;

        @include for-size(tablet-phone) {
            padding: 0 0 15px 15px;
            margin-bottom: 15px;
            @include text-size(normal, 600, 18px, 24px);
        }
    }
}
