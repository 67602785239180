@import "../../../../../../../assets/styles/mixin";

.chat-carousel {
    margin: 50px 50px 0 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__count {
        position: absolute;
        top: -50px;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        color: #fff;
        z-index: 100000;

        @include for-size(phone) {
            font-size: 16px;
            line-height: 19px;
        }
    }

    &__inner {
        height: 70vh;
        width: 1200px;
        &-item {
            position: relative;
            display: none;
            -webkit-transition: 0.6s ease-in-out left;
            -o-transition: 0.6s ease-in-out left;
            transition: transform 0.6s ease-in-out;
            transition: 0.6s ease-in-out left;
            animation: animationChatCarousel 0.6s normal;

            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
                max-height: 500px;

                @media (max-width: 1300px) {
                    width: calc(100vw - 80px);
                }

                @media (max-width: 767px) {
                    width: calc(100vw - 50px);
                }
            }

            &.active {
                display: block;
            }
        }
    }

    &__actions {
        clear: both;
        position: absolute;
        left: -50px;
        right: 0;
        width: calc(100% + 100px);

        &-control {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            padding: 10px;
            background-color: transparent !important;
            cursor: pointer;

            &-left {
                float: left;
                background-color: transparent;
                border: none;
                cursor: pointer;

                svg {
                    @include for-size(phone) {
                        width: 22px;
                        height: 22px;
                    }
                }

                svg path {
                    transition: 0.2s ease-in-out;
                }

                &:hover > svg path {
                    stroke: #33b378;
                }
            }

            &-right {
                float: right;
                background-color: transparent;
                border: none;
                cursor: pointer;

                svg {
                    @include for-size(phone) {
                        width: 22px;
                        height: 22px;
                    }
                }

                svg path {
                    transition: 0.2s ease-in-out;
                }

                &:hover > svg path {
                    stroke: #33b378;
                }
            }
        }
    }
}

@keyframes animationChatCarousel {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
