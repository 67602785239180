@import "../../assets/styles/main";

.all_result {
    max-width: 1170px;
    margin: 0 auto;

    &__header {
        display: flex;

        @media screen and (max-width: 900px) {
            flex-direction: column-reverse;
        }
    }

    &__inner {
        display: flex;
        align-items: flex-start;
        width: 100%;

        &-sort {
            @media screen and (max-width: 900px) {
                display: none;
            }
        }
    }

    &__body {
        word-break: break-all;
        overflow-wrap: normal;
        width: 100%;
        transition: 0.3s linear;
        box-sizing: border-box;
        @media screen and (max-width: 900px) {
            position: relative;
            min-width: auto;
            left: 0;
            margin-left: 40px;

            &_with-visible-sidebar-filters {
                left: 0px;
            }
        }
    }

    &__sort {
        display: none;

        @media screen and (max-width: 900px) {
            display: block;
        }
    }

    &__searched,
    &__searched-default {
        width: 100%;
        border-radius: 10px;
        background-color: $white;

        transition: 0.2s linear;

        &_inner {
            padding: 18px 0px 26px 0px;
        }

        &_people {
            display: flex;
            flex-direction: column;
            padding: 24px 0px 0px 0px;

            &_item {
                display: flex;
            }
        }
    }

    &__searched-default {
        margin-top: 24px;

        &-content {
            padding: 0 20px 24px 20px;
        }

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;
            padding: 18px 24px 48px 24px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 24px;
                width: calc(100% - 48px);
                left: 24px;
                height: 1px;
                background: $neutral-light-grey-fdf;
            }

            &_title {
                color: $neutral-black-a32;
                @include text-size(normal, 600, 18px, 30px);
                word-wrap: normal;
                word-break: keep-all;
                line-break: normal;
            }

            &_count {
                margin-left: 10px;
                text-decoration: underline;
                white-space: nowrap;
            }
        }
    }

    &__pagination-container {
        margin-top: 24px;
    }
    &__filters-container {
        z-index: 2;
        transition: 0.3s linear;
        margin-right: 30px;
        @media screen and (max-width: 900px) {
            left: -282px;
            position: absolute;

            &_visible {
                left: 0px;
                z-index: 5;
                position: absolute;

                @media screen and (max-width: 350px) {
                    left: 0px;
                }
            }
        }
    }
}
.filters-sidebar {
    &__filters-container {
        @media screen and (max-width: 900px) {
            position: relative;
            height: 100%;
            box-sizing: border-box;
            background: $white;
            border-top: 20px solid $white;
            border-bottom: 20px solid $white;

            border-radius: 10px;
            border-top-right-radius: 0;

            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
                rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        }
        @media screen and (max-width: 350px) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &__visibility-button-container {
        position: absolute;
        transform: scale(0);
        opacity: 0;
        transition: opacity 0.1ms;
        @media screen and (max-width: 900px) {
            position: absolute;
            z-index: 50;
            top: 0;
            right: 0;
            transform-origin: right top;
            opacity: 1;
            transform: rotate(-90deg) scale(1);
            display: inline-block;
        }
    }
    &__visibility-button {
        border: none;
        background: $main-green;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px 30px;
        border-radius: 0 0 8px 8px;
        color: $white;
        & svg {
            padding-right: 10px;
        }
    }
}
