@import "../../../../../../../assets/styles/mixin";
@import "../../../../../../../assets/styles/main";

.complain-modal {
    padding: 52px 24px 24px 24px;
    box-sizing: border-box;
    text-align: center;
    background: $white;
    border-radius: 10px;

    @include for-size(tablet-phone) {
        padding: 35px 15px 15px 15px;
        margin: 15px;
    }
}
.complain-modal__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 454px;

    &--error {
        width: 100%;
        margin-bottom: 3px;
        box-sizing: border-box;
        text-align: start;
        color: $light-red;
        font-size: 15px;
    }

    @include for-size(phone) {
        width: auto;
    }

    &-title {
        width: 100%;
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: #272a32;
        padding: 0;
        margin: 0 0 24px 0;

        @include for-size(phone) {
            font-size: 20px;
            line-height: 24px;
            margin: 0 0 15px 0;
        }
    }

    &-btn {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        background: #33b378;
        border-radius: 4px;
        border: none;
        outline: none;
        transition: all 0.3s linear;
        cursor: pointer;
        height: 50px;
        width: 194px;

        @include for-size(phone) {
            font-size: 13px;
            line-height: 16px;
        }

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            outline: none;
        }
    }

    &-quiz {
        width: 100%;

        height: 224px;
        overflow-y: auto;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;

        &--error {
            @extend .complain-modal__inner-quiz;
            border: 1px solid $light-red;
        }

        @include for-size(phone) {
            margin-bottom: 15px;
        }

        &::-webkit-scrollbar {
            width: 3px;
            border-radius: 10px;

            &-thumb {
                background: #33b378;
                border-radius: 10px;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            padding: 18px 18px 18px 0;

            @include for-size(phone) {
                padding: 15px 15px 15px 0;
            }

            &-title {
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #272a32;

                @include for-size(phone) {
                    font-size: 13px;
                    line-height: 16px;
                }
            }

            &-circle,
            &-circle-active {
                margin: 0 18px;
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
                border-radius: 32px;
                border: 2px solid #dfdfdf;
                cursor: pointer;
                transition: all 0.3s ease-in;
                position: relative;

                @include for-size(phone) {
                    margin: 0 10px;
                }

                &-active {
                    border: 2px solid #33b378;
                    position: relative;

                    &-inside {
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        min-width: 16px;
                        min-height: 16px;
                        background-color: #33b378;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 32px;
                        -webkit-animation: fadeinCircle 0.3s;
                        -moz-animation: fadeinCircle 0.3s;
                        -ms-animation: fadeinCircle 0.3s;
                        -o-animation: fadeinCircle 0.3s;
                        animation: fadeinCircle 0.3s;
                    }
                }
            }
        }
    }
}

@keyframes fadeinCircle {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeinCircle {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeinCircle {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-ms-keyframes fadeinCircle {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
