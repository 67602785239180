@import "../../assets/styles/mixin";
@import "../../assets/styles/main";

.blog {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 1170px;
    margin: 69px auto 69px auto;
    padding: 0 15px;

    .error-component {
        max-width: 1170px;
        margin: 0;
    }

    @include for-size(tablet-phone) {
        margin: 35px auto;
    }

    &__header {
        display: flex;
        align-items: center;
        margin: 0 0 35px 0;

        @include for-size(phone) {
            margin: 0 0 15px 0;
            flex-direction: column;
        }

        .back-button {
            @include for-size(phone) {
                align-self: flex-start;
            }
        }

        &-title {
            font-weight: 600;
            font-size: 45px;
            line-height: 30px;
            text-align: center;
            color: #272a32;
            padding: 0;
            margin: 0 auto;

            @include for-size(phone) {
                font-size: 32px;
                line-height: 38px;
                margin-top: 15px;
            }
        }
    }

    &__container {
        width: 100%;

        &-search-header {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            &-filter {
                width: 300px;
                height: 62px;
                margin-right: 30px;
            }

            &-textfield {
                width: 100%;
                display: flex;
                border-radius: 10px;
                align-items: center;
                background-color: $white;
                justify-content: space-between;
                padding: 7px 12px;
                box-sizing: border-box;

                &-input {
                    width: 80%;
                    padding: 0;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    background-image: none;
                    background-color: transparent;
                    @include text-size(normal, normal, 16px, 22px);
                }

                &-btn {
                    height: 48px;
                    width: 125px;
                    padding: 0;
                    border: none;
                    color: $white;
                    border-radius: 4px;
                    text-align: center;
                    background-color: $main_green;
                    @include text-size(normal, 500, 16px, 22px);

                    &:hover {
                        cursor: pointer;
                        opacity: 0.7;
                    }
                }
            }

            @include for-size(tablet) {
                &-filter {
                    margin-right: 12px;
                }
            }

            @include for-size(phone) {
                flex-direction: column;

                &-filter {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 16px;
                }
            }
        }

        &-content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 30px;
            column-gap: 24px;
            margin-bottom: 24px;

            @include for-size(phone) {
                grid-template-columns: 1fr;
            }
        }
    }
}
