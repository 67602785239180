@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.balance-onboarding-iyzico {
    background-color: #fff;
    padding: 35px;
    border-radius: 10px;
    height: auto;
    width: 350px;
    border-radius: 10px;

    @include for-size(phone) {
        padding: 15px;
        margin: 0 15px;
        width: 315px;
    }

    @media (max-width: 374px) {
        width: 260px;
    }

    &__form {
        &-btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 7px;

            &-cancel,
            &-save {
                height: 43px;
                font-size: 16px;
                line-height: 19px;
                box-sizing: border-box;
                border-radius: 6px;
                outline: none;
                cursor: pointer;
                transition: all 0.2s linear;
                color: $white;
                background: $main_green;
                border: none;
                width: 48%;

                &[disabled] {
                    cursor: not-allowed;
                    transition: all 0.2s linear;

                    &:hover {
                        background: $neutral-light-grey-fdf;
                        color: $white;
                    }
                }

                &:hover {
                    opacity: 0.7;
                }
            }

            &-cancel {
                color: $black;
                background: $white;
                border: 1px solid $neutral-light-grey-fdf;
            }
        }
    }
}
