@import "../../assets/styles/mixin";
@import "../../assets/styles/variables";

.specialist-services {
    width: 1170px;
    margin: 57px auto;
    min-height: calc(100vh - 500px);

    @include for-size(laptop) {
        width: 90%;
        max-width: 1170px;
    }

    @include for-size(tablet-phone) {
        width: 90%;
    }

    &__header {
        display: flex;
        align-items: center;

        .back-button {
            position: absolute;
        }

        @include for-size(tablet-phone) {
            flex-direction: column;

            .back-button {
                position: relative;
            }
        }

        &__title {
            color: $neutral-black-a32;
            margin: 0 auto;
            font-family: "SF Pro Display";
            @include text-size(normal, 600, 45px, unset);

            @include for-size(phone) {
               font-size: 20px;
               margin-top: 20px;
            }
        }

        &__subtitle {
            text-align: center;
            color: $neutral-grey-282;
            margin-bottom: 42px;
        }
    }

    &__add-new {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            width: 100%;
            @include text-size(normal, 400, 22px, 32px);
        }

        &-symbol {
            margin-left: 27px;
        }
    }

    .service-item__photos {
        padding-top: 0;
    }
}
