@import "../../../../assets/styles/main";
.promotion-decline-form {
    background: #ffffff;
    border-radius: 10px;
    padding: 50px 115px;

    @include for-size(phone) {
        padding: 20px 30px;
        text-align: center;
    }

    &__title {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.38px;
        color: #272a32;

        @include for-size(phone) {
            font-size: 15px;
        }
    }

    &__btns {
        margin: 40px auto 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &-cancel,
        &-delete {
            width: 119px;
            height: 43px;
            font-size: 16px;
            line-height: 19px;
            box-sizing: border-box;
            border-radius: 4px;
            outline: none;
            cursor: pointer;
            transition: all 0.2s linear;
            border: none;

            &:hover {
                opacity: 0.7;
            }
        }

        &-cancel {
            margin-right: 12px;
            background: #33b378;
            color: #fff;
        }

        &-delete {
            margin-left: 12px;
            border: 1px solid #33b378;
            color: #272a32;
            background: #fff;

            &-loading {
                background: "#dfdfdf";
                cursor: not-allowed;
                width: 119px;
                margin-left: 12px;
                border: 1px solid #dfdfdf;
            }
        }
    }
}
