@import "../../../../../assets/styles/main";

.restore-account__request {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 136px auto 136px;
    background: $white;
    border-radius: 10px;
    padding: 24px;
    width: 520px;
    height: auto;
    text-align: center;

    @include for-size(phone) {
        margin: 100px 15px 100px 15px;
        width: calc(100% - 60px);
        padding: 15px;
    }

    &_content {
        padding: 10px 20px 24px 20px;

        @include for-size(phone) {
            padding: 0 0 15px 0;
        }
    }
    &-img {
        width: 72px;
        height: 72px;
        display: flex;
        justify-content: center;
        margin: 0 auto 19px;
    }

    &-title {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        margin: 0 0 16px 0;
        color: $neutral-black-a32;
    }

    &-subtitle {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: $light_gray;
        margin-bottom: 16px;
    }

    &-btns {
        display: flex;
        align-items: center;
        justify-content: center;

        &-left,
        &-right {
            background: $main_green;
            border-radius: 4px;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            height: 48px;
            border: none;
            outline: none;
            text-align: center;
            color: $white;
            transition: all 0.2s linear;
            cursor: pointer;
            width: 100%;
            max-width: 200px;

            &:hover {
                opacity: 0.7;
            }

            &[disabled] {
                cursor: not-allowed;
                transition: all 0.2s linear;

                &:hover {
                    background: $neutral-light-grey-fdf;
                    color: $white;
                }
            }
        }

        &-right {
            margin-left: 8px;
            border: 2px solid #33b378;
            background-color: #fff;
            color: #272a32;
        }

        &-left {
            margin-right: 8px;
        }
    }
}
