.all-result-sort {
    margin-bottom: 24px;
    width: 282px;
    z-index: 3;
    margin-right: 30px;

    @media screen and (max-width: 900px) {
        width: 100%;
        margin-right: 0;
    }

    .select {
        margin: 0;
    }

    .select__dropdown_header {
        padding: 21.8px 16px;
    }

    .select__dropdown {
        border: none;
    }

    .select__dropdown-open {
        border: none;
        outline: 1px double #33b378;
        outline-offset: 0px;
    }

    .select__dropdown,
    .select__dropdown-open {
        margin: 0;
        border-radius: 6px;
    }

    .select__dropdown-open {
        margin: 0;
        border-radius: 6px 6px 0 0;
    }

    .select__dropdown_body.open {
        border-radius: 0 0 6px 6px;
        border: none;
        outline: 1px double #33b378;
        outline-offset: 0px;
        width: 100%;
        left: 0;
    }
}
