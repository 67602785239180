@import "../../../../../../assets/styles/mixin";

.header-profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    @include for-size(phone) {
        display: none;
    }

    &__photo {
        width: 38px;
        height: 38px;
        object-fit: cover;
        border-radius: 50%;
        cursor: pointer;
    }

    &__arrow {
        transition: all 0.2s linear;
        margin-left: 10px;

        &-active {
            transition: all 0.2s linear;
            transform: rotate(180deg);
            margin-left: 10px;
        }
    }

    &__dropdown {
        cursor: default;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        position: absolute;
        z-index: 1;
        right: 0;
        top: 46px;
        z-index: 1000;
        border: 1px solid #dfdfdf;
        box-shadow: 0px 8px 12px -6px rgba(75, 75, 75, 0.16);
        border-radius: 12px;

        a,
        button {
            background: #fff;
            cursor: pointer;
            text-decoration: none;
            border: none;
            color: #556267;
            font-size: 15px;
            line-height: 18px;
            text-align: left;
            height: 48px;
            transition: all 0.2s linear;
            display: flex;
            align-items: center;
            padding-left: 16px;
            width: 183px;

            &:first-child {
                border-radius: 11px 11px 0 0;
            }

            &:last-child {
                border-radius: 0 0 11px 11px;
            }

            &:hover {
                background: #33b378;
                color: #fff;
            }
        }

        &-logout-btn {
            width: 199px !important;

            &.isBanned {
                border-radius: 11px !important;
            }

            &.isLoading {
                display: flex;
                justify-content: center;
                padding: 0;
                background-color: #dfdfdf !important;
                cursor: not-allowed !important;
            }
        }

        &-item {
            width: 50px;
            height: 60px;
            position: absolute;
            align-self: center;
        }

        &-header {
            width: calc(100% - 32px);
            background: #fff;
            border-radius: 11px 11px 0 0;
            cursor: default;
            padding: 16px;
            display: flex;
            align-items: center;

            &__photo {
                width: 42px;
                height: 42px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 8px;
            }

            &__content {
                &-name {
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                }

                &-role {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: #ababab;
                }
            }
        }
    }
}
