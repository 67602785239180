@import "./../../../../assets/styles/main";
@import "./../../../../assets/styles/mixin";

.comfort {
    overflow-x: hidden;
    background-size: cover;
    background-color: $main-green;

    &__content {
        display: flex;
        padding: 57px 115px 0 115px;
        max-width: 1170px;
        margin: 0 auto;

        @include for-size(tablet-phone) {
            flex-direction: column;
        }
        @include for-size(phone-630) {
            padding: 57px 15px 0 15px;
        }
    }

    &__info {
        width: 449px;
        @include for-size(phone-475) {
            width: 100%;
        }
    }

    &__title {
        color: $white;
        margin: 34px 0 18px 0;
        font-family: "SF Pro Display Medium";
        @include text-size(normal, 500, 40px, 42px);
    }

    &__description {
        color: $white;
        width: 326px;
        margin: 0 0 61px 0;
        font-family: "SF Pro Display Medium";
        @include text-size(normal, 500, 22px, 32px);

        @include for-size(phone-475) {
            width: 100%;
        }
    }

    &__links {
        display: flex;
        width: 417px;
        justify-content: space-between;

        @include for-size(phone-475) {
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &__link {
        width: 200px;
        padding: 18px 34px 17px 34px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 6px;
        font-family: "SF Pro Display Medium";

        svg {
            margin-right: 16px;
        }

        &--playmarket {
            color: $neutral-black-a32;
            margin-right: 17px;
            background-color: $white;
            @include text-size(normal, 500, 18px, 26px);
            transition: all 0.2s ease-in;

            &:hover {
                opacity: 0.7;
            }

            @include for-size(phone-475) {
                margin-right: auto;
                margin-bottom: 20px;
            }
        }

        &--appstore {
            color: $white;
            background-color: $neutral-black-a32;
            @include text-size(normal, 500, 18px, 26px);
            transition: all 0.2s ease-in;

            &:hover {
                opacity: 0.7;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__mobile {
        margin-left: 115px;

        @include for-size(tablet-phone) {
            margin-left: 0;
            margin-top: 50px;
        }
    }
}
