@import "../../../../assets/styles/main";
.no-faq {
    width: 100%;
    height: 384px;
    border-radius: 10px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__img {
        width: 88px;
        height: 120px;
    }

    &__title {
        margin-top: 16px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: $neutral-black-a32;
    }
}
