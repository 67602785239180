@import "../../../../assets/styles/mixin";
@import "../../../../assets/styles/main";

.balance-payment-modal {
    width: 502px;
    padding: 36px 73px;
    background-color: $white;
    border-radius: 10px;
    box-sizing: border-box;

    @include for-size(phone) {
        margin: 0 auto;
        padding: 20px 40px;
        width: 90%;
    }

    &__title {
        margin-bottom: 36px;
        text-align: center;
        font-size: 25px;
        line-height: 30px;
        color: $neutral-black-a32;

        @include for-size(phone) {
            margin-bottom: 26px;
            font-size: 20px;
        }
    }

    &__types {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;

        &-item {
            width: 48%;
            height: 151px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            border-radius: 8px;
            cursor: pointer;
            filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));

            @include for-size(phone) {
                height: 100px;
            }
        }

        &-active-item {
            background-color: $white_background_def;
        }
    }
}
