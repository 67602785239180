@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixin";

.service-item {
    width: 100%;
    background: white;
    border-radius: 10px;
    padding: 24px 44px;
    margin-bottom: 24px;
    box-sizing: border-box;

    @include for-size(tablet-phone) {
        padding: 24px 24px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        &__title {
            margin: 0;

            font-family: "SF Pro Display";
            @include text-size(normal, 600, 24px, 30px);

            @include for-size(phone) {
                font-size: 18px;
            }
        }

        &__controls {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__buttons {
                display: flex;
                justify-content: center;

                cursor: pointer;
                transition: all 0.2s linear;

                &:hover {
                    opacity: 0.7;
                }

                &:first-child {
                    margin-right: 35px;

                    @include for-size(phone) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    &__divider {
        height: 1px;
        width: 100%;
        background: $neutral-light-grey-fdf;
    }

    &__body {
        margin-top: 30px;
        display: flex;

        @include for-size(tablet-phone) {
            display: block;
        }

        &--left-column {
            width: calc(50% - 10px);
            margin-right: 20px;

            @include for-size(tablet-phone) {
                width: 100%;
                margin-right: 0;
            }
        }

        &--right-column {
            width: calc(50% - 10px);

            @include for-size(tablet-phone) {
                width: 100%;
            }
        }

        &__caption {
            padding: 9px 15px;
            color: $neutral-black-a32;
            background: rgba(237, 238, 240, 0.37);
            border-radius: 4px;
            margin-top: 12px;
            font-family: "SF Pro Display Medium";
            @include text-size(normal, 400, 17px, 30px);

            &:first-child {
                margin-top: 0;
            }
        }

        &__description {
            color: $neutral-black-a32;
            font-family: "SF Pro Display";
            @include text-size(normal, 400, 15px, 18px);

            @include for-size(phone) {
                font-size: 12px;
            }
            &-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px 15px;
                border-bottom: 1px dashed $neutral-light-grey-fdf;
            }
        }

        &__comments {
            color: $neutral-black-a32;
            padding: 12px 15px;
            word-break: break-word;

            font-family: "SF Pro Display";
            @include text-size(normal, 400, 15px, 18px);
        }

        &__schedule {
            color: $neutral-black-a32;
            padding: 12px 15px;
            font-family: "SF Pro Display";
            @include text-size(normal, 400, 15px, 18px);

            &__content {
                &-container {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &__divider {
                border: 1px dashed #dfdfdf;
            }
        }
    }

    &__photos {
        display: flex;
        overflow: auto;
        padding-top: 12px;
        margin-top: -12px;
        padding-bottom: 11px;
        margin-top: 12px;

        &-empty {
            padding: 12px;
        }

        &::-webkit-scrollbar {
            height: 12px;
            border-radius: 10px;
            background-color: #fff;
            border: 1px solid #33b378;
        }

        &::-webkit-scrollbar-thumb {
            background: #33b378;
            border-radius: 10px;
        }

        &__item {
            margin-right: 20px;
            position: relative;

            &-img {
                width: 173px;
                height: 173px;
                object-fit: cover;
                border-radius: 10px;
                cursor: pointer;
            }

            &-close {
                position: absolute;
                cursor: pointer;
                z-index: 1000;

                top: -10px;
                right: -10px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
