@import "../../../../assets/styles/main";
@import "../../../../assets/styles/mixin";

.carousel {
    background-color: $white;

    &_content {
        margin: 0 auto;
        max-width: 1170px;
        padding: 57px 115px 95px 115px;

        @include for-size(phone-630) {
            padding: 57px 15px 95px 15px;
        }
    }

    &_title {
        margin-bottom: 73px;
        color: $neutral-black-a32;
        @include text-size(normal, 600, 40px, 42px);
    }
    &_list {
        transition: all 2s ease-in-out;
        overflow-x: hidden;
        display: flex;
        align-items: center;
        margin-bottom: 42px;

        &-mobile-scroll {
            display: flex;
            align-items: center;
            margin-bottom: 42px;

            overflow-x: auto;
            scroll-behavior: smooth;
        }
    }

    &_pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include for-size(phone-630) {
            flex-direction: column;
        }

        &__crumbs {
            display: flex;
            align-items: center;

            &_item {
                width: 13px;
                height: 7px;
                margin-right: 10px;
                border-radius: 55px;
                background: $neutral-light-grey-fdf;

                &:last-of-type {
                    margin-right: 0px;
                }

                &-active {
                    background: $main_green;
                    width: 13px;
                    height: 7px;
                    margin-right: 10px;
                    border-radius: 55px;
                }
            }
        }

        &__services {
            display: flex;
            align-items: center;
            width: 112px;
            justify-content: space-between;
            @include for-size(phone-630) {
                margin-top: 20px;
            }
            &_right {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 50px;
                background: $main_green;
                border-radius: 4px;
                transition: 0.1 linear;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
            &_left {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 50px;
                background: $main_green;
                border-radius: 4px;
                transition: 0.1 linear;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                &-blocked {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 48px;
                    height: 50px;
                    background: $main_green;
                    border-radius: 4px;
                    transition: 0.1 linear;
                    pointer-events: none;
                    cursor: not-allowed;
                    opacity: 0.6;
                }
            }
        }
    }
}
.carousel_list-mobile-scroll::-webkit-scrollbar {
    display: none;
}
