@import "../../../assets/styles/main";

.order-info_body {
    background: $white;
    border-radius: 10px;
    padding: 40px 32px 36px 32px;

    @include for-size(phone) {
        padding: 10px 15px 10px 15px;
    }

    &__details {
        &_title {
            margin-top: 12px;
            padding: 9px 15px;
            border-radius: 4px;
            color: $neutral-black-a32;
            background: rgba(237, 238, 240, 0.37);
            @include text-size(normal, 700, 17px, 30px);

            @include for-size(phone) {
                @include text-size(normal, 400, 15px, 18px);
            }
        }

        &_photos {
            display: flex;
            overflow: auto;
            padding-top: 26px;
            padding-bottom: 11px;

            &-empty {
                padding: 12px;
            }

            &::-webkit-scrollbar {
                height: 12px;
                border-radius: 10px;
                background-color: #fff;
                border: 1px solid #33b378;
            }

            &::-webkit-scrollbar-thumb {
                background: #33b378;
                border-radius: 10px;
            }

            &__item {
                margin-right: 20px;
                position: relative;

                &-img {
                    width: 173px;
                    height: 173px;
                    object-fit: cover;
                    border-radius: 10px;
                }

                &-close {
                    position: absolute;
                    cursor: pointer;
                    z-index: 1000;

                    top: -10px;
                    right: -10px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 15px;
            border-bottom: 1px dashed $neutral-light-grey-fdf;

            @include for-size(phone) {
                padding: 15px 5px;
            }

            &_description {
                @include text-size(normal, 400, 15px, 18px);
                color: $neutral-black-a32;

                @include for-size(phone) {
                    @include text-size(normal, 400, 13px, 18px);
                }
            }
            &_value {
                text-align: center;
                color: $neutral-black-a32;
                @include text-size(normal, 400, 15px, 18px);
                display: flex;
                align-items: center;

                svg {
                    path {
                        fill: #556267;
                    }
                }

                @include for-size(phone) {
                    width: 100px;
                    @include text-size(normal, 400, 13px, 18px);
                }
            }
            &_no-image {
                padding: 0 15px;
            }
        }
    }
}
