@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixin";

.country-codes-modal-outer-container {
    height: 614px;
    position: relative;
}

.country-codes-modal {
    width: 440px;
    padding: 48px 42px;
    background-color: $white;
    border-radius: 10px;
    text-align: center;
    overflow-y: hidden;
    box-sizing: border-box;

    @include for-size(phone-475) {
        width: 100%;
        padding: 48px 22px;
    }

    &__title {
        margin: 25px 0 16px;
        font-size: 25px;
        line-height: 30px;
        color: $neutral-black-a32;

        @include for-size(phone-475) {
            font-size: 22px;
        }
    }

    &__description {
        font-size: 16px;
        line-height: 21px;
        color: $light_gray;

        @include for-size(phone-475) {
            font-size: 13px;
        }
    }

    &-item:last-child {
        border-bottom: none;
    }
}

.country-codes-modal__dropdown-search-icon {
    margin: 4px 20px 0 0;
}

.country-codes-modal__dropdown-item {
    display: flex;
    align-items: center;
}

.country-codes-modal__dropdown-item-flag {
    width: 24px;
    height: 16px;
}

.country-codes-modal__dropdown-item-name {
    margin-left: 8px;
}

.country-codes-modal__dropdown-item-code {
    margin-left: 8px;
    color: $light_gray;
}
