@import "../../../assets/styles/mixin";
@import "../../../assets/styles/variables";

.schedule-input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    max-width: 538px;
    box-sizing: border-box;

    &-container {
        width: 100%;
        height: 50px;
        border: 1px solid $neutral-light-grey-fdf;
        border-radius: 4px;
        position: relative;

        &--error {
            @extend .schedule-input-container;

            border: 1px solid #ff3b30;
        }

        .schedule_work {
            top: -590px;
            left: -1px;
            height: 580px;
            overflow: auto;
            border: 1px solid $neutral-light-grey-fdf;
        }
    }

    &__content {
        font-family: "SF Pro Display";
        @include text-size(normal, 400, 16px, 22px);
        color: #272a32;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 90%;
        white-space: nowrap;
        opacity: 0.7;
    }

    &__error {
        margin: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $light_red;
        position: absolute;
        top: -20px;
        right: 0;
    }
}
