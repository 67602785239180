@import "../../assets/styles/mixin";
@import "../../assets/styles/main";

.discussion_work_project {
    max-width: 1170px;
    margin: 69px auto 69px auto;
    padding: 0 15px;

    @include for-size(phone) {
        margin: 35px auto 35px auto;
    }

    &__header {
        width: 100%;
        display: flex;
        margin: 0 0 18px 0;

        @include for-size(phone) {
            margin: 0 0 9px 0;
            flex-direction: column;
        }

        &_main {
            margin: 0 auto;

            &_title {
                margin: 0 0 17px 0;
                color: $neutral-black-a32;
                @include text-size(normal, 600, 45px, 30px);

                @include for-size(tablet-phone) {
                    @include text-size(normal, 600, 33px, 30px);
                }

                @include for-size(phone) {
                    text-align: center;
                    margin: 25px 0 9px 0;
                }
            }

            &_subtitle {
                text-align: center;
                color: $neutral-grey-282;
                @include text-size(normal, normal, 18px, 22px);
            }
        }

        &_btn {
            max-height: 42px;
        }
    }

    &__body {
        display: flex;
        flex-wrap: wrap;

        &_profile {
            display: flex;
            width: 100%;

            @include for-size(phone) {
                flex-direction: column-reverse;
            }
        }

        &_main {
            display: flex;
            width: 100%;

            @include for-size(phone) {
                flex-direction: column;
            }
        }
    }
}
