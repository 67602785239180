@import "../../assets/styles/variables";
@import "../../assets/styles/mixin";

.scroll-button {
    width: 40px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 10px;
    position: fixed;
    cursor: pointer;
    bottom: 50px;
    right: 50px;
    z-index: 776;
    background-color: $green_90;

    @include for-size(phone) {
        bottom: 100px;
    }

    &:hover {
        opacity: 0.5;
    }

    &__icon {
        width: 10px;
        height: 12px;
        transform: rotate(90deg);
        fill: $gray_background_icons;
    }
}

.scroll-button-chat {
    width: 32px;
    height: 32px;
    border: none;
    outline: none;
    border-radius: 50%;
    z-index: 1000;
    position: absolute;
    cursor: pointer;
    top: -20px;
    right: 20px;
    background-color: #f1f1f1;
    transition: 0.1s linear;

    @include for-size(phone) {
        bottom: 100px;
    }

    &:hover {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    }

    &__icon {
        margin-top: 7px;
        text-align: center;
        width: 18px;
        height: 18px;
        transform: rotate(0deg);
    }
}
