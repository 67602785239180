.global-loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999999999;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(0, 0, 0);
    background-color: #f3f4f6;
}
