@import "../../../../../assets/styles/main";
.forgot-password__confirm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 117px auto 117px;
    background: $white;
    border-radius: 10px;
    padding: 42px;
    width: 356px;
    height: auto;
    text-align: center;

    @include for-size(phone-475) {
        margin: 100px auto 100px;
        background: none;
        padding: 0;
        width: 100%;
    }
    &_content {
        padding: 10px 20px;
    }

    &-img {
        width: 72px;
        height: 72px;
        display: flex;
        justify-content: center;
        margin: 0 auto 16px;
    }

    &-title {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        margin: 0 0 8px 0;
        color: $neutral-black-a32;
    }

    &-subtitle {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: $light_gray;
        width: 276px;
        margin: 0 auto 16px;

        @include for-size(phone-475) {
            width: 100%;
        }
    }

    &-btn {
        background: $main_green;
        border-radius: 4px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        height: 48px;
        border: none;
        outline: none;
        text-align: center;
        color: $white;
        transition: all 0.2s linear;
        cursor: pointer;
        width: 100%;

        &:hover {
            opacity: 0.7;
        }

        &[disabled] {
            cursor: not-allowed;
            transition: all 0.2s linear;

            &:hover {
                background: $neutral-light-grey-fdf;
                color: $white;
            }
        }
    }
}
