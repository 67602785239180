@import "../../../../assets/styles/mixin";

.chats__content-item {
    border-bottom: 0.5px solid #ababab;
    padding: 25px 0 25px 0;
    margin-bottom: 25px;
    margin-top: -25px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @include for-size(phone-630) {
        padding: 15px 0 15px 0;
        margin-bottom: 15px;
        margin-top: -15px;
    }

    &:first-child {
        padding: 0 0 25px 0;
        margin-top: 0;

        @include for-size(phone-630) {
            padding: 0 0 15px 0;
        }
    }

    &:last-child {
        border-bottom: none;
        padding: 0 0 0 0;
        margin: 0;
    }

    &--avatar {
        width: 58px;
        height: 58px;
        position: relative;

        @include for-size(phone-630) {
            width: 45px;
            height: 45px;
        }

        &-img {
            width: 58px;
            object-fit: cover;
            height: 58px;
            border-radius: 50%;

            @include for-size(phone-630) {
                width: 45px;
                height: 45px;
            }
        }

        &-status {
            background: #33b378;
            border-radius: 62.5px;
            position: absolute;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            width: 15px;
            height: 15px;

            @include for-size(phone-630) {
                width: 10px;
                height: 10px;
            }
        }
    }

    &--info {
        max-width: calc(100% - 72px);
        margin-left: 20px;
        text-align: left;
        @include for-size(phone) {
            max-width: calc(100% - 58px);
        }

        @include for-size(phone-630) {
            width: 100%;
            margin-left: 10px;
        }

        &-header {
            display: flex;
            align-items: center;
            min-width: 100%;

            &-name {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #272a32;
                margin-right: 16px;

                @include for-size(phone-630) {
                    font-size: 13px;
                    line-height: 16px;
                    margin-right: 10px;
                }
            }

            &-date {
                font-size: 15px;
                line-height: 22px;
                color: #828282;

                @include for-size(phone-630) {
                    font-size: 13px;
                    line-height: 16px;
                }
            }

            &-messages {
                font-weight: 500;
                font-size: 12px;
                line-height: 27px;
                text-align: center;
                background: #33b378;
                border-radius: 34.2857px;
                color: #ffffff;
                width: 24px;
                height: 24px;
                margin-left: 16px;
                cursor: default;

                @include for-size(phone-630) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    line-height: 16px;
                    min-width: 14px;
                    min-height: 14px;
                    margin-left: 10px;
                }
            }
        }

        &-body {
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            margin: 4px 0 8px 0;
            color: #272a32;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @include for-size(phone-630) {
                font-size: 13px;
                line-height: 16px;
            }

            &-img {
                width: 40px;
                height: 25px;
                object-fit: cover;
                border-radius: 4px;

                @include for-size(phone-630) {
                }
            }
        }

        &-button {
            border: 2px solid #33b378;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            text-align: center;
            color: #272a32;
            width: 252px;
            height: 50px;
            background: #fff;
            cursor: pointer;
            transition: all 0.2s linear;

            @include for-size(phone-630) {
                width: auto;
                min-width: 205px;
                font-size: 13px;
                height: 16px;
                height: 45px;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
