@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/mixin";

.executors-list {
    margin: 0 0 43px 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: $white;

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 25px 61px 16px 61px;
        color: $neutral-black-a32;
        border-bottom: 1px solid $neutral-light-grey-fdf;
        cursor: pointer;

        font-family: "SF Pro Display Medium";
        @include text-size(normal, 400, 18px, 30px);

        @include for-size(phone) {
            display: block;
            padding: 25px 20px 16px 20px;
        }
        @media (min-width: 767px) and (max-width: 1200px) {
            display: block;
            padding: 25px 20px 16px 20px;
        }
    }

    &__items {
        padding: 29px 39px 16px 61px;

        @include for-size(phone) {
            padding: 25px 20px 16px 20px;
        }
        @media (min-width: 767px) and (max-width: 1200px) {
            padding: 25px 20px 16px 20px;
        }
    }

    &__empty {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &__title {
            text-align: center;

            font-family: "SF Pro Display Medium";
            @include text-size(normal, 500, 20px, 24px);
            margin: 16px 0 16px 0;

            @include for-size(phone) {
                width: 100%;
            }
        }

        &__subtitle {
            color: $light_gray;
            width: 40%;
            text-align: center;
            font-family: "SF Pro Display";
            @include text-size(normal, 400, 15px, 22px);

            @include for-size(phone-475) {
                width: 100%;
            }
            @media (min-width: 475px) and (max-width: 1200px) {
                width: 70%;
            }
        }
    }
}

.executor-item {
    display: flex;
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }

    &__avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;

        &-container {
            min-width: 58px;
            max-width: 58px;
            height: 58px;
            margin-right: 15px;
            position: relative;
        }

        &__status {
            width: 15px;
            height: 15px;
            background: $main_green;
            position: absolute;
            bottom: 0;
            right: 0;
            border-radius: 50%;
        }
    }
    &__content {
        max-width: calc(100% - 73px);
        width: 100%;
        &__general-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;

            @media (min-width: 320px) and (max-width: 1200px) {
                display: block;
            }
            .btn {
                width: 260px;
            }
            .btn.btn--green.btn--large {
                max-width: 90%;
            }
            &__name {
                color: $neutral-grey-343;
                margin-bottom: 4px;

                font-family: "SF Pro Display Medium";
                @include text-size(normal, 400, 16px, 22px);
            }

            &__feedback {
                @include text-size(normal, 500, 15px, 22px);
                word-break: break-word;
                padding-bottom: 20px;
                color: #6a6a6a;
            }

            &__main-service {
                color: $light_gray;
                margin-bottom: 4px;

                font-family: "SF Pro Display";
                @include text-size(normal, 400, 15px, 22px);
            }

            &__price-range {
                @include text-size(normal, 400, 15px, 22px);
                font-family: "SF Pro Display Medium";
                color: $neutral-grey-343;
                align-items: center;
                display: flex;
            }

            &__rating {
                width: 200px;
                display: flex;
                justify-content: space-between;

                font-family: "SF Pro Display";
                @include text-size(normal, 400, 15px, 18px);
            }
        }
    }

    &__request-text {
        overflow: hidden;
        text-overflow: ellipsis;
        color: $neutral-grey-a6a;

        font-family: "SF Pro Display";
        @include text-size(normal, 400, 15px, 22px);
    }
}
