@import "../../../../assets/styles/main";

.all_result__searched_header {
    padding: 0px 20px 0 20px;

    &_global {
        border-bottom: 1px solid $neutral-light-grey-fdf;
        padding-bottom: 24px;
    }

    &_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;

        &_title {
            color: $neutral-black-a32;
            @include text-size(normal, 600, 18px, 30px);
            word-wrap: normal;
            word-break: keep-all;
            line-break: normal;
        }

        &_count {
            margin-left: 10px;
            text-decoration: underline;
            white-space: nowrap;
        }
        @media screen and (max-width: 650px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &_all_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 350px) {
            flex-direction: column;
        }

        &_title {
            color: $neutral-black-a32;
            @include text-size(normal, 600, 18px, 30px);
        }

        &_count {
            text-decoration: underline;
        }
    }

    &_services {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-bottom: 16px;

        &_item {
            color: $white;
            display: flex;
            align-items: center;
            padding: 11px 18px;
            margin: 0 8px 8px 0;
            background: $main_green;
            border-radius: 6px;
            border: none;

            &_icon {
                transition: all 0.3s ease-in;
                cursor: pointer;

                &:hover {
                    transform: rotate(90deg);
                }
            }

            &_title {
                margin-right: 10px;
            }
        }

        &_remove {
            background: $white;
            border-radius: 6px;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            border: none;
            outline: none;
            text-align: center;
            transition: all 0.2s linear;
            cursor: pointer;
            border: 1px solid $main_green;
            padding: 11px 18px;
            height: 40.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $neutral-black-a32;

            &:hover {
                opacity: 0.7;
            }

            &:focus {
                outline: none;
            }
        }
    }
}
