@import "../../../../assets/styles/main";

.chats__search {
    display: flex;
    border-radius: 10px;
    margin-bottom: 24px;
    align-items: center;
    background-color: $white;
    justify-content: space-between;
    padding: 7px 24px;

    &-input {
        width: 80%;
        padding: 0;
        border: none;
        outline: none;
        box-shadow: none;
        background-image: none;
        background-color: transparent;
        @include text-size(normal, normal, 16px, 22px);
    }

    &-btn {
        height: 48px;
        width: 125px;
        padding: 0;
        border: none;
        color: $white;
        border-radius: 4px;
        text-align: center;
        background-color: $main_green;
        @include text-size(normal, 500, 16px, 22px);

        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }
}
