@import "../../../../../../assets/styles/mixin";

.select-order-modal {
    padding: 75px;
    background: #fff;
    border-radius: 10px;
    width: 352px;

    @include for-size(phone) {
        width: auto;
        margin: 0 15px;
        padding: 45px 15px 15px 15px;
    }

    .select__dropdown-open {
        border-bottom: none;
        border-radius: 4px;
    }

    .select__dropdown_body.open {
        position: relative;
    }

    &__title {
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: #272a32;
        margin-bottom: 36px;

        @include for-size(phone) {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 15px;
        }
    }

    &__empty {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 36px;

        @include for-size(phone) {
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            margin-bottom: 15px;
        }
    }

    &__btn {
        transition: all 0.2s linear;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        outline: none;
        width: 100%;
        height: 48px;
        cursor: pointer;
        color: #ffffff;
        background: #33b378;
        border-radius: 4px;
        border: none;
        margin-top: 7px;

        @include for-size(phone) {
            font-size: 13px;
            line-height: 16px;
        }

        &[disabled] {
            cursor: not-allowed;
            transition: all 0.2s linear;

            &:hover {
                background: #33b378;
                color: #fff;
            }
        }

        &:hover {
            opacity: 0.7;
        }
    }
}
