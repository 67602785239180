@import "../../../../../assets/styles/main";
.initial-image {
    width: 140px;
    height: 140px;

    &-button {
        display: none;
    }
    &-content {
        position: relative;
        cursor: pointer;

        &-plus {
            position: absolute;
            bottom: 4.8px;
            right: 5px;
            cursor: pointer;
        }
        &-file {
            padding: 7px;
            width: 124px;
            height: 124px;
            object-fit: cover;
            border-radius: 50%;
            border: 1px solid $neutral-white-1f1;
        }
    }
}
