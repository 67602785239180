@import "../../../../assets/styles/mixin";

.support-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #ffffff;
    border-radius: 10px;
    width: calc(100% - 72px);
    padding: 36px;
    margin: 0 0 30px 0;

    @include for-size(phone) {
        padding: 15px;
        width: calc(100% - 30px);
        margin: 0 0 15px 0;
    }
}
