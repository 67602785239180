@import "../../assets/styles/variables";
@import "../../assets//styles/mixin";

.specialist-orders {
    width: 1170px;
    margin: 57px auto;
    min-height: calc(100vh - 500px);

    @include for-size(phone) {
        width: 95%;
        padding: 0 10px;
        margin: 35px auto;
    }

    @media (min-width: 767px) and (max-width: 1200px) {
        width: 95%;
        padding: 0 20px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 43px;

        @include for-size(phone) {
            display: block;
            margin-bottom: 20px;

            .btn.btn--green.btn--large {
                width: 48%;
            }
            .btn.btn--light.btn--large {
                width: 48%;
            }
        }
        @media (min-width: 767px) and (max-width: 1200px) {
            display: block;
            margin-bottom: 20px;

            .btn.btn--green.btn--large {
                width: 48%;
            }
            .btn.btn--light.btn--large {
                width: 48%;
            }
        }

        &__title {
            font-family: "SF Pro Display Medium";
            @include text-size(normal, 900, 36px, 28px);

            @include for-size(phone) {
                @include text-size(normal, 900, 30px, 28px);
                text-align: center;
            }
        }

        &__controls-block {
            width: 560px;
            display: flex;
            justify-content: space-between;

            .btn {
                width: calc(50% - 9px);
                margin-bottom: 0;
            }

            @include for-size(phone) {
                width: 100%;
                margin: 0 auto;
                justify-content: space-between;
            }
        }
    }

    &__content {
        display: flex;
        align-items: flex-start;

        @include for-size(phone) {
            display: block;
        }

        &__sidebar {
            top: 80px;
            position: sticky;

            @include for-size(phone) {
                margin-bottom: 20px;
                position: relative;
                width: 100%;
                top: 0;
            }
        }

        &__list {
            width: calc(100% - 300px);

            @include for-size(phone) {
                width: 100%;
                margin-top: 20px;
            }

            &--disabled {
                @extend .specialist-orders__content__list;
                cursor: not-allowed;
                opacity: 0.5;
            }

            :last-child {
                margin-bottom: 0;
            }
        }
    }
}
