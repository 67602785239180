@import "../../../../assets/styles/main";

.create-order__header {
    margin: 0 0 10px 0;
    position: relative;

    @include for-size(phone) {
        display: block;
    }

    &-btn {
        max-height: 42px;
        position: absolute;
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            font-weight: 600;
            font-size: 45px;
            line-height: 30px;
            text-align: center;
            color: #272a32;

            @include for-size(phone) {
                font-size: 35px;
                margin-top: 70px;
            }
        }

        &-subtitle {
            margin: 20px 0 0 0;
            font-size: 18px;
            line-height: 22px;
            color: #828282;

            @include for-size(phone) {
                text-align: center;
                margin: 15px 0 0 0;
            }
        }
    }

    &-info {
        font-size: 18px;
        line-height: 34px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: #272a32;
    }
}
