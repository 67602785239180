@import "../../assets/styles/mixin";
@import "../../assets/styles/variables";

.specialist-profile {
    width: 1170px;
    margin: 57px auto;

    @include for-size(tablet-laptop) {
        box-sizing: border-box;
        width: 100%;
        padding: 0 20px;
    }

    @include for-size(tablet-phone) {
        box-sizing: border-box;
        width: 100%;
        padding: 0 20px;
    }

    &__header {
        margin-bottom: 45px;
        display: flex;
        align-items: center;

        .back-button {
            position: absolute;
        }

        &__title {
            color: $neutral-black-a32;
            margin: 0 auto;
            font-family: "SF Pro Display";
            @include text-size(normal, 600, 45px, 30px);

            @include for-size(tablet-phone) {
                margin-top: 70px;
            }
        }

        @include for-size(tablet-phone) {
            flex-direction: column;
        }
    }

    &__main-content {
        display: flex;

        @include for-size(tablet-phone) {
            flex-direction: column;
        }

        &__left-column {
            width: 370px;
            margin-right: 30px;

            @include for-size(tablet-phone) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        &__right-column {
            width: calc(100% - 400px);

            @include for-size(tablet-phone) {
                width: 100%;
            }
        }
    }
}
