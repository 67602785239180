@import "../../../../assets/styles/main";

.searched_specialist {
    box-sizing: border-box;
    display: grid;
    grid-template-areas: "ava info btn" "ava description description" "ava body body";
    grid-template-columns: 78px 1fr 1fr;
    width: 100%;
    border: 1px;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid $neutral-light-grey-fdf;
    @media screen and (max-width: 650px) {
        grid-template-areas: "body body" "ava info" "description description" "btn btn";
        grid-template-columns: 78px 1fr;
    }

    &:last-of-type {
        margin-bottom: 0px;
        border-bottom: none;
        padding-bottom: 0;
    }

    &__img {
        grid-area: ava;
        margin-right: 14px;
        max-width: fit-content;
        max-height: fit-content;
        &_avatar {
            width: 58px;
            height: 58px;
            object-fit: cover;
            border-radius: 50%;
            position: relative;

            &_online {
                content: "";
                right: 0;
                bottom: 5px;
                display: block;
                position: absolute;
                width: 15px;
                height: 15px;
                z-index: 3;
                border-radius: 50%;
                background-color: $main_green;
            }
        }
    }

    &__info {
        width: fit-content;
        grid-area: info;
        @media screen and (max-width: 650px) {
            justify-self: start;
        }
    }
    &__description {
        grid-area: description;
        display: inline-block;
        @include text-size(normal, 500, 15px, 22px);
        color: #6a6a6a;
        margin-bottom: 4px;
        @media screen and (max-width: 650px) {
            margin-bottom: 24px;
        }
        @media screen and (max-width: 375px) {
            @include text-size(normal, 500, 13px, 16px);
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_info {
            &_day {
                @include text-size(normal, 400, 15px, 22px);
                color: #828282;
                margin-bottom: 4px;
                @media screen and (max-width: 375px) {
                    @include text-size(normal, 700, 13px, 16px);
                }
            }

            &_title {
                display: flex;
                align-items: center;
                color: $gray_43;
                margin-bottom: 4px;
                word-wrap: break-word;
                @include text-size(normal, 700, 16px, 22px);
                cursor: pointer;
                transition: all 0.3s linear;

                &.beauty {
                    color: $main_green;
                }

                &:hover {
                    opacity: 0.7;
                }

                @media screen and (max-width: 375px) {
                    @include text-size(normal, 700, 15px, 20px);
                }

                &-popular {
                    margin-right: 11px;

                    &.beauty {
                        color: $main_green;
                    }
                }
            }

            &_price {
                color: $dark_gray;
                margin-bottom: 4px;
                @include text-size(normal, 700, 15px, 22px);
                display: flex;
                align-items: center;
                @media screen and (max-width: 375px) {
                    @include text-size(normal, 700, 13px, 16px);
                }

                svg {
                    path {
                        fill: #556267;
                    }
                }
            }
        }
    }

    &__right-block {
        grid-area: btn;
        justify-self: end;
        align-self: flex-start;
        display: flex;
        align-items: center;

        &-btn {
            box-sizing: border-box;
            width: 205px;
            color: $white;
            padding: 14px 20px;
            border: none;
            border-radius: 4px;
            background: $main_green;
            @include text-size(normal, 500, 15px, 22px);
            transition: all 0.2s linear;
            outline: none;

            @media screen and (max-width: 650px) {
                width: 100%;
                justify-self: center;
                margin: 0 0 10px;
            }

            &:focus {
                outline: none;
            }

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }
    &__body {
        grid-area: body;
        width: 100%;

        &_tags {
            margin-bottom: 10px;
            @media screen and (max-width: 650px) {
                margin-bottom: 16px;
            }
            &_list {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                flex-direction: row;
                &::-webkit-scrollbar {
                    height: 6px;
                    background-color: #fff;
                    border: 1px solid #33b378;
                    border-radius: 3px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #33b378;
                    border-radius: 3px;
                }

                &_item {
                    margin: 0 4px 4px 0;
                    min-width: max-content;
                    padding: 11px 16px;
                    color: $main_green;
                    border-radius: 6px;
                    background: $white_background_def;
                    @media screen and (max-width: 350px) {
                        font-size: 10px;
                    }
                    & p {
                        min-width: 100%;
                        word-wrap: normal;
                    }
                }
            }
        }
    }
}
