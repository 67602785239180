@import "../../../../assets/styles/main";

.blog__container-right-content-item {
    border-radius: 10px;
    height: 408px;
    position: relative;
    transition: all 0.3s linear;
    background-color: #fff;
    cursor: pointer;

    &-image {
        width: 100%;
        object-fit: cover;
        height: 190px;
        border-radius: 10px 10px 0 0;
    }

    &-content {
        height: 218px;
        position: relative;
        padding: 16px;
        box-sizing: border-box;

        &-category {
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            word-break: break-word;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            text-align: left;
            font-size: 12px;
            font-family: "SF Pro Display Bold";
            line-height: 16px;
            color: $green_90;
            text-transform: uppercase;
        }

        &-title {
            margin: 8px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            word-break: break-word;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            text-align: left;
            font-size: 17px;
            font-family: "SF Pro Display Bold";
            color: $neutral-black-a32;
        }

        &-description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-break: break-word;
            -webkit-line-clamp: 3;
            text-align: left;
            font-size: 15px;
            line-height: 20px;
            color: $gray_6a;
            height: 60px;
            margin-bottom: 13px;

            ol,
            ul {
                margin-left: 15px;
            }
        }

        &-date {
            text-align: left;
            margin-bottom: 13px;
            color: $light_gray;
            font-size: 12px;
            line-height: 16px;

            &-item {
                margin-right: 16px;
            }
        }

        &-footer {
            display: flex;
            align-items: center;

            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover > svg circle {
                    fill: #def7eb;
                    stroke: #def7eb;
                }

                &:hover > svg path {
                    fill: #33b378;
                }

                svg {
                    width: 34px;
                    height: 34px;

                    path {
                        fill: #33b378;
                    }
                }

                svg circle {
                    fill: #fff;
                    stroke: #fff;
                }
            }
        }
    }
}
