@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/_mixin";
@import "../../../../../assets/styles/main";

.auth_required_distributor {
    max-width: 440px;
    height: 421px;
    background: $white;
    border-radius: 10px;
    margin: 117px auto 117px;

    @include for-size(phone-475) {
        max-width: 440px;
        height: 421px;
        background: none;
        border-radius: 10px;
        margin: 100px auto 100px;

        .btn.btn--green.btn--large {
            max-width: 100%;
        }
        .btn.btn--light.btn--large {
            max-width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 54px 42px 43px;
        text-align: center;

        @include for-size(phone-475) {
            padding: 43px 42px 43px;
        }
    }

    &__title {
        text-align: center;
        margin: 20px 0 32px;
        font-size: 25px;
        line-height: 30px;
        font-weight: 400;
    }

    &__description {
        font-size: 16px;
        line-height: 22px;
        color: $light_gray;
        margin: 0;
        font-weight: 400;
    }

    &__description_link {
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
        color: $main_green;
    }
}
